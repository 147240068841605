import React, { useCallback } from 'react';
import {
	apiFailure,
	initUserTypeListFetch,
	userTypeListSuccess
} from './actions';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { Store } from '../../store/store';
import UserTypeListResponse from '../../types/user-type-list-response';
import { doGet } from '../../service';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to manage user type list data and actions.
 *
 * @returns An object containing functions for fetching user type list and the user type list state.
 */
export function useUserTypeApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
   * Fetches the userType list from the API and dispatches actions based on the result.
   */
	const fetchUserTypeList = useCallback(async () => {
		dispatch(initUserTypeListFetch());
		try {
			const response: UserTypeListResponse = await doGet(APIConfig.userTypeList);
			dispatch(userTypeListSuccess(response.data || []));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Dispatches an API failure action with an error message.
   *
   * @param {any} error - The error object.
   */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
    dispatch(apiFailure(new AppError(error?.code, message)));
  }

	return {
		fetchUserTypeList,
		state
	};
}
