import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import APP_NAV from './app-nav';
import DepartmentScreen from '../screens/DepartmentScreen';
import DirectoryScreen from '../screens/DirectoryScreen';
import DomainScreen from '../screens/DomainScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import InboxScreen from '../screens/InboxScreen';
import LoginUtil from '../utils/login-util';
import Main from '../layouts/AppLayout/Main';
import Minimal from '../layouts/AppLayout/Minimal';
import NotificationScreen from '../screens/NotificationScreen';
import PermissionScreen from '../screens/PermissionScreen';
import ProfileScreen from '../screens/ProfileScreen';
import React from 'react';
import RegisterDepartmentScreen from '../screens/RegisterDepartmentScreen';
import RegisterNewDepartmentScreen from '../screens/RegisterDepartmentScreen/RegisterNewDepartmentScreen';
import RegisterPasswordScreen from '../screens/RegisterPasswordScreen';
import RegisterProfileScreen from '../screens/RegisterProfileScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import SettingScreen from '../screens/SettingScreen';
import SignInEmailScreen from '../screens/SignInEmailScreen';
import SignInPasswordScreen from '../screens/SignInPasswordScreen';
import SiteScreen from '../screens/SiteScreen';
import SplashScreen from '../screens/SplashScreen';
import UserScreen from '../screens/UserScreen';
import VerificationScreen from '../screens/VerificationScreen';
import WelcomeScreen from '../screens/WelcomeScreen';

interface Props {
  component: React.FC;
  isProtected: boolean;
}

/**
 * AppRoutes component defines the application routing logic using React Router.
 * 
 * It utilizes a custom AppLayout component to handle layout rendering based on login state and route protection.
 * Props:
 *  - component: React component to be rendered within the layout.
 *  - isProtected: boolean indicating if the route requires authentication.
 */
const AppRoutes = () => {

  const AppLayout = (props: Props) => {

    const { component: Component } = props;
    const isLoggedIn = LoginUtil.isLoggedIn();

    if (isLoggedIn) {
      if (props.isProtected) {
        return (<Main> <Component /> </Main>);
      } else {
        return (<Navigate to={`${APP_NAV.INBOX}`} replace />);
      }
    } else {
      if (props.isProtected) {
        return (<Navigate to={APP_NAV.WELCOME} replace />);
      } else {
        return (<Minimal> <Component /> </Minimal>);
      }
    }
  };

  return (
    <Routes>
      <Route path={APP_NAV.SPLASH_SCREEN} element={<AppLayout component={SplashScreen} isProtected={false} />} />
      <Route path={APP_NAV.WELCOME} element={<AppLayout component={WelcomeScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_UP_PASSWORD} element={<AppLayout component={RegisterPasswordScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_UP_DEPARTMENT} element={<AppLayout component={RegisterDepartmentScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_UP_NEW_DEPARTMENT} element={<AppLayout component={RegisterNewDepartmentScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_UP_PROFILE} element={<AppLayout component={RegisterProfileScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_IN} element={<AppLayout component={SignInEmailScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_IN_PASSWORD} element={<AppLayout component={SignInPasswordScreen} isProtected={false} />} />
      <Route path={APP_NAV.SIGN_IN_VERIFY} element={<AppLayout component={VerificationScreen} isProtected={false} />} />
      <Route path={APP_NAV.FORGOT_PASSWORD} element={<AppLayout component={ForgotPasswordScreen} isProtected={false} />} />
      <Route path={APP_NAV.RESET_PASSWORD} element={<AppLayout component={ResetPasswordScreen} isProtected={false} />} />
      <Route path={`${APP_NAV.INBOX}/*`} element={<AppLayout component={InboxScreen} isProtected={true} />} />
      <Route path={APP_NAV.DIRECTORY} element={<AppLayout component={DirectoryScreen} isProtected={true} />} />
      <Route path={APP_NAV.PROFILE} element={<AppLayout component={ProfileScreen} isProtected={true} />} />
      <Route path={APP_NAV.ADMIN} element={<AppLayout component={Outlet} isProtected={true} />}>
        <Route index element={<Navigate to={APP_NAV.ADMIN_USERS} />} />
        <Route path={APP_NAV.ADMIN_USERS} element={<UserScreen />} />
        <Route path={APP_NAV.ADMIN_DEPARTMENTS} element={<DepartmentScreen />} />
        <Route path={APP_NAV.ADMIN_SETTINGS} element={<Outlet />}>
          <Route index element={<Navigate to={APP_NAV.ADMIN_SETTINGS_DOMAINS} />} />
          <Route path={APP_NAV.ADMIN_SETTINGS_DOMAINS} element={<DomainScreen />} />
          <Route path={APP_NAV.ADMIN_SETTINGS_PERMISSIONS} element={<PermissionScreen />} />
          <Route path={APP_NAV.ADMIN_SETTINGS_SITES} element={<SiteScreen />} />
        </Route>
      </Route>
      <Route path={APP_NAV.NOTIFICATIONS} element={<AppLayout component={NotificationScreen} isProtected={true} />} />
      <Route path={APP_NAV.SETTINGS} element={<AppLayout component={SettingScreen} isProtected={true} />} />
      <Route path='/*' element={<AppLayout component={WelcomeScreen} isProtected={false} />} />
      <Route path='/' element={<Navigate to={APP_NAV.WELCOME} />} />
    </Routes>
  );
};

export default AppRoutes;
