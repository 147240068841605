import { Card, CardContent, IconButton, Typography } from '@mui/material';

import AddIcon from '../../../components/CustomIcons/AddIcon';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	onClick: () => void;
}

/**
 * Component representing a card for creating a new group.
 *
 * @param {Props} props Component props.
 * @returns {JSX.Element} The rendered component.
 */
const CreateGroupCard: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();

	return (
		<Card sx={styles.card}>
			<CardContent sx={styles.cardContent}>
				<IconButton sx={styles.actionBtn} onClick={props.onClick}>
					<AddIcon />
				</IconButton>
				<Typography variant={'p2'} sx={styles.title}>{t('createGroup')}</Typography>
			</CardContent>
		</Card>
	);
};

export default CreateGroupCard;