import { ATTACHMENT_TYPE_LIST, MESSAGE_PRIORITY_LIST } from '../../../../utils/ui-constants';
import { Box, Fab, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '../../../../components/CustomIcons/AddIcon';
import ChatUtil from '../../../../utils/chat-util';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import { MessagePriority } from '../../../../utils/constants';
import MicIcon from '../../../../components/CustomIcons/MicIcon';
import PriorityIcon from '../../../../components/CustomIcons/PriorityIcon';
import SendIcon from '../../../../components/CustomIcons/SendIcon';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onSendMessage: (message: string, priority: MessagePriority) => void;
}

/**
 * ChatFooter Component
 * 
 * This component renders the chat footer section at the bottom of a chat window.
 * It provides functionalities for composing and sending messages.
 */
const ChatFooter: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [priority, setPriority] = useState<MessagePriority>(MessagePriority.Normal);
  const styles = useStyles(ChatUtil.isUrgent(priority));
  const [priorityAnchorEl, setPriorityAnchorEl] = React.useState<null | HTMLElement>(null);
  const [attachmentAnchorEl, setAttachmentAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  /**
   * Handles clicks on the upload attachment button.
   * 
   * (Placeholder for future implementation)
   */
  const handleAttachmentTypeClick = (menuItem: MenuData) => {
    setAttachmentAnchorEl(null);
    // TODO: handle actions for different attachment types
    fileInputRef.current?.click();
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  /**
   * Handles clicks on the priority button.
   */
  const handlePriorityChange = (menuItem: MenuData) => {
    setPriorityAnchorEl(null);
    setPriority(menuItem.id as MessagePriority);
  }

  const onMediaCloseClick = () => {
    setFile(null);
    setPreviewUrl(null);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (message.trim()) {
      props.onSendMessage(message.trim(), priority);
      setMessage('');
    }
  }

  return (
    <Box sx={styles.footer}>
      <Box sx={styles.msgLayout}>
      <Fab color='secondary' sx={styles.fab} onClick={(event) => setAttachmentAnchorEl(event.currentTarget)}>
        <AddIcon />
      </Fab>
      <TextField
        type='text'
        multiline
        maxRows={4}
        placeholder={t('messageTxt')}
        sx={styles.textField}
        value={message}
        onChange={e => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          sx: styles.outerInput,
          endAdornment:
            <InputAdornment position='end'>
              <IconButton sx={styles.iconBtn} onClick={(event) => setPriorityAnchorEl(event.currentTarget)} edge='end'>
                <PriorityIcon />
              </IconButton>
            </InputAdornment>
        }}
        inputProps={{ sx: styles.innerInput }}
      />
      {
        (message.trim() || previewUrl) ? (
          <Fab
            color={ChatUtil.isUrgent(priority) ? 'error' : 'primary'}
            sx={styles.fab}
            onClick={sendMessage}
          >
            <SendIcon />
          </Fab>
        ) : (
          <Fab color='secondary' sx={styles.fab}>
            <MicIcon />
          </Fab>
        )
      }
      </Box>
      {/* <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      {previewUrl && 
      <Box sx={styles.previewLayout}>
        <Box>
          <Box component='img' src={previewUrl} alt={file?.name} width="50" height="50" />
          <IconButton />
        </Box>
      </Box>
} */}
      <MenuSelectDialog
        anchorEl={priorityAnchorEl}
        open={Boolean(priorityAnchorEl)}
        onClose={() => setPriorityAnchorEl(null)}
        menuList={MESSAGE_PRIORITY_LIST}
        onMenuItemSelected={handlePriorityChange}
      />
      <MenuSelectDialog
        anchorEl={attachmentAnchorEl}
        open={Boolean(attachmentAnchorEl)}
        onClose={() => setAttachmentAnchorEl(null)}
        menuList={ATTACHMENT_TYPE_LIST}
        onMenuItemSelected={handleAttachmentTypeClick}
      />
    </Box>
  );
};

export default ChatFooter;
