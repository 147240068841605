import {
	ADD_GROUP_MEMBER,
	API_FAILURE,
	CREATE_GROUP,
	DirectoryActionTypes,
	FETCH_GROUP_MEMBERS,
	REMOVE_GROUP_MEMBER,
	RESET_DIRECTORY_STATE,
	RESET_ERROR_STATE,
	SEARCH_DIRECTORY,
	SEARCH_DIRECTORY_SUCCESS,
	UPDATE_GROUP,
	UPDATE_GROUP_ROLE,
} from './action-types';

import DirectoryState from '../../types/states/directory-state';

export const directoryInitialState: DirectoryState = {
	apiStatus: undefined,
	recordList: []
};

/**
 * Directory reducer handles actions related to directory data.
 * 
 * @param {DirectoryState} state - The current directory state.
 * @param {DirectoryActionTypes} action - The action to be handled.
 * 
 * @returns {DirectoryState} The updated directory state.
 */
const directoryReducer = (state: DirectoryState, action: DirectoryActionTypes): DirectoryState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case SEARCH_DIRECTORY:
		case CREATE_GROUP:
		case UPDATE_GROUP:
		case ADD_GROUP_MEMBER:
		case REMOVE_GROUP_MEMBER:
		case UPDATE_GROUP_ROLE:
		case FETCH_GROUP_MEMBERS:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case SEARCH_DIRECTORY_SUCCESS:
			return {
				...state,
				recordList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_DIRECTORY_STATE:
			return directoryInitialState;
		default:
			return state;
	}
}

export default directoryReducer;