import APIConfig from '../../../service/api-config';
import { Box } from '@mui/material';
import ContentLayout from './ContentLayout';
import DrawerNavigator from './DrawerNavigator';
import React from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useSessionHandler } from '../../../hooks/use-session-handler';
import useStyles from './styles';

export interface MainProps {
  children: React.ReactNode;
}

/**
 * Main Component
 * 
 * This component renders the main layout for the application.
 * It includes the DrawerNavigator component, content wrapper, and child content.
 * 
 * @param {MainProps} props - Component properties with children (content to display).
 * @returns {JSX.Element} - The JSX element for the Main component.
 */
const Main: React.FC<MainProps> = (props: MainProps) => {

  const styles = useStyles();
  // Call session handler hook for session/idle time management.
  useSessionHandler();

  return (
    <StompSessionProvider url={`${APIConfig.chatBaseURL}${APIConfig.chatWS}`}>
      <Box sx={styles.root}>
        <DrawerNavigator />
        <ContentLayout>
          {props.children}
        </ContentLayout>
      </Box>
    </StompSessionProvider>
  );
}

export default Main;
