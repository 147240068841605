import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeactivateIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6868_10428)'>
          <path d='M12.2235 23.5C15.2882 23.5 18.2274 22.2621 20.3945 20.0585C22.5616 17.855 23.7791 14.8663 23.7791 11.75C23.7791 8.63371 22.5616 5.64505 20.3945 3.4415C18.2274 1.23794 15.2882 0 12.2235 0C9.1588 0 6.2196 1.23794 4.05251 3.4415C1.88543 5.64505 0.667969 8.63371 0.667969 11.75C0.667969 14.8663 1.88543 17.855 4.05251 20.0585C6.2196 22.2621 9.1588 23.5 12.2235 23.5ZM8.97352 10.6484H15.4735C16.0739 10.6484 16.5569 11.1396 16.5569 11.75C16.5569 12.3604 16.0739 12.8516 15.4735 12.8516H8.97352C8.37318 12.8516 7.89019 12.3604 7.89019 11.75C7.89019 11.1396 8.37318 10.6484 8.97352 10.6484Z' fill='currentColor' />
        </g>
        <defs>
          <clipPath id='clip0_6868_10428'>
            <rect width='23.1111' height='23.5' fill='transparent' transform='translate(0.667969)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DeactivateIcon;