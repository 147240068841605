import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

import Dimens from '../../../theme/dimens';
import DirectoryIcon from '../../../components/CustomIcons/DirectoryIcon';
import MapPinIcon from '../../../components/CustomIcons/MapPinIcon';
import PhoneIcon from '../../../components/CustomIcons/PhoneIcon';
import React from 'react';
import Site from '../../../types/site';
import useStyles from './styles';

/**
 * Interface defining props for the SiteCard component.
 * 
 * @interface SiteCardProps
 */
interface SiteCardProps {
	/**
	 * Site data object containing details about the site.
	 */
	site: Site;
	/**
	 * Callback function to be invoked when the user selects this site card.
	 * 
	 * @param {Site} param - The selected site object.
	 */
	onItemSelected: (param: Site) => void;
}

/**
 * SiteCard component for displaying information about a site.
 *
 * This component renders a Material-UI Card with details about a site, including its name, 
 * address (if available), and phone number (if available). It utilizes custom icons and 
 * handles clicks to trigger a callback function for site selection.
 *  
 * @param {SiteCardProps} props - Component properties including site data and selection callback.
 * 
 * @returns {JSX.Element} - The rendered SiteCard component.
 */
const SiteCard: React.FC<SiteCardProps> = (props: SiteCardProps) => {

	const { site, onItemSelected, ...rest } = props;
	const styles = useStyles();

	const onCardClick = () => {
		onItemSelected(site);
	};

	return (

		<Card sx={styles.card} onClick={onCardClick}>
			<CardActionArea sx={styles.cardActionArea}>
				<CardContent sx={styles.cardContent}>
					<Box sx={styles.title}>
						<DirectoryIcon sx={styles.icon} />
						<Typography
							variant='p1'
							fontWeight={Dimens.fontWeight.semiBold}
							sx={styles.textEllipsis(1)}
						>
							{site.name}
						</Typography>
					</Box>
					{site.address &&
						<Box sx={styles.subTitle}>
							<MapPinIcon sx={styles.icon} />
							<Typography variant='p1' sx={styles.textEllipsis(2)}>{site.address}</Typography>
						</Box>
					}
					{site.phoneNumber &&
						<Box sx={styles.subTitle}>
							<PhoneIcon sx={styles.icon} />
							<Typography variant='p1' sx={styles.textEllipsis(1)}>{site.phoneNumber}</Typography>
						</Box>
					}
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default SiteCard;