import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

const useStyles = () => {

  const { lg, container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      bgcolor: theme.palette.background.paper,
      flexGrow: 1
    },
    leftPane: {
      flex: 0.3,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      p: container,
      [theme.breakpoints.down('md')]: {
        flex: 1,
        bgcolor: theme.palette.primary.light
      },
      [theme.breakpoints.down('sm')]: {
        bgcolor: theme.palette.background.paper,
        p: lg
      }
    },
    rightPane: {
      flex: 0.7,
      display: 'flex',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    image: {
      flex: 1,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  };

  return styles;
};

export default useStyles;