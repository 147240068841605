import Colors from '../../../theme/colors';
import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the SiteCard component.
 * 
 * @returns {object} - An object containing styles for the SiteCard component.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();

	const styles = {
		...commonStyles,
		card: {
			borderRadius: Dimens.radius.md,
			boxShadow: `0px ${Dimens.spacing.xxs} ${Dimens.spacing.xs} 0px ${Colors.shadow}`,
			'&:hover': {
				cursor: 'pointer'
			}
		},
		cardActionArea: {
			p: Dimens.spacing.section,
		},
		cardContent: {
			flexDirection: 'column',
			'&.MuiCardContent-root': {
				p: 0
			}
		},
		title: {
			display: 'flex',
			flexDirection: 'row'
		},
		subTitle: {
			display: 'flex',
			flexDirection: 'row',
			pt: Dimens.spacing.lg
		},
		icon: {
			color: theme.palette.primary.main,
			fontSize: Dimens.icon.sm,
			mt: Dimens.spacing.xxs,
			mr: Dimens.spacing.lg
		}
	};
	
	return styles;
};

export default useStyles;