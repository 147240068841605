import { Box, IconButton, Typography } from '@mui/material';

import { ActionType } from '../../../utils/constants';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import React from 'react';
import useStyles from './styles';

export type Props = {
  title?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  hasBorder?: boolean;
  hasDefaultBg?: boolean;
  onActionClick?: (action: ActionType) => void;
  onClose: () => void;
};

/**
 * RightDrawerHeader component for displaying the header section of a right-aligned drawer.
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The rendered component.
 */
const RightDrawerHeader: React.FC<Props> = (props: Props) => {

  const {
    title,
    leftIcon,
    rightIcon,
    onActionClick,
    onClose,
  } = props;
  const styles = useStyles(props);

  /**
   * Handles click events for left and right icons.
   *
   * @param {ActionType} action - The type of action (left or right).
   */
  const handleActionClick = (action: ActionType) => {
    if (onActionClick) {
      onActionClick(action);
    }
  }

  return (
    <Box sx={styles.headerLayout}>
      {leftIcon &&
        <IconButton onClick={() => handleActionClick(ActionType.BackAction)}>{leftIcon}</IconButton>
      }
      {title &&
        <Typography variant='h3' sx={styles.title}>{title}</Typography>
      }
      <Box>
        {rightIcon &&
          <IconButton onClick={() => handleActionClick(ActionType.PrimaryAction)}>{rightIcon}</IconButton>
        }
        <IconButton onClick={onClose}><CrossIcon /></IconButton>
      </Box>
    </Box>
  );
};

export default RightDrawerHeader;
