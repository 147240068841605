import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.375 19.002L35.625 19.002' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
        <path d='M19 2.37695V35.627' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
      </svg>
    </SvgIcon>
  );
}

export default AddIcon;