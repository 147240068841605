import { Box } from '@mui/material';
import React from 'react';
import useStyles from './styles';

const SettingScreen: React.FC = () => {

  const styles = useStyles();
  
  return (
    <Box sx={styles.container}>
      {'Settings'}
    </Box>
  );
};

export default SettingScreen;
