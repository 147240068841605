import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for DirectoryScreen styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { xxs, xs, base, xl, section, lg } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 'inherit'
    },
    subContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      overflow: 'auto'
    },
    leftPane: {
      ...commonStyles.defaultBorder,
      display: 'flex',
      flex: 0.3,
      m: `${section} 0 ${section} ${section}`,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    rightPane: {
      flex: 0.7,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flex: 1
      }
    },
    rightPaneHeader: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    searchLayout: {
      display: 'flex',
      flexDirection: 'row',
      m: `${section} ${section} 0 ${section}`,
      [theme.breakpoints.down('sm')]: {
        m: `0 ${lg} 0 ${lg}`
      }
    },
    searchInput: {
      mr: base,
      flex: 1
    },
    filterBtn: {
      p: 0,
      'svg': {
        ...commonStyles.smIcon,
        color: theme.palette.text.primary
      }
    },
    contentWrapper: {
      overflowY: 'auto',
      m: lg,
      p: 0
    },
    content: {
      m: 0
    },
    card: {
			borderRadius: Dimens.radius.lg,
			boxShadow: `0px ${xxs} ${xs} 0px ${Colors.shadow}`,
			height: Dimens.directoryCardMaxHeight,
      alignItems: 'center',
      justifyContent: 'center',
			display: 'flex',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		cardActionArea: {
			p: xl,
			alignItems: 'center',
			justifyContent: 'center'
		},
    cardContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			'&.MuiCardContent-root': {
				p: 0
			}
		},
		title: {
			...commonStyles.textEllipsis(1),
			color: theme.palette.text.primary,
			fontWeight: Dimens.fontWeight.medium
		}
  };

  return styles;
};

export default useStyles;