import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';

import CheckboxSelector from '../../../components/CheckboxSelector';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInputSelect from '../../../components/CustomInputSelect';
import { DIRECTORY_TYPE_LIST } from '../../../utils/ui-constants';
import Department from '../../../types/department';
import Dimens from '../../../theme/dimens';
import DirectoryFilter from '../../../types/ui/directory-filter';
import React, { } from 'react';
import { SORT_BY_ALPHABET_MENU } from '../../../utils/constants';
import Site from '../../../types/site';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface DirectorySortFilterProps {
  deptList: Array<Department>;
  siteList: Array<Site>;
  filter: DirectoryFilter;
  onResetFilter: () => void;
  onApplyFilter: (values: DirectoryFilter) => void;
}

/**
 * DirectorySortFilter component for sorting and filtering directory data.
 * 
 * This component allows users to filter directory data based on various criteria,
 * including sort order, types, sites, and departments.
 * 
 * @props DirectorySortFilterProps - Props containing:
 *   - deptList: An array of departments.
 *   - siteList: An array of sites.
 *   - filter: The current filter object.
 *   - onResetFilter: Callback to reset the filter.
 *   - onApplyFilter: Callback to apply the filter.
 *
 * @returns {JSX.Element} - The rendered DirectorySortFilter component.
 */
const DirectorySortFilter: React.FC<DirectorySortFilterProps> = (props: DirectorySortFilterProps) => {

  const styles = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const formikRef = React.createRef<FormikProps<DirectoryFilter>>();

  /**
   * Handles resetting the form and filters.
   * 
   * This function resets the formik form and triggers the `onResetFilter` callback
   * to clear any applied filters.
   */
  const handleReset = () => {
    formikRef.current?.resetForm();
    props.onResetFilter();
  }

  return (
    <Box sx={styles.filterContainer}>
      <Box sx={styles.sortFilterHeader}>
        <Typography variant='h5' fontWeight={Dimens.fontWeight.bold}>
          {t('sortNFilters')}
        </Typography>
        {!isTablet &&
          <Typography variant='caption' sx={styles.resetBtn} onClick={handleReset}>
            {t('resetAll')}
          </Typography>
        }
      </Box>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={props.filter}
        onSubmit={values => props.onApplyFilter(values)}>
        {({ dirty }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box sx={styles.sortFilterContent}>
              <Field
                name='sortBy'
                label={t('sortBy')}
                menu={SORT_BY_ALPHABET_MENU}
                component={CustomInputSelect}
                showCheckedIcon
              />
              <Divider sx={styles.divider} />
              <Field
                name='types'
                label={t('type')}
                component={CheckboxSelector}
                menu={DIRECTORY_TYPE_LIST}
              />
              <Divider sx={styles.divider} />
              <Field
                name='sites'
                label={t('sites')}
                placeholder={t('findSites')}
                component={CustomAutocomplete}
                multiple
                noOptionsText={t('noSites')}
                menu={props.siteList}
                showDropdownIcon
              />
              <Divider sx={styles.divider} />
              <Field
                name='departments'
                label={t('departments')}
                placeholder={t('findDepartments')}
                component={CustomAutocomplete}
                noOptionsText={t('noDepts')}
                multiple
                menu={props.deptList}
                showDropdownIcon
              />
            </Box>
            <Box sx={styles.applyBtn}>
              {isTablet &&
                <Typography variant='caption' sx={styles.resetBtn} onClick={handleReset}>
                  {t('resetAll')}
                </Typography>
              }
              <CustomButton type='submit' title={t('apply')} disabled={!dirty} fullWidth={!isTablet} />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DirectorySortFilter;
