import { ActualSessionStatus, AssignedSessionStatus, DATE_PATTERN_MMM_YYYY, InteractionType } from '../../../utils/constants';
import { Avatar, Badge, BadgeOrigin, Box, Card, CardContent, IconButton, ListItemButton, Typography, useMediaQuery, useTheme } from '@mui/material';

import APP_NAV from '../../../routes/app-nav';
import ChatUtil from '../../../utils/chat-util';
import ConversationData from '../../../types/conversation-data';
import CustomButton from '../../../components/CustomButton';
import { DirectoryRecord } from '../../../types/directory-record';
import LoginUtil from '../../../utils/login-util';
import MessageIcon from '../../../components/CustomIcons/MessageIcon';
import PhoneIcon from '../../../components/CustomIcons/PhoneIcon';
import React from 'react';
import Util from '../../../utils/util';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface for DirectoryCard component props.
 */
interface Props {
	record: DirectoryRecord;
	onItemSelected: (param: DirectoryRecord) => void;
}

/**
 * DirectoryCard component renders a card for each directory record.
 * 
 * @param {Props} props - Component properties containing directory record and callback for item selection.
 * 
 * @returns {JSX.Element} The JSX element for the DirectoryCard component.
 */
const DirectoryCard: React.FC<Props> = (props: Props) => {

	const { record, onItemSelected, ...rest } = props;
	const styles = useStyles(record);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const navigate = useNavigate();

	/**
	 * Generates the subtitle for the card based on record type. 
	 * 
	 * @returns {string} The subtitle text.
	 */
	const getSubTitle = () => {
		let subTitle = record.position;
		if (record.groupRoomKey) {
			subTitle = `${t('created')} ${Util.formatUTCtoLocal(record.createdAt, DATE_PATTERN_MMM_YYYY)}`
		}

		return subTitle;
	}

	/**
	 * Checks if the user is not a member of the group associated with the record.
	 * 
	 * @returns {boolean} True if the user is not a member, false otherwise.
	 */
	const isNotGroupMember = () => {

		return (record.groupRoomKey && !record.isMember);
	}

	/**
	 * Handles click event for the "Join Group" button (implementation pending).
	 * 
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The click event object.
	 */
	const joinGroupBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		// TODO: Add join group logic.
	}

	/**
	 * Handles click event for the "Call" button (implementation pending).
	 * 
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The click event object.
	 */
	const onCallBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		// TODO: Add call logic
	}

	/**
	 * Handles click event for the "Message" button. Navigates to inbox detail with conversation data.
	 * 
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The click event object.
	 */
	const onMessageBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		const conversation: ConversationData = {
			...record,
			interactionType: record.groupRoomKey ? InteractionType.Group : InteractionType.Private,
			senderLoginId: LoginUtil.getLoginId(),
			senderName: LoginUtil.getUserFullName(),
			recipientLoginId: record.email,
			recipientName: record.name,
			recipientActualSessionStatus: ActualSessionStatus.Online,
			recipientAssignedSessionStatus: AssignedSessionStatus.NotDefined,
			count: 0
		};
		navigateToInboxDetail(conversation);
	}

	/**
	 * Navigates to the inbox detail page with the provided conversation data.
	 * 
	 * @param {ConversationData} conversation - The conversation data to be passed to the inbox detail page.
	 */
	const navigateToInboxDetail = (conversation: ConversationData) => {
		navigate(`${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`, {
			state: conversation
		});
	}

	return (
		<Card {...rest} sx={styles.card} onClick={(e) => onItemSelected(record)}>
			<ListItemButton sx={styles.cardActionArea} disableRipple>
				<CardContent sx={styles.cardContent}>
					<Badge
						variant='dot'
						overlap='circular'
						sx={styles.badge}
						anchorOrigin={styles.badgeOrigin as BadgeOrigin}
					>
						<Avatar sx={styles.avatar}>
							<Typography variant='p3'>{Util.getInitialsFromName(record.name)}</Typography>
						</Avatar>
					</Badge>
					<Typography variant={isNotGroupMember() ? 'h5' : 'p2'} sx={styles.title}>{record.name}</Typography>
					{(record.email || record.isMember) &&
						<Typography variant='caption' sx={styles.subTitle}>{getSubTitle()}</Typography>
					}
					<Box sx={styles.actionLayout}>
						{isNotGroupMember() ? (
							<CustomButton sx={styles.joinGrpBtn} title={t('requestToJoin')} small onClick={joinGroupBtnClick} />
						) : (
							<Box visibility={ChatUtil.isLoggedInUser(record.email) ? 'hidden' : 'visible'}>
								<IconButton onClick={onCallBtnClick}><PhoneIcon /></IconButton>
								<IconButton onClick={onMessageBtnClick}><MessageIcon /></IconButton>
							</Box>
						)}
					</Box>
				</CardContent>
			</ListItemButton>
		</Card>
	);
};

export default DirectoryCard;