import AuthLayout from '../../layouts/AuthLayout';
import { Box } from '@mui/material';
import CircularProgressBar from '../../components/CircularProgressBar';
import InlineAlert from '../../components/InlineAlert';
import React from 'react';
import SignInFooter from './SignInFooter';
import SignInHeader from './SignInHeader';
import useStyles from './styles';

interface Props<T = any> { /* eslint-disable-line */
  headerTitle: string;
  headerDesc: string;
  footerCaption?: string;
  footerAction?: string;
  hideFooter?: boolean;
  children: React.ReactNode;
  image: string;
  error?: string;
  showProgressBar?: boolean;
  onFooterClick?: () => void;
}

/**
 * SignInLayout Component
 *
 * This component renders a layout for sign-in or sign-up forms. It provides a consistent
 * structure with header, form, footer, and progress indicator.
 *
 * @props { Props<T> } - The component properties where T is the type of the object eg: SignInRequest.
 * @property { string } headerTitle - The title text for the header.
 * @property { string } headerDesc - The description text for the header.
 * @property { string } footerCaption - The caption text for the footer.
 * @property { string } footerAction - The action text for the footer link.
 * @property { boolean } hideFooter? - (Optional) Flag to control footer visibility (defaults to false).
 * @property { React.ReactNode } children - Child elements to be rendered within the form.
 * @property { string } image - The background image path for the layout (right pane).
 * @property { string } error? - (Optional) Error message to be displayed.
 * @property { boolean } showProgressBar? - (Optional) Flag to control progress bar visibility (defaults to false).
 * @property { () => void } onFooterClick - Function to be called on footer link click.
 *
 * @returns {JSX.Element} - The rendered JSX element for the sign-in layout.
 */
const SignInLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  const onFooterClick = () => {
    if (props.onFooterClick) {
      props.onFooterClick();
    }
  }

  return (
    <AuthLayout image={props.image}>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.content}>
          <SignInHeader title={props.headerTitle} desc={props.headerDesc} />
          <InlineAlert message={props.error} />
          {props.children}
        </Box>
        {!props.hideFooter &&
          <SignInFooter
            caption={props.footerCaption || ''}
            action={props.footerAction || ''}
            hideFooter={props.hideFooter}
            onFooterClick={onFooterClick}
          />
        }
      </Box>
      <CircularProgressBar show={props.showProgressBar} />
    </AuthLayout>
  );
};

export default SignInLayout;
