import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AlertTriangleIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='27' height='25' viewBox='0 0 27 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M11.5358 3.3138L1.80756 19.5544C1.60712 19.9018 1.50109 20.2955 1.50001 20.6965C1.49893 21.0976 1.60283 21.4919 1.80139 21.8403C1.99995 22.1887 2.28625 22.4791 2.63183 22.6826C2.97741 22.886 3.37022 22.9955 3.77122 23.0001H23.2288C23.6298 22.9955 24.0226 22.886 24.3682 22.6826C24.7137 22.4791 25 22.1887 25.1986 21.8403C25.3972 21.4919 25.5011 21.0976 25.5 20.6965C25.4989 20.2955 25.3929 19.9018 25.1924 19.5544L15.4631 3.3138C15.2582 2.9765 14.9699 2.69766 14.626 2.50415C14.282 2.31064 13.8941 2.20898 13.4994 2.20898C13.1048 2.20898 12.7168 2.31064 12.3729 2.50415C12.0289 2.69766 11.7406 2.9765 11.5358 3.3138Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5688 9.13892V13.7593' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5688 18.3797H13.5789' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default AlertTriangleIcon;