import AppError from '../../exception/app-error';
import Challenge from '../../types/challenge';
import SignInResponse from '../../types/sign-in-response';

export const INIT_API = 'INIT_API';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const CHALLENGE_SUCCESS = 'CHALLENGE_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

export interface InitApiAction {
  type: typeof INIT_API
}

export interface ChallengeSuccessAction {
  type: typeof CHALLENGE_SUCCESS;
  payload: Array<Challenge>;
}

export interface SignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: SignInResponse;
}

export interface SignOutSuccessAction {
  type: typeof SIGN_OUT_SUCCESS;
  payload: boolean;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export type SignInActionTypes = InitApiAction | ChallengeSuccessAction | SignInSuccessAction
  | SignOutSuccessAction | ApiFailureAction;