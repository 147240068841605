import {
	CREATE_SITE,
	DELETE_SITE,
	FETCH_SITE_DETAIL,
	FETCH_SITE_LIST,
	RESET_ERROR_STATE,
	RESET_SITE_STATE,
	SITE_FAILURE,
	SITE_LIST_SUCCESS,
	SiteActionTypes,
	UPDATE_SITE,
} from './action-types';

import SiteState from '../../types/states/site-state';

export const siteInitialState: SiteState = {
	apiStatus: undefined,
	siteList: [],
	site: undefined
};

/**
 * Site Reducer function to handle state updates based on dispatched actions.
 *
 * @param {SiteState} state - The current site state.
 * @param {SiteActionTypes} action - The dispatched action object.
 * @returns {SiteState} - The updated site state.
 */
const siteReducer = (state: SiteState, action: SiteActionTypes): SiteState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case FETCH_SITE_LIST:
		case FETCH_SITE_DETAIL:
		case CREATE_SITE:
		case UPDATE_SITE:
		case DELETE_SITE:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case SITE_LIST_SUCCESS:
			return {
				...state,
				siteList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case SITE_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_SITE_STATE:
			return siteInitialState;
		default:
			return state;
	}
}

export default siteReducer;