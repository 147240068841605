import { UserProfileStatus } from '../../../utils/constants';
import useCommonStyles from '../../../theme/use-common-styles';

/**
 * Creates styles for the user list table row, applying different cursor styles based on the user's status.
 *
 * @param {string} status - The user's profile status.
 * 
 * @returns {object} An object containing styles for the table row and option menu button.
 */
const useStyles = (status = '') => {

  const commonStyles = useCommonStyles();

  const styles = {
    tableRow: {
      cursor: (status === UserProfileStatus.Pending) ? 'unset' : 'pointer'
    },
    optionMenuBtn: {
      justifyContent: 'end',
      '& svg':{
        ...commonStyles.smIcon
      }
    }
  };

  return styles;
};

export default useStyles;
