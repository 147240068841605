import Dimens from '../../theme/dimens';

/**
 * Generates MUI styles for elements used in the RegisterDepartmentScreen component.
 */
const useStyles = () => {

  const styles = {
    icon: {
      fontSize: `${Dimens.icon.sm} !important`
    },
    form: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  };

  return styles;
};

export default useStyles;