import {
	API_FAILURE,
	ApiFailureAction,
	FETCH_POSITION_LIST,
	FETCH_USER_DETAIL,
	FETCH_USER_LIST,
	FetchPositionListAction,
	FetchUserDetailAction,
	FetchUserListAction,
	INVITE_USERS,
	INVITE_USERS_SUCCESS,
	InviteUsersAction,
	InviteUsersSuccessAction,
	POSITION_LIST_SUCCESS,
	PositionListSuccessAction,
	RESET_ERROR_STATE,
	RESET_USER_STATE,
	ResetErrorStateAction,
	SEARCH_USER_LIST,
	SEARCH_USER_LIST_SUCCESS,
	SearchUserListAction,
	SearchUserListSuccessAction,
	UPDATE_USER_DETAIL,
	USER_LIST_SUCCESS,
	UpdateUserDetailAction,
	UserListSuccessAction,
	UserStateResetAction
} from './action-types';

import AppError from '../../exception/app-error';
import { User } from '../../types/user';

/**
 * Resets the error state.
 *
 * @returns {ResetErrorStateAction} A ResetErrorStateAction object.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Action creator to indicate the start of a user list search.
 *
 * @returns {SearchUserListAction} An action object indicating the search initiation.
 */
export const initUserListSearch = (): SearchUserListAction => ({
  type: SEARCH_USER_LIST,
});

/**
 * Action creator to indicate the start of fetching user details.
 *
 * @returns {FetchUserDetailAction} An action object indicating the start of fetching user details.
 */
export const initUserDetailFetch = (): FetchUserDetailAction => ({
  type: FETCH_USER_DETAIL,
});

/**
 * Action creator to indicate the start of updating user details.
 *
 * @returns {UpdateUserDetailAction} An action object indicating the start of updating user details.
 */
export const initUserDetailUpdate = (): UpdateUserDetailAction => ({
  type: UPDATE_USER_DETAIL,
});

/**
 * Action creator to indicate successful search user list retrieval.
 *
 * @param {Array<User>} userList - The list of users retrieved from the search.
 * 
 * @returns {SearchUserListSuccessAction} An action object with the user list payload.
 */
export const searchUserListSuccess = (userList: Array<User>): SearchUserListSuccessAction => ({
	type: SEARCH_USER_LIST_SUCCESS,
	payload: userList
});

/**
 * Initiates fetching the user list.
 *
 * @returns {FetchUserListAction} A FetchUserListAction object.
 */
export const initUserListFetch = (): FetchUserListAction => ({
	type: FETCH_USER_LIST
});

/**
 * Sets the user list after successful fetching.
 *
 * @param {Array<UserProfile>} userList The fetched user list.
 * 
 * @returns {UserListSuccessAction} A UserListSuccessAction object with the user list payload.
 */
export const userListSuccess = (userList: Array<User>): UserListSuccessAction => ({
	type: USER_LIST_SUCCESS,
	payload: userList
});

/**
 * Action creator to initiate fetching the list of available positions.
 *
 * @returns {FetchPositionListAction} An action object indicating the start of position list fetching.
 */
export const initPositionListFetch = (): FetchPositionListAction => ({
	type: FETCH_POSITION_LIST
});

/**
 * Action creator to indicate successful fetching of the position list.
 *
 * @param {Array<string>} positionList - The list of fetched positions.
 * 
 * @returns {PositionListSuccessAction} An action object containing the fetched position list.
 */
export const positionListSuccess = (positionList: Array<string>): PositionListSuccessAction => ({
	type: POSITION_LIST_SUCCESS,
	payload: positionList
});

/**
 * Action creator to initiate the user invitation process.
 *
 * @returns {InviteUsersAction} An action object indicating the start of user invitation.
 */
export const initUserInvite = (): InviteUsersAction => ({
	type: INVITE_USERS
});

/**
 * Action creator to indicate successful user invitation.
 *
 * @returns {InviteUsersSuccessAction} An action object indicating successful user invitation.
 */
export const userInviteSuccess = (): InviteUsersSuccessAction => ({
	type: INVITE_USERS_SUCCESS
});

/**
 * Handles API failure with an error.
 *
 * @param {AppError} error The encountered error.
 * 
 * @returns {ApiFailureAction} An ApiFailureAction object with the error payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * Resets the user state.
 *
 * @returns {UserStateResetAction} A UserStateResetAction object.
 */
export const resetUserState = (): UserStateResetAction => ({
	type: RESET_USER_STATE
});