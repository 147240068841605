import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CheckboxSelector component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	
	const styles = {
		wrapper: {
      flexDirection: 'column',
      display: 'flex',
      flex: 1
    },
		header: {
			flexDirection: 'row',
			pb: Dimens.spacing.xs
		},
		subLabel: {
			pl: Dimens.spacing.xxs,
			color: theme.palette.text.secondary,
			fontWeight: Dimens.fontWeight.semiBold
		},
		formGroup: {
			flexDirection: 'row',
			'.MuiFormControlLabel-root': {
				m: 0,
				pr: Dimens.spacing.section,
				'span': {
					p: 0,
					pr: Dimens.spacing.xxs,
					'svg': {
						...commonStyles.smIcon
					}
				}
			}
		},
		fieldError: {
			...theme.typography.footer,
			m: 0,
			pt: Dimens.spacing.xs,
			color: theme.palette.error.main
		}
	};

	return styles;
};

export default useStyles;