import { Box, Divider, Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

import React from 'react';
import useStyles from './styles';

export interface Props extends ButtonProps {
	destructive?: boolean;
	leftTitle?: string;
	leftIcon?: JSX.Element;
	onLeftButtonClick?: () => void;
	rightTitle?: string;
	rightIcon?: JSX.Element;
	onRightButtonClick?: () => void;
}

/**
 * SegmentedButton: A custom MUI Button component with two segments.
 *
 * This component provides a button with two clickable sections, ideal for situations 
 * where you want to offer users distinct but related actions. Each segment can have 
 * its own title, icon, and click handler.
 * 
 * @props {Props} - Extends ButtonProps with additional properties for customization:
 *   - destructive (boolean): Optional flag for styling a destructive action on the left.
 *   - leftTitle (string): Title displayed on the left segment.
 *   - leftIcon (JSX.Element): Optional JSX element for an icon on the left.
 *   - onLeftButtonClick (function): Function to be called on left segment click.
 *   - rightTitle (string): Title displayed on the right segment.
 *   - rightIcon (JSX.Element): Optional JSX element for an icon on the right.
 *   - onRightButtonClick (function): Function to be called on right segment click.
 *
 * @returns {JSX.Element} - The rendered SegmentedButton component.
 */
const SegmentedButton: React.FC<Props> = (props: Props) => {

	const {
		destructive,
		title,
		leftTitle,
		leftIcon,
		onLeftButtonClick,
		rightTitle,
		rightIcon,
		onRightButtonClick,
		...rest
	} = props;
	const styles = useStyles(props);

	return (
		<Box sx={styles.container}>
			<Button
				{...rest}
				sx={{ ...styles.base, ...styles.leftButton }}
				startIcon={leftIcon}
				variant='contained'
				disableElevation
				onClick={onLeftButtonClick}
			>
				{leftTitle &&
					<Typography variant='p1' sx={styles.label}>{leftTitle}</Typography>
				}
			</Button>
			<Divider sx={styles.divider} />
			<Button
				{...rest}
				sx={{ ...styles.base, ...styles.rightButton }}
				startIcon={rightIcon}
				variant='contained'
				disableElevation
				onClick={onRightButtonClick}
			>
				{rightTitle &&
					<Typography variant='p1' sx={styles.label}>{rightTitle}</Typography>
				}
			</Button>
		</Box>
	);
};

export default SegmentedButton;
