import { Box, Stack, Typography } from '@mui/material';

import ChatUtil from '../../../../../utils/chat-util';
import CheckFilled2Icon from '../../../../../components/CustomIcons/CheckFilled2Icon';
import MenuData from '../../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../../components/MenuSelectDialog';
import MessageData from '../../../../../types/message-data';
import { MessageOptions } from '../../../../../utils/constants';
import React from 'react';
import { getMessageActionList } from '../../../../../utils/ui-constants';
import useStyles from './styles';

interface Props {
  messageData: MessageData;
  disableMessageClick?: boolean;
  handleMsgActionClick: (action: MessageOptions) => void;
}

/**
 * RightChatItem Component
 * 
 * This component renders a right-aligned chat message item, representing 
 * messages sent by the current user. It displays the message content 
 * and a timestamp using `ChatUtil.getChatFormatTime`.
 */
const RightChatItem: React.FC<Props> = (props: Props) => {

  const { messageData, disableMessageClick } = props;
  const styles = useStyles(ChatUtil.isUrgent(messageData.priority), disableMessageClick);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Handles the click event for an item in the action menu.
   *
   * @param {MenuData} item - The clicked menu item object.
   * 
   * Closes the action menu and delegates handling the action to the parent component through props.
   */
  const handleActionMenuClick = (item: MenuData) => {
    setActionMenuAnchorEl(null);
    props.handleMsgActionClick(item.id as MessageOptions);
  }

  /**
   * Handles the click event on a message.
   *
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e - The click event object.
   */
  const handleMessageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disableMessageClick) {
      setActionMenuAnchorEl(e.currentTarget)
    }
  }

  return (
    <Box sx={styles.wrapper}>
      <Stack sx={styles.contentWrapper}>
        <Box sx={styles.content} onClick={handleMessageClick}>
          <Typography variant='p1' sx={styles.contentText}>{messageData.contentText}</Typography>
        </Box>
        <Typography variant='caption'>{ChatUtil.getChatFormatTime(messageData.createdAt)}</Typography>
      </Stack>
      <CheckFilled2Icon sx={styles.icon} />
      <MenuSelectDialog
        anchorEl={actionMenuAnchorEl}
        open={Boolean(actionMenuAnchorEl)}
        onClose={() => setActionMenuAnchorEl(null)}
        menuList={getMessageActionList(true)}
        onMenuItemSelected={handleActionMenuClick}
      />
    </Box>
  );
};

export default RightChatItem;
