import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LeaveIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M21.6426 24.6135L30.256 16.0001L21.6426 7.38672' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M30.2871 16L11.312 16' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
        <path d='M9.79871 3.24609H5.57617C3.36703 3.24609 1.57617 5.03695 1.57617 7.24609V24.7571C1.57617 26.9663 3.36703 28.7571 5.57617 28.7571H9.79871' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
      </svg>
    </SvgIcon>
  );
}

export default LeaveIcon;