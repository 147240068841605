import { Box, Fab, List, useMediaQuery, useTheme } from '@mui/material';
import { TAB_ALL, TAB_PRIORITY, TAB_UNREAD } from '../../../utils/constants';

import APP_NAV from '../../../routes/app-nav';
import AddIcon from '../../../components/CustomIcons/AddIcon';
import CircularProgressBar from '../../../components/CircularProgressBar';
import ConversationData from '../../../types/conversation-data';
import { FETCH_INTERACTION_HISTORY } from '../../../data/inbox/action-types';
import InboxListHeader from './InboxListHeader';
import InboxListItem from './InboxListItem';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import NoDataFound from '../../../components/NoDataFound';
import React from 'react';
import { Store } from '../../../store/store';
import Util from '../../../utils/util';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * InboxListScreen Component
 * 
 * This component renders the Inbox screen, including the list of conversations.
 */
const InboxListScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(TAB_ALL);
  const { state } = React.useContext(Store);
  const apiStatus = state.inbox.apiStatus;

  /**
   * getSortedAndFilteredList function
   * 
   * This function retrieves the conversation list, sorts it by last message time 
   * (or creation time if no last message), and applies any filtering based on the 
   * selected tab (all, priority, unread).
   * 
   * @returns {Array<ConversationData>} The sorted and filtered conversation list.
   */
  const getSortedAndFilteredList = (): Array<ConversationData> => {

    let conversationList: Array<ConversationData> = state.inbox.messageList || [];
    if (!Util.isArrayEmpty(conversationList)) {
      conversationList.sort((a, b) => {
        const dateA = a.lastContentTime ? new Date(a.lastContentTime) : new Date(a.createdAt);
        const dateB = b.lastContentTime ? new Date(b.lastContentTime) : new Date(b.createdAt);

        return dateB.getTime() - dateA.getTime();
      });

      if (selectedTab === TAB_PRIORITY) {
        conversationList = conversationList.filter(item => item.hasPriority);
      } else if (selectedTab === TAB_UNREAD) {
        conversationList = conversationList.filter(item => item.count > 0);
      }
    }

    return conversationList;
  }

  const conversationList: Array<ConversationData> = getSortedAndFilteredList();

  /**
   * onItemSelected function
   * 
   * This function handles item selection in the conversation list.
   * It navigates to the conversation detail screen, passing the conversation data as state.
   * 
   * @param {ConversationData} item The conversation item that was selected.
   */
  const onItemSelected = (item: ConversationData) => {
    const navPath = isDesktop ? `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`
      : APP_NAV.INBOX_DETAIL_REL_PATH.replace('/', '');
    navigate(navPath, {
      state: {
        ...item
      }
    });
  };

  /**
   * onAddButtonClick function
   * 
   * This function handles the click event on the add button. Navigate the user to direactory page.
   */
  const onAddButtonClick = () => {
    navigate(APP_NAV.DIRECTORY);
  }

  return (
    <Box sx={styles.wrapper}>
      <InboxListHeader
        title={t('inbox')}
        name={LoginUtil.getUserFullName()}
        selectedTab={selectedTab}
        handleTabChange={setSelectedTab}
      />
      <InlineAlert message={apiStatus?.task === FETCH_INTERACTION_HISTORY ? apiStatus?.error : undefined} />
      <Box sx={styles.innerWrapper}>
        {Util.isArrayEmpty(conversationList) ? (
          <NoDataFound message={t('noMessages')} />
        ) : (
          <List sx={styles.list}>
            {conversationList.map((item: ConversationData, index: number) =>
              <InboxListItem
                key={`inbox_list_item_${index}`}
                conversation={item}
                onItemSelected={onItemSelected}
              />
            )}
          </List>
        )
        }
      </Box>
      <Fab color='primary' sx={styles.fab} onClick={onAddButtonClick}>
        <AddIcon />
      </Fab>
      <CircularProgressBar show={apiStatus?.task === FETCH_INTERACTION_HISTORY && apiStatus?.isLoading} />
    </Box>
  );
};

export default InboxListScreen;