import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomToggleButtonGroup from '../../../components/CustomToggleButtonGroup';
import FilterIcon from '../../../components/CustomIcons/FilterIcon';
import InviteIcon from '../../../components/CustomIcons/InviteIcon';
import OptionData from '../../../types/option-data';
import SearchBar from '../../../components/SearchBar';
import SearchIcon from '../../../components/CustomIcons/SearchIcon';
import TopBar from '../../../components/TopBar';
import { USER_TAB_LIST } from '../../../utils/ui-constants';
import UserFilter from '../../../types/ui/user-filter';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

export interface UserSortFilterProps {
  selectedTab: string;
  filter: UserFilter;
  handleTabChange: (value: string) => void;
  onSearchKeyChange: (value: string) => void;
  onResetFilter: () => void;
  onApplyFilter: (values: UserFilter) => void;
  onInviteClick: () => void;
}

/**
 * Renders the header section for the user search and filter functionality.
 *
 * @param {UserSortFilterProps} props - Component props containing filter state, handlers, and user actions.
 * 
 * @returns {JSX.Element} JSX element representing the user search and filter header.
 */
const UserSearchAndFilterHeader: React.FC<UserSortFilterProps> = (props: UserSortFilterProps) => {

  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const formikRef = React.createRef<FormikProps<UserFilter>>();
  const [openFilterView, setOpenFilterView] = useState<boolean>(false);
  const userApi = useUserApi();

  const userTypeList: Array<OptionData> = userApi.state.userType.userTypeList.map(userType => ({
    id: userType.userType,
    name: userType.userType
  }));

  const userPositionList: Array<OptionData> = userApi.state.user.positionList.map(position => ({
    id: position,
    name: position
  }));

  /**
   * Handles form submission for the search bar, applying the current filter.
   *
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   */
  const onSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onApplyFilter({ ...props.filter });
  }

  /**
   * Handles resetting the filter form and applying the reset action.
   */
  const handleReset = () => {
    formikRef.current?.resetForm();
    setOpenFilterView(false);
    props.onResetFilter();
  }

  /**
   * Handles the click event on the "Apply Filter" button.
   *
   * @param {UserFilter} values - The updated filter values from the form.
   */
  const onApplyFilterClick = (values: UserFilter) => {
    setOpenFilterView(false);
    props.onApplyFilter(values);
  }

  return (
    <Box sx={styles.container}>
      <TopBar
        title={t('users')}
        subTitle={t('manageUsers')}
        actionText={t('invite')}
        actionIcon={<InviteIcon />}
        onActionClick={props.onInviteClick}
      />
      <Box sx={styles.tabSearchContainer}>
        <CustomToggleButtonGroup
          fullWidth={isMobile}
          value={props.selectedTab}
          data={USER_TAB_LIST}
          onChange={(_, value) => value && props.handleTabChange(value)}
        />
        <form onSubmit={onSearchSubmit}>
          <Box sx={styles.searchLayout}>
            <SearchBar
              sx={styles.searchInput}
              handleChange={props.onSearchKeyChange}
              placeholder={t('search')}
              endAdornment={
                <IconButton sx={styles.filterBtn} onClick={() => setOpenFilterView(!openFilterView)}>
                  <FilterIcon />
                </IconButton>
              }
            />
            <CustomButton type='submit' startIcon={<SearchIcon />} />
          </Box>
        </form>
      </Box>
      {openFilterView &&
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={props.filter}
          onSubmit={onApplyFilterClick}>
          {({ dirty }) => (
            <Form style={styles.form as React.CSSProperties}>
              <Box sx={styles.sortFilterContent}>
                <Box sx={styles.content}>
                  <Field
                    name='departments'
                    label={t('departments')}
                    placeholder={t('select')}
                    component={CustomAutocomplete}
                    noOptionsText={t('noDepts')}
                    multiple
                    menu={userApi.state.department.departmentList}
                    showDropdownIcon
                  />
                  <Box sx={styles.spacer} />
                  <Field
                    name='sites'
                    label={t('sites')}
                    placeholder={t('select')}
                    component={CustomAutocomplete}
                    multiple
                    noOptionsText={t('noSites')}
                    menu={userApi.state.site.siteList}
                    showDropdownIcon
                  />
                </Box>
                {isDesktop && <Box sx={styles.spacer} />}
                <Box sx={styles.content}>
                  <Field
                    name='userTypes'
                    label={t('userType')}
                    placeholder={t('select')}
                    component={CustomAutocomplete}
                    multiple
                    noOptionsText={t('noUserTypes')}
                    menu={userTypeList}
                    showDropdownIcon
                  />
                  <Box sx={styles.spacer} />
                  <Field
                    name='positions'
                    label={t('position')}
                    placeholder={t('search')}
                    component={CustomAutocomplete}
                    multiple
                    noOptionsText={t('noPositions')}
                    menu={userPositionList}
                    showDropdownIcon
                  />
                </Box>
              </Box>
              <Box sx={styles.applyBtn}>
                <Typography variant='caption' sx={styles.resetBtn} onClick={handleReset}>
                  {t('resetAll')}
                </Typography>
                <CustomButton type='submit' title={t('apply')} disabled={!dirty} />
              </Box>
            </Form>
          )}
        </Formik>
      }
    </Box>
  );
};

export default UserSearchAndFilterHeader;
