import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Generates MUI styles for elements used in the RegisterPasswordScreen component.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    icon: {
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    form: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  };

  return styles;
};

export default useStyles;