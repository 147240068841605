import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ForwardIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5818_2470)'>
          <path d='M11.4736 10.3666L15.207 6.63324L11.4736 2.8999' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15.207 6.6333H4.94036C3.95022 6.6333 3.00063 7.02663 2.3005 7.72677C1.60036 8.4269 1.20703 9.37649 1.20703 10.3666C1.20703 11.3568 1.60036 12.3064 2.3005 13.0065C3.00063 13.7066 3.95022 14.1 4.94036 14.1H5.8737' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_5818_2470'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.207031 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ForwardIcon;