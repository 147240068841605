import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the MenuSelectDialog component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	
	const styles = {
		destructive: {
      color: theme.palette.error.main
    },
    menuPaperProps: {
      borderRadius: Dimens.radius.md,
      backgroundImage: 'none',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`
    },
    menu: {
      padding: 0
    },
    menuItem: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      minWidth: Dimens.menuItemMinWidth,
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			'&:hover': {
				backgroundColor: theme.palette.background.default
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.paper,
				'&:hover': {
					backgroundColor: theme.palette.background.default
				}
			}
		},
		iconWrapper: {
      '&.MuiListItemIcon-root': {
        minWidth: '0 !important',
				mr: Dimens.spacing.base,
				color: 'inherit',
				'& .MuiSvgIcon-root': {
					fontSize: Dimens.icon.sm
				}
      }
    },
		divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		}
	};

	return styles;
};

export default useStyles;