import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MapPinIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.0158 3.23514C14.145 4.36425 14.7909 5.88827 14.8168 7.48496C14.8427 9.08164 14.2466 10.6258 13.1545 11.7909L13.0158 11.9345L10.116 14.8335C9.74809 15.2012 9.25415 15.4153 8.73425 15.4325C8.21436 15.4496 7.70739 15.2685 7.31604 14.9258L7.21763 14.8335L4.31717 11.9338C3.16366 10.7803 2.51563 9.21576 2.51562 7.58446C2.51563 5.95315 3.16366 4.38865 4.31717 3.23514C5.47068 2.08163 7.03518 1.43359 8.66649 1.43359C10.2978 1.43359 11.8623 2.08163 13.0158 3.23514ZM8.66649 5.53418C8.39724 5.53418 8.13063 5.58721 7.88188 5.69025C7.63313 5.79328 7.40711 5.94431 7.21672 6.13469C7.02634 6.32508 6.87532 6.5511 6.77228 6.79985C6.66924 7.0486 6.61621 7.31521 6.61621 7.58446C6.61621 7.8537 6.66924 8.12031 6.77228 8.36906C6.87532 8.61781 7.02634 8.84383 7.21672 9.03422C7.40711 9.2246 7.63313 9.37563 7.88188 9.47866C8.13063 9.5817 8.39724 9.63473 8.66649 9.63473C9.21025 9.63473 9.73175 9.41872 10.1163 9.03422C10.5008 8.64972 10.7168 8.12822 10.7168 7.58446C10.7168 7.04069 10.5008 6.51919 10.1163 6.13469C9.73175 5.75019 9.21025 5.53418 8.66649 5.53418Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default MapPinIcon;