/**
 * Action Types for Registration Process
 * 
 * This file defines action types used during the user registration process.
 * These action types are used to create Redux actions that signal different stages 
 * of registration.
 */

import {
  INIT_REGISTRATION,
  InitRegistrationAction,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
  RegistrationFailureAction,
  RegistrationSuccessAction
} from './action-types';

/**
 * initRegistration action creator
 * 
 * This function creates a Redux action to signal the initiation of the registration process.
 * 
 * @returns {InitRegistrationAction} - Redux action object with type `INIT_REGISTRATION`.
 */
export const initRegistration = (): InitRegistrationAction => ({
  type: INIT_REGISTRATION
});

/**
 * registrationSuccess action creator
 * 
 * This function creates a Redux action to signal successful user registration.
 * 
 * @returns {RegistrationSuccessAction} - Redux action object with type `REGISTRATION_SUCCESS`.
 */
export const registrationSuccess = (): RegistrationSuccessAction => ({
  type: REGISTRATION_SUCCESS
});

/**
 * registrationFailure action creator
 * 
 * This function creates a Redux action to signal a registration failure.
 * 
 * @param {string} error - Error message describing the registration failure.
 * 
 * @returns {RegistrationFailureAction} - Redux action object with type `REGISTRATION_FAILURE` 
 * and payload containing the error message.
 */
export const registrationFailure = (error: string): RegistrationFailureAction => ({
  type: REGISTRATION_FAILURE,
  payload: error
});