import { Dialog, DialogProps, IconButton } from '@mui/material';
import DirectorySortFilter, { DirectorySortFilterProps } from '../DirectorySortFilter';

import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import React, { } from 'react';
import useStyles from './styles';

interface Props extends DialogProps, DirectorySortFilterProps {
	onClose: () => void;
}

/**
 * DirectorySortFilterDialog component for displaying the directory sort and filter options.
 * 
 * This component renders a dialog containing the `DirectorySortFilter` component, allowing users
 * to apply filters to the directory data.
 * 
 * @props Props - Props passed to the component, including:
 *   - siteList: An array of sites.
 *   - deptList: An array of departments.
 *   - filter: The current filter object.
 *   - onResetFilter: Callback to reset the filter.
 *   - onApplyFilter: Callback to apply the filter.
 *   - onClose: Callback to close the dialog.
 *
 * @returns {JSX.Element} - The rendered DirectorySortFilterDialog component.
 */
const DirectorySortFilterDialog: React.FC<Props> = (props: Props) => {

	const {
		siteList,
		deptList,
		filter,
		onResetFilter,
		onApplyFilter,
		onClose,
		...rest
	} = props;
	const styles = useStyles();

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={onClose}>
			<IconButton onClick={onClose} sx={styles.closeIcon}>
					<CrossIcon />
				</IconButton>
			<DirectorySortFilter
				filter={filter}
				deptList={deptList}
				siteList={siteList}
				onResetFilter={onResetFilter}
				onApplyFilter={onApplyFilter}
			/>
		</Dialog>
	);
};

export default DirectorySortFilterDialog;