import {
	ADD_GROUP_MEMBER,
	API_FAILURE,
	AddGroupMemberAction,
	ApiFailureAction,
	CREATE_GROUP,
	CreateGroupAction,
	DirectoryStateResetAction,
	FETCH_GROUP_MEMBERS,
	FetchGroupMembersAction,
	REMOVE_GROUP_MEMBER,
	RESET_DIRECTORY_STATE,
	RESET_ERROR_STATE,
	RemoveGroupMemberAction,
	ResetErrorStateAction,
	SEARCH_DIRECTORY,
	SEARCH_DIRECTORY_SUCCESS,
	SearchDirectoryAction,
	SearchDirectorySuccessAction,
	UPDATE_GROUP,
	UPDATE_GROUP_ROLE,
	UpdateGroupAction,
	UpdateGroupRoleAction
} from './action-types';

import AppError from '../../exception/app-error';
import { DirectoryRecord } from '../../types/directory-record';

/**
 * Creates an action to reset the error state.
 * 
 * @returns {ResetErrorStateAction} The action to be dispatched.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Creates an action to initiate a search for directories.
 * 
 * @returns {SearchDirectoryAction} The action to be dispatched.
 */
export const initSearchDirectory = (): SearchDirectoryAction => ({
	type: SEARCH_DIRECTORY
});

/**
 * Creates an action to indicate successful search directory with retrieved site list.
 * 
 * @param {Array<DirectoryRecord>} siteList The list of directory records.
 * 
 * @returns {SearchDirectorySuccessAction} The action to be dispatched.
 */
export const searchDirectorySuccess = (siteList: Array<DirectoryRecord>): SearchDirectorySuccessAction => ({
	type: SEARCH_DIRECTORY_SUCCESS,
	payload: siteList
});

/**
 * Creates an action to initiate a group creation.
 * 
 * @returns {SearchDirectoryAction} The action to be dispatched.
 */
export const initCreateGroup = (): CreateGroupAction => ({
	type: CREATE_GROUP
});

/**
 * Action creator to initialize the group update process.
 *
 * @returns {UpdateGroupAction} An action object indicating the start of the group update.
 */
export const initUpdateGroup = (): UpdateGroupAction => ({
  type: UPDATE_GROUP,
});

/**
 * Action creator to initialize the process of adding a group member.
 *
 * @returns {AddGroupMemberAction} An action object indicating the start of adding a member.
 */
export const initAddGroupMember = (): AddGroupMemberAction => ({
  type: ADD_GROUP_MEMBER,
});

/**
 * Action creator to initialize the process of removing a group member.
 *
 * @returns {RemoveGroupMemberAction} An action object indicating the start of removing a member.
 */
export const initRemoveGroupMember = (): RemoveGroupMemberAction => ({
  type: REMOVE_GROUP_MEMBER,
});

/**
 * Action creator to initialize the process of updating a group member's role.
 *
 * @returns {UpdateGroupRoleAction} An action object indicating the start of updating a member's role.
 */
export const initGroupRoleUpdate = (): UpdateGroupRoleAction => ({
  type: UPDATE_GROUP_ROLE,
});

/**
 * Action creator to initialize the fetching of group members.
 *
 * @returns {FetchGroupMembersAction} An action object indicating the start of the fetch process.
 */
export const initGroupMembersFetch = (): FetchGroupMembersAction => ({
	type: FETCH_GROUP_MEMBERS
});

/**
 * Creates an action to handle API failure with an error object.
 * 
 * @param {AppError} error The error object.
 * 
 * @returns {ApiFailureAction} The action to be dispatched.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * Creates an action to reset the directory state.
 * 
 * @returns {DirectoryStateResetAction} The action to be dispatched.
 */
export const resetDirectoryState = (): DirectoryStateResetAction => ({
	type: RESET_DIRECTORY_STATE
});