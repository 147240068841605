import { Box, Grid, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import APIConfig from '../../service/api-config';
import APP_NAV from '../../routes/app-nav';
import AlertDialogLarge from '../../components/AlertDialogLarge';
import CircularProgressBar from '../../components/CircularProgressBar';
import { HttpStatusCode } from 'axios';
import LinkExpiredImageDark from '../../assets/sign_in_link_expired_dark.svg';
import LinkExpiredImageLight from '../../assets/sign_in_link_expired_light.svg';
import SignUpUtil from '../../utils/sign-up-util';
import ThemeUtil from '../../theme/theme-util';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * SplashScreen Component
 * 
 * This component handles the splash screen logic, including checking for 
 * email verification link expiration and displaying an alert if necessary.
 * It also attempts to manage email invites if provided in the URL parameters.
 */
const SplashScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const signInApi = useSignInApi();
  const [showLinkExpiredAlert, setShowLinkExpiredAlert] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  // Check for API error indicating link expiration and manage email invite
  useEffect(() => {

    if (signInApi.state.apiStatus?.error
      && signInApi.state.apiStatus.data?.code === HttpStatusCode.Conflict) {
      setLoading(false);
      setShowLinkExpiredAlert(true);
    } else {
      manageEmailInvite();
    }

  }, [signInApi.state.apiStatus?.error]);


  /**
   * Retrieves challenge path and token from URL parameters and attempts token verification if present.
   * Navigates to welcome page if no required parameters are found.
   */
  const manageEmailInvite = () => {
    const challengePath = searchParams.get('next');
    const token = searchParams.get('token');
    const signUpInfo = SignUpUtil.getBearerToken()
    if (challengePath && token && !signUpInfo) {
      signInApi.performTokenVerification(`${APIConfig.baseURL}/${challengePath}`, token);
    } else if (location.pathname === APP_NAV.SPLASH_SCREEN  && !signUpInfo) {
      // If somebody opens this page without required parameters, redirect him to welcome page.
      navigate(APP_NAV.WELCOME);
    }
  }

  /**
   * onResendLinkClicked function
   * 
   * This function is called when the "Resend Link" button is clicked in the 
   * alert dialog. (Functionality to be implemented)
   */
  const onResendLinkClicked = () => {
    // TODO:
  }

  /**
   * Handles closing the link expired dialog.
   * 
   * This function closes the link expired alert dialog and redirects the user to the sign-in page.
   */
  const onLinkExpiredDilogClose = () => {
    setShowLinkExpiredAlert(false);
    navigate(APP_NAV.SIGN_IN);
  }

  return (
    <Grid container sx={styles.wrapper}>
      <Box component={'img'} sx={styles.logo} />
      <CircularProgressBar show={isLoading} />
      <AlertDialogLarge
        open={showLinkExpiredAlert}
        title={t('verifyLinkExpired')}
        message={t('verifyLinkExpiredMsg')}
        titleIcon={isLightTheme ? LinkExpiredImageLight : LinkExpiredImageDark}
        primaryLabel={t('resendLink')}
        onPrimaryAction={onResendLinkClicked}
        onClose={onLinkExpiredDilogClose}
        footerCaption={t('stillProblem')}
        footerAction={t('emailSupport')}
      />
    </Grid>
  );
};

export default SplashScreen;
