import React, { useEffect, useRef, useState } from 'react';

import ArrowLeftIcon from '../../../components/CustomIcons/ArrowLeftIcon';
import { Box } from '@mui/material';
import { ChatRole } from '../../../utils/constants';
import { FETCH_GROUP_MEMBERS } from '../../../data/directory/action-types';
import { Group } from '../../../types/group';
import GroupMemberBaseInfo from '../../../types/group-member-base-info';
import GroupMemberCard from './GroupMemberCard';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import Util from '../../../utils/util';
import { useDirectoryApi } from '../../../data/directory/api';
import { useLoadMore } from '../../../hooks/use-load-more';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	group: Group;
	onClose: () => void;
}

/**
 * GroupMemberPanel component for displaying a list of group members.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GroupMemberPanel: React.FC<Props> = (props: Props) => {

	const { open, group, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [searchKey, setSearchKey] = useState<string>('');
	const directoryApi = useDirectoryApi();
	const apiStatus = directoryApi.state.directory.apiStatus;

	/**
	 * Fetches the group members on mount.
	 */
	useEffect(() => {
		if (group.groupRoomKey && !group.isCompleted) {
			directoryApi.fetchGroupMembers(group);
		}
	}, []);

	/**
	 * Retrieves and processes the list of group members for the current room.
	 *
	 * @returns {Array<GroupMemberBaseInfo>} An array of group member information objects.
	 */
	const getGroupMemberList = (): Array<GroupMemberBaseInfo> => {

		const record = directoryApi.state.directory.recordList
			.find(item => item.groupRoomKey === group.groupRoomKey);
		let filteredMemberList = record?.groupMembers || [];
		if (!Util.isArrayEmpty(filteredMemberList)) {
			filteredMemberList = filteredMemberList.filter(member =>
				member.name.toLowerCase().includes(searchKey.toLowerCase()));
			filteredMemberList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
		}

		return filteredMemberList;
	}

	const memberList = getGroupMemberList();

	return (
		<RightDrawerLayout
			open={open}
			hasBorder
			title={t('members')}
			leftIcon={<ArrowLeftIcon />}
			onActionClick={onClose}
			onClose={onClose}
		>
			<Box sx={styles.contentWrapper}>
				<SearchBar placeholder={t('search')} handleChange={setSearchKey} />
				<Box sx={styles.content}>
					{memberList.map((user, index) => (
						<GroupMemberCard
							key={`group-member-${index}`}
							name={user.name}
							position={user.loginId}
							isAdmin={user.chatRole === ChatRole.Admin}
						/>
					))}
				</Box>
			</Box>
		</RightDrawerLayout>
	);
};

export default GroupMemberPanel;