import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the Toast component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (severity = 'info') => {

  const theme = useTheme();
  
  const styles = {
    alert: {
      alignItems: 'center',
      color: severity === 'success' ? theme.palette.text.primary : theme.palette.primary.contrastText,
      p: `${Dimens.spacing.base} ${Dimens.spacing.md}`,
      '& .MuiAlert-icon': {
        p: 0,
        m: 0,
        'svg': {
          fontSize: Dimens.icon.md
        },
      },
      '& .MuiAlert-message': {
        p: 0,
        m: `0 ${Dimens.spacing.md}`
      },
      '& .MuiAlert-action': {
        p: 0,
        m: 0,
        'button': {
          p: 0,
          display: 'inline-flex !important'
        },
        'svg': {
          fontSize: Dimens.icon.sm
        },
      }
    }
  };

  return styles;
};

export default useStyles;
