import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserSquareIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2904_229)'>
          <path d='M16.0001 18.1232C17.2909 18.1232 18.5289 17.6104 19.4417 16.6976C20.3545 15.7848 20.8673 14.5468 20.8673 13.2559C20.8673 11.965 20.3545 10.727 19.4417 9.81426C18.5289 8.90147 17.2909 8.38867 16.0001 8.38867C14.7092 8.38867 13.4712 8.90147 12.5584 9.81426C11.6456 10.727 11.1328 11.965 11.1328 13.2559C11.1328 14.5468 11.6456 15.7848 12.5584 16.6976C13.4712 17.6104 14.7092 18.1232 16.0001 18.1232Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M16.0002 1.89844C27.6816 1.89844 30.6019 4.81879 30.6019 16.5002C30.6019 28.1816 27.6816 31.1019 16.0002 31.1019C4.31879 31.1019 1.39844 28.1816 1.39844 16.5002C1.39844 4.81879 4.31879 1.89844 16.0002 1.89844Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M6.26562 29.561V29.4799C6.26562 27.7587 6.94936 26.1081 8.1664 24.891C9.38345 23.674 11.0341 22.9902 12.7553 22.9902H19.245C20.9661 22.9902 22.6168 23.674 23.8339 24.891C25.0509 26.1081 25.7346 27.7587 25.7346 29.4799V29.561' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_2904_229'>
            <rect width='32' height='32' fill='transparent' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default UserSquareIcon;