import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import CrossIcon from '../CustomIcons/CrossIcon';
import CustomButton from '../CustomButton';
import Dimens from '../../theme/dimens';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining the properties accepted by the AlertDialogSmall component.
 * These properties allow for customization of the dialog's appearance and behavior.
 */
export interface Props extends DialogProps {
	title: string;
	titleIcon?: React.ReactNode;
	message: string;
	/**
	 * The primary button color. If set to true, then color will be red.
	 * @default false
	 */
	isDestructive?: boolean;
	/**
	 * Show 'Don't ask again' checkbox.
	 * @default false
	 */
	showCheckboxFooter?: boolean;
	primaryLabel?: string;
	secondaryLabel?: string;
	onPrimaryAction?: (isDontAskChecked: boolean) => void;
	onSecondaryAction?: (isDontAskChecked: boolean) => void;
	onClose: () => void;
}

/**
 * Functional component implementation for the AlertDialogSmall component.
 * It renders the dialog structure and handles user interactions like button clicks and closing the dialog.
 */
const AlertDialogSmall: React.FC<Props> = (props: Props) => {

	const {
		title,
		titleIcon,
		message,
		isDestructive,
		showCheckboxFooter,
		primaryLabel,
		secondaryLabel,
		onPrimaryAction,
		onSecondaryAction,
		onClose,
		...rest
	} = props;
	const styles = useStyles(props);
	const { t } = useTranslation();
	const [isDontAskChecked, setDontAskChecked] = useState<boolean>(false);

	return (
		<>
			{rest.open &&
				<Dialog {...rest} sx={styles.dialog}>
					<DialogTitle>
						<Stack sx={styles.header}>
							{titleIcon ? (
								<Box sx={styles.iconWrapper}>
									{titleIcon}
								</Box>
							) : (
								<Typography variant={'h5'} fontWeight={Dimens.fontWeight.bold}>
									{title}
								</Typography>
							)}
							<Box flex={1} />
							<IconButton onClick={onClose} >
								<CrossIcon sx={styles.closeIcon} />
							</IconButton>
						</Stack>
					</DialogTitle>
					<DialogContent>
						{titleIcon &&
							<Typography variant={'h5'} fontWeight={Dimens.fontWeight.bold}>
								{title}
							</Typography>
						}
						<Typography variant='p1' sx={styles.message}>
							{message}
						</Typography>
					</DialogContent>
					<DialogActions>
						<FormControlLabel
							control={<Checkbox onChange={(e, checked) => setDontAskChecked(checked)} />}
							label={<Typography variant='p1' sx={styles.footerCheckbox}>{t('dontAskAgain')}</Typography>}
						/>
						<Stack sx={styles.btnLayout}>
							<CustomButton
								title={secondaryLabel}
								color='secondary'
								// eslint-disable-next-line
								onClick={() => onSecondaryAction!(isDontAskChecked)}
							/>
							<CustomButton
								title={primaryLabel}
								color='primary'
								destructive={isDestructive}
								// eslint-disable-next-line
								onClick={() => onPrimaryAction!(isDontAskChecked)}
							/>
						</Stack>
					</DialogActions>
				</Dialog>
			}
		</>
	);
};

export default AlertDialogSmall;
