import { Box, Tab } from '@mui/material';
import { TAB_GROUP_DETAIL, TAB_GROUP_MEMBER } from '../../../utils/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import DetailsTab from './DetailsTab';
import { GROUP_TAB_LIST } from '../../../utils/ui-constants';
import { Group } from '../../../types/group';
import MembersTab from './MembersTab';
import React from 'react';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	group: Group;
	onClose: () => void;
}

/**
 * GroupEditPanel component for managing group details (name, description, and members).
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GroupEditPanel: React.FC<Props> = (props: Props) => {

	const { open, group, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = React.useState(TAB_GROUP_DETAIL);

	/**
   * Renders the appropriate TabPanel content based on the selected tab ID.
   *
   * @param {string} tabId - ID of the selected tab.
	 * 
   * @returns {JSX.Element} The content for the TabPanel.
   */
	const getTabPanel = (tabId: string) => {
		switch (tabId) {
			case TAB_GROUP_DETAIL:
				return <DetailsTab group={group} />;
			case TAB_GROUP_MEMBER:
				return <MembersTab group={group} />;
		}
	}

	return (
		<RightDrawerLayout open={open} title={t('editGroup')} onClose={onClose}>
			{open && group &&
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
						{
							GROUP_TAB_LIST.map((item, index) => (
								<Tab key={`org_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							GROUP_TAB_LIST.map((item, index) => (
								<TabPanel key={`org_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							))
						}
					</Box>
				</TabContext>
			}
		</RightDrawerLayout>
	);
};

export default GroupEditPanel;