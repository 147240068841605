import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

const useStyles = () => {

  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    message: {
      color: theme.palette.text.secondary
    }
  };

  return styles;
};

export default useStyles;
