import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook that generates styles for the CustomToggleButtonGroup component.
 * 
 * @returns {Object} An object containing styles for the toggle button component.
 */
const useStyles = () => {

  const theme = useTheme();
  
  const styles = {
    toggleButton: {
      textTransform: 'none',
      bgcolor: theme.palette.background.default,
      m: 0,
      border: 0,
      p: `${Dimens.spacing.sm} ${Dimens.spacing.base}`,
      color: theme.palette.text.primary,
      '&.MuiToggleButtonGroup-firstButton': {
        borderTopLeftRadius: Dimens.radius.md,
        borderBottomLeftRadius: Dimens.radius.md
      },
      '&.MuiToggleButtonGroup-middleButton': {
        ml: 0,
        borderLeft: 0
      },
      '&.MuiToggleButtonGroup-lastButton': {
        ml: 0,
        borderLeft: 0,
        borderTopRightRadius: Dimens.radius.md,
        borderBottomRightRadius: Dimens.radius.md
      },
      '&.Mui-selected': {
        bgcolor: theme.palette.secondary.dark
      },
      '&:hover': {
        bgcolor: theme.palette.secondary.dark
      }
    }
  };

  return styles;
};

export default useStyles;
