import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import Department from '../../../../types/department';
import InlineAlert from '../../../../components/InlineAlert';
import { UPDATE_DEPT } from '../../../../data/department/action-types';
import Util from '../../../../utils/util';
import { deptSchema } from '../../../../utils/validation-schema';
import { useDepartmentApi } from '../../../../data/department/api';
import { useSiteApi } from '../../../../data/site/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  department: Department;
}

/**
 * Renders a form for editing department details.
 *
 * @param {Props} props - Component props containing the department data.
 * 
 * @returns JSX element representing the department details tab.
 */
const DetailsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [department, setDepartment] = useState<Department>(props.department);
  const siteApi = useSiteApi();
  const departmentApi = useDepartmentApi();
  const apiStatus = departmentApi.state.department.apiStatus;

  /**
   * Handles form submission, updating the department details and refreshing the list.
   *
   * @param {Department} values - The updated department data from the form.
   */
  const onSubmit = (values: Department) => {
    departmentApi.updateDepartment(values).then((data) => {
      if (data) {
        setDepartment(values);
      }
    });
  }

  return (
    <Grid sx={styles.wrapper}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={deptSchema}
        initialValues={department}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box sx={styles.formInnerWrapper}>
              <InlineAlert message={Util.getApiError([UPDATE_DEPT], apiStatus)} />
              <Field
                name='name'
                label={t('name')}
                placeholder={t('name')}
                component={CustomInput}
              />
              <Field
                name='description'
                label={t('description')}
                placeholder={t('deptDescPlaceholder')}
                component={CustomInput}
                multiline
              />
              <Field
                name='sites'
                label={t('sites')}
                placeholder={t('findSites')}
                component={CustomAutocomplete}
                noOptionsText={t('noSites')}
                multiple
                menu={siteApi.state.site.siteList}
              />
            </Box>
            <CustomButton
              type='submit'
              title={t('save')}
              color='primary'
              disabled={!dirty || !isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <CircularProgressBar show={Util.isApiLoading([UPDATE_DEPT], apiStatus)} />
    </Grid>
  );
};

export default DetailsTab;