import Dimens from '../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for the parent drawer component.
 * It utilizes the theme object from Material UI and theme utility functions.
 * 
 * @returns {object} - An object containing styles for the drawer and its elements.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    drawer: {
      '&.MuiDrawer-root': {
        width: Dimens.drawer.parentWidth,
        [theme.breakpoints.down('sm')]: {
          flex: 1
        }
      },
      '.MuiDrawer-paper': {
        border: 'none',
        height: '100%',
        p: Dimens.spacing.md,
        width: Dimens.drawer.parentWidth,
        position: 'relative',
        justifyContent: 'space-between',
        bgcolor: theme.palette.background.default,
        'svg': {
          fontSize: Dimens.icon.lg
        },
        [theme.breakpoints.down('sm')]: {
          bgcolor: theme.palette.background.paper,
          p: Dimens.spacing.base,
          width: '100%'
        }
      }
    },
    list: {
      p: 0,
      [theme.breakpoints.down('sm')]: {
				flexDirection: 'row',
        display: 'flex',
        flex: 1
			}
    },
    divider: {
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.text.secondary,
      mb: Dimens.spacing.md
    },
    footer: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };

  return styles;
};

export default useStyles;
