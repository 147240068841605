import APP_NAV from '../routes/app-nav';
import Department from '../types/department';
import MenuData from '../types/ui/menu-data';
import Site from '../types/site';
import TabData from '../types/ui/tab-data';
import { t } from 'i18next';

/**
 * constants.ts
 * 
 * This file is used to define general constants that do not include JSX components.
 * If the constants has any JSX dependency, define that in ui-constants.ts.
 * It helps in centralizing and organizing constants used throughout the application.
 */

// General App Constants
export const MAX_SINGLE_LINE_CHAR_COUNT = 64;
export const MAX_MULTI_LINE_CHAR_COUNT = 400;
export const MULTI_LINE_MAX_ROWS = 4;
export const SEARCH_DEBOUNCE_DELAY = 500;
export const CARD_MAX_WIDTH = 400;
export const TOAST_DURATION = 5000; // 5 seconds
export const DEFAULT_PAGE_SIZE = 100;
export const DIRECTORY_CARD_MAX_WIDTH = 185;

// Login Storage keys
export const KEY_APP_CLIENT_ID = 'app_client_id';
export const KEY_APP_LOGIN_STATUS = 'app_login_status';
export const KEY_SESSION_INFO = 'session_info';
export const KEY_APP_USER_INFO = 'app_user_info';
export const KEY_LAST_ACTIVE_TIME = 'last_active_time';
export const KEY_ORG_DOMAIN = 'org_domain';

// App Storage keys
export const KEY_APP_LANGUAGE = 'app_language';

// Encryption and decryption credentials
export const SIGN_UP_STORAGE_IV = 'xB%{W0gKe#8KDN$3tD#WCgi;!Ry0xMwB';
export const SIGN_UP_STORAGE_SECRET = 'ZTSz7SK0dmN639!d';
export const LOGIN_STORAGE_IV = 'xB%{W0gKe#8KDN$3tD#WCgi;!Ry0xMwB';
export const LOGIN_STORAGE_SECRET = 'ZTSz7SK0dmN639!d';
export const APP_STORAGE_IV = 'xB%{W0gKe#8KDN$3tD#WCgi;!Ry0xMwB';
export const APP_STORAGE_SECRET = 'ZTSz7SK0dmN639!d';
export const PASSWORD_IV = 'FGP0n7nqx17mtV3R';
export const PASSWORD_SECRET = 'p6p4V57hbCmA10iAvlXAP9W2rg7RInyB';

// Session Handling Constants
export const SESSION_TIME_OUT = 20 * 60 * 1000; // 20 Minutes
export const SESSION_DEBOUNCE_TIME = 500;
export const REFRESH_TOKEN_DEBOUNCE_TIME = 15000; // 15 Seconds

export const PLATFORM = 'WEB';

// Welcome Page Constants
export const CAROUSEL_DEFAULT_INTERVAL = 500000; // 5 seconds

// Sign up constants
export const SIGN_UP_PAGE_LIST = [
  APP_NAV.SPLASH_SCREEN,
  APP_NAV.SIGN_UP_PASSWORD,
  APP_NAV.SIGN_UP_DEPARTMENT,
  APP_NAV.SIGN_UP_NEW_DEPARTMENT,
  APP_NAV.SIGN_UP_PROFILE
];

export const enum SignUpChallengeType {
  SetPassword = 'SET_PASSWORD',
  SetDepartment = 'SET_DEPARTMENT',
  SetProfile = 'SET_PROFILE'
}

// Sign In Page constants
export const OTP_LENGTH = 6;
export const PWD_MIN_LENGTH = 10;
export const SIGN_IN_INVITE = 'LINK';
export const SIGN_IN_PASSWORD = 'PASSWORD';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const enum ControlType {
  MainView = 'MAIN_VIEW',
  Subscription = 'SUBSCRIPTION',
  SetPassword = 'SET_PASSWORD',
  SetDepartments = 'SET_DEPARTMENT',
  SetProfile = 'SET_PROFILE',
  PasswordReset = 'PASSWORD_RESET'
}

// Regex Patterns
export const REGEX_PATTERN_LOWER_CASE = /[a-z]/;
export const REGEX_PATTERN_UPPER_CASE = /[A-Z]/;
export const REGEX_PATTERN_NUMBER = /\d/;
export const REGEX_PATTERN_PWD_SPECIAL_CHAR = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
export const REGEX_PATTERN_NO_NUMBERS = /^[^\d]+$/;
export const REGEX_PATTERN_PHONE_NUMBER = /^\+?[0-9\- ]+$/;
export const REGEX_PATTERN_EMAIL = /\S+@\S+\.\S+/;

// Date Patterns
export const DATE_PATTERN_DD_MM_YYYY = 'dd/MM/yyyy';
export const DATE_PATTERN_DD_MMM_YYYY = 'dd MMM yyyy';
export const DATE_PATTERN_DD_MMM = 'dd MMM';
export const TIME_PATTERN_HH_MM_AAA = 'hh:mmaaa';
export const DATE_PATTERN_MMM_YYYY = 'MMM yyyy';
export const DATE_PATTERN_MMMM_YYYY = 'MMMM yyyy';
export const DATE_PATTERN_DD_MM_YY = 'dd/MM/yy';
export const DATE_PATTERN_HH_MM = 'HH:mm';

//ThemeMode maps to the MUI PaletteMode
export const enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}
// Language preferences supported by the application.
export const APP_DEFAULT_LANGUAGE = 'en';
export const languageList = [
  {
    id: 'en',
    value: 'English'
  },
  {
    id: 'fr',
    value: 'French'
  },
  {
    id: 'de',
    value: 'German'
  },
  {
    id: 'es',
    value: 'Spanish'
  }
];

export const DRAWER_HIDDEN_PATH_LIST = [
  `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`
];

// Inbox constants
export const TAB_ALL = '1';
export const TAB_PRIORITY = '2';
export const TAB_UNREAD = '3';
export const TAB_STARRED = '4';
// This is written as function in order to address the translation.
// Otherwise translation will not work;
export const getInboxTabs = (): Array<TabData> => ([
  {
    id: TAB_ALL,
    label: t('all')
  },
  {
    id: TAB_PRIORITY,
    label: t('priority')
  },
  {
    id: TAB_UNREAD,
    label: t('unread')
  },
  // {
  //   id: TAB_STARRED,
  //   label: t('starred')
  // }
]);

export const enum ConversationSubMenu {
  Archive = 'ARCHIVE',
  Star = 'STAR',
  Mute = 'MUTE',
  MarkAsRead = 'MARK_AS_READ'
}

export const enum InteractionType {
  Group = 'GROUP',
  Private = 'PRIVATE'
}

export const enum SessionType {
  Actual = 'ACTUAL',
  Assigned = 'ASSIGNED'
}

export const enum ActualSessionStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  NotDefined = 'NOT_DEFINED'
}

export const enum AssignedSessionStatus {
  Available = 'AVAILABLE',
  Away = 'AWAY',
  DoNotDisturb = 'DO_NOT_DISTURB',
  NotDefined = 'NOT_DEFINED'
}

export const enum UserProfileStatus {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Deactivated = 'DEACTIVATED'
}

export const enum MessagePriority {
  Urgent = 'URGENT',
  Normal = 'NORMAL'
}

export const enum ContentType {
  ContentText = 'CONTENT_TEXT',
  ForwardText = 'FORWARD_TEXT',
  ReplyText = 'REPLY_TEXT'
}

export const enum AttachmentType {
  Camera = 'CAMERA',
  Photo = 'PHOTO',
  Video = 'VIDEO',
  Document = 'DOCUMENT'
}
// Action types received along with subscription messages for chat
export const enum MessageActionType {
  CreatedGroupRoom = 'CREATED_GROUP_ROOM',
  UpdatedGroupRoom = 'UPDATED_GROUP_ROOM',
  AddedMember = 'ADDED_MEMBER',
  RemovedMember = 'REMOVED_MEMBER',
  MuteMember = 'MUTE_MEMBER',
  UnMuteMember = 'UNMUTE_MEMBER',
  SetRoleMember = 'SET_ROLE_MEMBER',
  ContentText = 'CONTENT_TEXT',
  MarkedMessage = 'MARKED_MESSAGE',
  UpdatedSessionAction = 'UPDATED_SESSION_STATUS'
}

export const CHAT_PAGE_SIZE = 100; // No. of items in each page for pagination.

export enum SortByAlphabet {
  A_Z = 'A_Z',
  Z_A = 'Z_A'
}

// TODO: Check how to apply localization here.
export const SORT_BY_ALPHABET_MENU: Array<MenuData> = [
  {
    id: SortByAlphabet.A_Z,
    value: 'Name (A - Z)'
  },
  {
    id: SortByAlphabet.Z_A,
    value: 'Name (Z - A)'
  }
];

// Site Constants
export const siteInitialValue: Site = {
  id: '',
  name: '',
  address: '',
  phoneNumber: '',
  departments: []
};

export const enum DirectoryRecordType {
  User = 'USER',
  Group = 'GROUP'
}

export const enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export const enum ActionType {
  BackAction = 'BACK_ACTION',
  PrimaryAction = 'PRIMARY_ACTION'
}

export const enum ChatRole {
  Admin = 'ADMIN',
  User = 'USER'
}

// Edit Group
export const TAB_GROUP_DETAIL = '1';
export const TAB_GROUP_MEMBER = '2';
export const TAB_GROUP_SETTING = '3';

export const TAB_GROUP_MEMBER_ALL = '1';
export const TAB_GROUP_MEMBER_ADMIN = '2';

export const enum AppFeature {
  ManageUser = 'admin.user.mur',
  InviteUser = 'admin.invite.inv',
  ManageDepartment = 'admin.department.dpt',
  ManageSystemSetting = 'admin.settings.stg',
  ManageGroup = 'messaging.group.mgp',
  MultimediaForwarding = 'messaging.multimedia.mtm',
  ManageIdleTime = 'system.idle.idl'
}

// Edit User
export const TAB_USER_EDIT_PROFILE = '1';
export const TAB_USER_EDIT_SETTINGS = '2';

export const enum MessageOptions {
  Info = 'INFO',
  Forward = 'FORWARD',
  Reply = 'REPLY'
}

// Department constants
export const deptInitialValue: Department = {
  id: '',
  name: '',
  description: '',
  sites: [],
  members: []
};

export const enum DepartmentSubMenuItem {
  Edit = 'EDIT',
  Delete = 'DELETE'
}

export const TAB_DEPT_EDIT_DETAIL = '1';
export const TAB_DEPT_EDIT_MEMBER = '2';
export const TAB_DEPT_EDIT_SETTINGS = '3';

// Admin User constants
export const TAB_USER_ALL = '1';
export const TAB_USER_ACTIVE = '2';
export const TAB_USER_PENDING = '3';
export const TAB_USER_DEACTIVATED = '4';

export const enum UserTableHeader {
  UserType = 'USER_TYPE',
  Department = 'DEPARTMENT',
  Position = 'POSITION',
  Invited = 'INVITED'
}

export const enum UserListSubMenu {
  Edit = 'EDIT',
  Unlock = 'UNLOCK',
  Deactivate = 'DEACTIVATE',
  Reactivate = 'REACTIVATE',
  ResendInvite = 'RESEND_INVITE',
  Delete = 'DELETE'
}

export const enum MessageSendStatus {
  InProgress = 'IN_PROGRESS',
  Sent = 'SENT',
  Failed = 'FALIED'
}

export const enum GroupType {
  Normal = 'NORMAL_GROUP',
  Mixed = 'MIXED_GROUP'
}

export const enum EntityType {
  User = 'USER'
}
