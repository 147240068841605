import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LockedIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6909_19247)'>
          <path d='M5.9894 5V6.5H11.3465V5C11.3465 3.61875 10.1479 2.5 8.66797 2.5C7.18806 2.5 5.9894 3.61875 5.9894 5ZM3.84654 6.5V5C3.84654 2.51562 6.00614 0.5 8.66797 0.5C11.3298 0.5 13.4894 2.51562 13.4894 5V6.5H14.0251C15.207 6.5 16.168 7.39687 16.168 8.5V14.5C16.168 15.6031 15.207 16.5 14.0251 16.5H3.31083C2.12891 16.5 1.16797 15.6031 1.16797 14.5V8.5C1.16797 7.39687 2.12891 6.5 3.31083 6.5H3.84654Z' fill='currentColor' />
        </g>
        <defs>
          <clipPath id='clip0_6909_19247'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default LockedIcon;