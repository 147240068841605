import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SiteMapIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1 12.5013C1 12.1477 1.14048 11.8085 1.39052 11.5585C1.64057 11.3084 1.97971 11.168 2.33333 11.168H3.66667C4.02029 11.168 4.35943 11.3084 4.60948 11.5585C4.85952 11.8085 5 12.1477 5 12.5013V13.8346C5 14.1883 4.85952 14.5274 4.60948 14.7774C4.35943 15.0275 4.02029 15.168 3.66667 15.168H2.33333C1.97971 15.168 1.64057 15.0275 1.39052 14.7774C1.14048 14.5274 1 14.1883 1 13.8346V12.5013Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7 3.16536C7 2.81174 7.14048 2.4726 7.39052 2.22256C7.64057 1.97251 7.97971 1.83203 8.33333 1.83203H9.66667C10.0203 1.83203 10.3594 1.97251 10.6095 2.22256C10.8595 2.4726 11 2.81174 11 3.16536V4.4987C11 4.85232 10.8595 5.19146 10.6095 5.44151C10.3594 5.69156 10.0203 5.83203 9.66667 5.83203H8.33333C7.97971 5.83203 7.64057 5.69156 7.39052 5.44151C7.14048 5.19146 7 4.85232 7 4.4987V3.16536Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13 12.5013C13 12.1477 13.1405 11.8085 13.3905 11.5585C13.6406 11.3084 13.9797 11.168 14.3333 11.168H15.6667C16.0203 11.168 16.3594 11.3084 16.6095 11.5585C16.8595 11.8085 17 12.1477 17 12.5013V13.8346C17 14.1883 16.8595 14.5274 16.6095 14.7774C16.3594 15.0275 16.0203 15.168 15.6667 15.168H14.3333C13.9797 15.168 13.6406 15.0275 13.3905 14.7774C13.1405 14.5274 13 14.1883 13 13.8346V12.5013Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7 12.5013C7 12.1477 7.14048 11.8085 7.39052 11.5585C7.64057 11.3084 7.97971 11.168 8.33333 11.168H9.66667C10.0203 11.168 10.3594 11.3084 10.6095 11.5585C10.8595 11.8085 11 12.1477 11 12.5013V13.8346C11 14.1883 10.8595 14.5274 10.6095 14.7774C10.3594 15.0275 10.0203 15.168 9.66667 15.168H8.33333C7.97971 15.168 7.64057 15.0275 7.39052 14.7774C7.14048 14.5274 7 14.1883 7 13.8346V12.5013Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.97852 10.8427V10.176C2.97852 9.82237 3.16923 9.28733 3.57247 8.92915C4.0091 8.54131 4.46755 8.5 5.00501 8.5H13.111C13.6485 8.5 14.1007 8.5444 14.5367 8.92915C14.9419 9.28682 15.1375 9.82237 15.1375 10.176C15.1375 10.176 15.1375 10.5823 15.1375 10.8427' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9 6.5L9 10.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default SiteMapIcon;