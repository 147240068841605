import {
  Stack,
  Typography,
} from '@mui/material';

import Dimens from '../../theme/dimens';
import React, { } from 'react';
import useStyles from './styles';

interface Props {
  label?: string;
  subLabel?: string;
  isReadOnly?: boolean;
  noPad?: boolean;
}

/**
 * CustomLabel component for displaying a label and optional sublabel.
 *
 * @param {Object} props Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CustomLabel: React.FC<Props> = (props: Props) => {

  const { label, subLabel, isReadOnly, noPad } = props;
  const styles = useStyles();

  return (
    <>
      {label &&
        <Stack sx={{ ...styles.header, ...(noPad && styles.noPad) }}>
          <Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>{label}</Typography>
          {subLabel && !isReadOnly &&
            <Typography variant='p1' sx={styles.subLabel}>{subLabel}</Typography>
          }
        </Stack>
      }
    </>
  );
};

export default CustomLabel;
