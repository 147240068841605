import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DepartmentViewPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = (memberCount = 0) => {

  const { xxs, base, lg, xl, section, box } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'hidden'
    },
    content: {
      ...commonStyles.noScrollBar,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto'
    },
    deptHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    avatarWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      width: '100%',
      background: `linear-gradient(to bottom, ${theme.palette.background.default} 50%, transparent 50%)`,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: `${xl} ${lg}`
      }
    },
		iconWrapper: {
			width: Dimens.avatarSize.section,
			height: Dimens.avatarSize.section,
			border: `${Dimens.menuBorderWidth} dashed ${theme.palette.text.primary}`,
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.text.primary,
      bgcolor: theme.palette.background.paper,
      '& svg': {
        fontSize: Dimens.icon.xl
      }
		},
    avatarGroup: {
      cursor: 'pointer',
      '& .MuiAvatar-root': {
        ...theme.typography.footer,
        width: Dimens.avatarSize.md,
        height: Dimens.avatarSize.md,
        border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.paper}`,
        bgcolor: theme.palette.text.secondary
      },
      '& >.MuiAvatar-root:first-of-type': {
        bgcolor: memberCount > 3 ? theme.palette.primary.light : theme.palette.text.secondary
      }
    },
    deptContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: `0 ${section} ${section} ${section}`,
      [theme.breakpoints.down('sm')]: {
        p: `0 ${lg} ${box} ${lg}`
      }
    },
    title: {
      fontWeight: Dimens.fontWeight.bold,
      textAlign: 'center',
      pb: xxs
    },
    deptInfo: {
      p: `${base} ${section} ${section} ${section}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    }
  };

  return styles;
};

export default useStyles;
