import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NormalPriorityIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8.16526 6.11167V3.25687C8.16529 3.09944 8.212 2.94555 8.29948 2.81467C8.38696 2.68378 8.51128 2.58177 8.65672 2.52153C8.80217 2.46129 8.96221 2.44552 9.11662 2.47623C9.27102 2.50693 9.41286 2.58272 9.52419 2.69402L14.7673 7.93712C14.9165 8.08641 15.0004 8.28887 15.0004 8.49996C15.0004 8.71106 14.9165 8.91351 14.7673 9.0628L9.52419 14.3059C9.41286 14.4172 9.27102 14.493 9.11662 14.5237C8.96221 14.5544 8.80217 14.5386 8.65672 14.4784C8.51128 14.4182 8.38696 14.3161 8.29948 14.1853C8.212 14.0544 8.16529 13.9005 8.16526 13.7431V10.8883H3.38867V6.11167H8.16526Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1 6.11133V10.8879' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default NormalPriorityIcon;