import Dimens from '../../../../theme/dimens';

/**
 * useStyles hook
 * 
 * This hook creates a styles object containing styling information for the 
 * `ChatItem` component.
 * 
 * @returns {object} An object containing styles for the `ChatItem` component.
 */
const useStyles = () => {

  const styles = {
    dateHeader: {
      fontWeight: Dimens.fontWeight.medium,
      display: 'flex',
      justifyContent: 'center',
      mb: Dimens.spacing.lg
    }
  };

  return styles;
};

export default useStyles;