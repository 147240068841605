import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the SettingsTab component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xs, base, lg, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      p: `${section} ${section} 0 ${section}`,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        p: `${lg} ${lg} 0 ${lg}`
      }
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto',
      '& .MuiButtonBase-root': {
        bgcolor: 'transparent',
        borderRadius: 0,
        alignItems: 'left',
        justifyContent: 'left'
      }
    }
  };

  return styles;
};

export default useStyles;