import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FileIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3690_9791)'>
          <path d='M9.55469 1.5V4.61111C9.55469 4.81739 9.63663 5.01522 9.78249 5.16108C9.92836 5.30694 10.1262 5.38889 10.3325 5.38889H13.4436' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M11.888 15.5H4.11024C3.69768 15.5 3.30202 15.3361 3.0103 15.0444C2.71858 14.7527 2.55469 14.357 2.55469 13.9444V3.05556C2.55469 2.643 2.71858 2.24733 3.0103 1.95561C3.30202 1.66389 3.69768 1.5 4.11024 1.5H9.55469L13.4436 5.38889V13.9444C13.4436 14.357 13.2797 14.7527 12.988 15.0444C12.6962 15.3361 12.3006 15.5 11.888 15.5Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3690_9791'>
            <rect width='16' height='16' fill='transparent' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default FileIcon;