import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook
 *
 * Generates MUI theme-dependent styles for the SignInLayout component.
 * 
 * @returns An object containing the styled classes:
 *  - contentWrapper: Styles for the content wrapper container.
 *  - header: Styles for the header typography.
 */
const useStyles = () => {

  const { xs, container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    contentWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '100%',
      [theme.breakpoints.between('sm', 'md')]: {
        minHeight: Dimens.signInLayout.mdHeight,
        minWidth: Dimens.signInLayout.mdWidth,
        bgcolor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        p: container,
        borderRadius: Dimens.radius.xl
      }
    },
    header: {
      fontWeight: Dimens.fontWeight.bold,
      pb: xs
    }
  };

  return styles;
};

export default useStyles;