import {
	API_FAILURE,
	ApiFailureAction,
	FETCH_USER_TYPE_LIST,
	FetchUserTypeListAction,
	USER_TYPE_LIST_SUCCESS,
	UserTypeListSuccessAction,
} from './action-types';

import AppError from '../../exception/app-error';
import UserType from '../../types/user-type';

/**
 * Action creator to fetch the user type list.
 *
 * @returns {FetchUserTypeListAction} - The action to fetch the user type list.
 */
export const initUserTypeListFetch = (): FetchUserTypeListAction => ({
	type: FETCH_USER_TYPE_LIST
});

/**
 * Action creator to indicate successful user type list fetching.
 *
 * @param {Array<UserType>} userTypeList - The fetched userType list.
 * @returns {UserTypeListSuccessAction} - The action to indicate successful fetching with the user type list as payload.
 */
export const userTypeListSuccess = (userTypeList: Array<UserType>): UserTypeListSuccessAction => ({
	type: USER_TYPE_LIST_SUCCESS,
	payload: userTypeList,
});

/**
 * Action creator to handle API failure.
 *
 * @param {AppError} error - The API error object.
 * @returns {ApiFailureAction} - The action to indicate API failure with the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error,
});

