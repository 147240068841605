import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SignInLayout component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    contentWrapper: {
      position: 'relative',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      maxWidth: Dimens.signInLayout.lgWidth,
      minWidth: Dimens.signInLayout.minWidth,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        minHeight: Dimens.signInLayout.mdHeight,
        maxWidth: Dimens.signInLayout.mdWidth,
        minWidth: Dimens.signInLayout.minWidth,
        bgcolor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        p: container,
        borderRadius: Dimens.radius.xl
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '100%',
        maxWidth: Dimens.signInLayout.smWidth,
        minWidth: Dimens.signInLayout.minWidth,
        justifyContent: 'space-between',
        p: 0
      }
    },
    content: {
      [theme.breakpoints.up('sm')]: {
        flex: 1,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
      },
      [theme.breakpoints.down('sm')]: {
        'button': {
          display: 'none'
        },
        '& .MuiInputAdornment-root': {
          'button': {
            display: 'flex'
          },
        }
      }
    }
  };

  return styles;
};

export default useStyles;