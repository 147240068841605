import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Creates styles for the user card component.
 *
 * @returns {object} An object containing styles for the content, avatar, and content text elements.
 */
const useStyles = () => {

  const { lg } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    content: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center'
    },
		iconWrapper: {
			width: Dimens.avatarSize.lg,
			height: Dimens.avatarSize.lg,
			border: `${Dimens.menuBorderWidth} dashed ${theme.palette.text.secondary}`,
			borderRadius: Dimens.radius.xxl,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.text.secondary,
      '& svg': {
        ...commonStyles.smIcon
      }
		},
    avatar: {
      width: Dimens.avatarSize.lg,
      height: Dimens.avatarSize.lg,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
    contentText: {
      flexDirection: 'column',
      display: 'flex',
      px: lg
    }
  };

  return styles;
};

export default useStyles;
