import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the Carousel component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	
	const styles = {
		slideAnimate: {
			transition: 'transform 0.5s ease-in-out',
		},
		indicatorWrapper: {
			flexDirection: 'row',
			my: Dimens.spacing.box,
			justifyContent: 'center',
			[theme.breakpoints.up('md')]: {
        position: 'absolute',
				zIndex: 2,
				bottom: 0,
				left: '47%',
      }
		},
		indicator: {
			minWidth: 0,
			minHeight: 0,
			height: Dimens.indicatorSize,
			width: Dimens.indicatorSize,
			borderRadius: Dimens.radius.lg,
			boxShadow: 'none',
			mx: Dimens.spacing.sm,
			p: '0',
			'&.MuiButton-colorSecondary': {
        backgroundColor: theme.palette.text.secondary
      }
		}
	};

	return styles;
};

export default useStyles;