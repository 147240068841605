import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DetailsTab component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const {lg, section} = Dimens.spacing;
  const theme = useTheme();
  
  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }
  };

  return styles;
};

export default useStyles;