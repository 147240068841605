import Dimens from '../../../theme/dimens';
import { Props } from '.';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the RightDrawerHeader component.
 *
 * @param {Props} props - Component props containing styling options.
 * 
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = (props: Props) => {

  const { xs, lg, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    headerLayout: {
      py: section,
      pl: props.leftIcon ? lg : section,
      pr: lg,
      alignItems: 'center',
      justifyContent: (props.title || props.leftIcon) ? 'space-between' : 'end',
      display: 'flex',
      flexDirection: 'row',
      bgcolor: props.hasDefaultBg ? theme.palette.background.default : 'inherit',
      borderBottom: props.hasBorder ? `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}` : 0,
      [theme.breakpoints.down('sm')]: {
        py: lg,
        pl: props.leftIcon ? xs : lg,
        pr: xs,
      },
      '& svg': {
        ...commonStyles.smIcon
      }
    },
    title: {
      ...commonStyles.textEllipsis(1),
      fontWeight: Dimens.fontWeight.bold
    }
  };

  return styles;
};

export default useStyles;