import { Stack, Typography } from '@mui/material';

import React from 'react';
import useStyles from './styles';

interface Props {
  caption: string;
  action: string;
  hideFooter?: boolean;
  onFooterClick: () => void;
}

/**
 * SignInFooter Component
 *
 * This component renders a footer section typically used for displaying a message
 * with an clickable action link (e.g., "Don't have an account? Sign up").
 *
 * @props { Props } - The component properties.
 * @property { string } caption - The text content before the action link.
 * @property { string } action - The text displayed as the clickable action link.
 * @property { boolean } hideFooter - (Optional) A flag to control footer visibility. Defaults to false (visible).
 * @property { () => void } onFooterClick - The function to be called when the action link is clicked.
 *
 * @returns {JSX.Element} - The rendered JSX element for the sign-in footer.
 */
const SignInFooter: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Stack sx={styles.footer} visibility={props.hideFooter ? 'hidden' : 'visible'}>
      <Typography component={'span'} variant='caption'>
        {`${props.caption} `}
        <Typography
          variant='caption'
          sx={styles.footerTxt}
          onClick={props.onFooterClick}
        >
          {props.action}
        </Typography>
      </Typography>
    </Stack>
  );
};

export default SignInFooter;
