import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';

import CheckIcon from '../CustomIcons/CheckIcon';
import ChevronDownIcon from '../CustomIcons/ChevronDownIcon';
import CustomLabel from '../CustomLabel';
import Dimens from '../../theme/dimens';
import { FieldProps } from 'formik';
import MenuData from '../../types/ui/menu-data';
import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import useStyles from './styles';

type Props<T extends MenuData> = {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  tooltip?: string;
  menu: Array<T>;
  showCheckedIcon?: boolean;
  hasBottomSpacing?: boolean;
} & SelectProps & FieldProps;

/**
 * Custom Input Select Component for Forms
 *
 * This component renders a custom select input field with the following features:
 * - Supports a label, sub-label, and placeholder text.
 * - Renders a dropdown menu with options from the provided `menu` prop (array of `MenuData`).
 * - Uses a custom caret icon for the dropdown indicator.
 * - Integrates with Formik for error handling and validation.
 * - Displays a placeholder option and underlines it for better clarity.
 * - Applies custom styles using Material-UI theming and CSS-in-JS (styles).
 *
 * @props { Props } - The component properties.
 * @property { string } label - The main label for the select input.
 * @property { string } subLabel - Optional sub-label displayed below the main label.
 * @property { string } placeholder - Placeholder text displayed when no option is selected.
 * @property { Array<MenuData> } menu - An array of objects defining the menu options (id and value properties).
 * @property { FieldProps } - Additional props used for form integration with Formik.
 * @property { SelectProps } - Standard props for the `Select` component from Material-UI.
 *
 * @returns {JSX.Element} - The rendered JSX element for the custom select input field.
 */
const CustomInputSelect = <T extends MenuData>(props: Props<T>) => {

  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel,
    placeholder,
    tooltip,
    showCheckedIcon,
    hasBottomSpacing,
    ...rest
  } = props;
  const styles = useStyles(hasBottomSpacing);
  const hasError = Boolean(errors[name] && touched[name]);

  /**
   * Handles the change event for the select input, updating the form field value.
   * 
   * @param {SelectChangeEvent<unknown>} event - The change event object from Material-UI Select.
   */
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setFieldValue(name, event.target.value as string);
  }

  /**
   * Handles the blur event for the custom select input.
   * 
   * This function is triggered when the select input loses focus.
   * It performs the following actions:
   *  - Sets the touched state for the corresponding form field using `setFieldTouched` from Formik.
   *  - Calls the `onBlur` callback function provided as a prop, potentially for additional blur handling logic.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  }

  const renderValue = (value: string) => {
    const selectedItem = props.menu.find((item) => item.id === value);

    return selectedItem ? <Typography variant={'p1'}>{selectedItem.value}</Typography> : null;
  };

  return (
    <Stack sx={styles.wrapper}>
      <CustomLabel label={label} subLabel={subLabel} />
      <FormControl>
        <InputLabel sx={styles.placeholder}><Typography variant='p1'>{placeholder}</Typography></InputLabel>
        <Select
          {...rest}
          sx={styles.select}
          renderValue={value => renderValue(value)}
          error={hasError}
          displayEmpty
          value={value || ''}
          IconComponent={(props) => <ChevronDownIcon sx={styles.caretIcon} {...props} />}
          MenuProps={{
            disableAutoFocusItem: true,
            MenuListProps: {
              sx: styles.menuList
            },
            slotProps: {
              paper: {
                sx: styles.paper
              }
            }
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {props.menu.flatMap((option, index) => [
            <MenuItem key={option.id} value={option.id} sx={styles.menuItem}>
              <Typography variant={'p1'}>{option.value}</Typography>
              {(showCheckedIcon && value == option.id) &&
                <CheckIcon />
              }
            </MenuItem>,
            index !== props.menu.length - 1 && (
              <Divider key={`divider-${index}`} sx={styles.divider} />
            ),
          ])}
        </Select>
      </FormControl>
      {tooltip && <Typography variant='footer' sx={styles.tooltip}>{tooltip}</Typography>}
      {hasError && <FormHelperText sx={styles.fieldError}>{errors[name]?.toString()}</FormHelperText>}
    </Stack>
  );
};

export default CustomInputSelect;
