import Dimens from '../../../theme/dimens';
import { DirectoryRecord } from '../../../types/directory-record';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DirectoryCard component based on a directory record.
 *
 * @param {DirectoryRecord} record - The directory record object.
 * @returns {object} An object containing styles for the DirectoryCard component.
 */
const useStyles = (record: DirectoryRecord) => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	const { xxs, xs, lg, xl } = Dimens.spacing;

	const styles = {
		...commonStyles,
		card: {
			borderRadius: Dimens.radius.lg,
			boxShadow: 'none',
			height: Dimens.directoryCardMaxHeight,
			display: 'flex',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			backgroundImage: 'none',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		cardActionArea: {
			p: xl,
			alignItems: 'center',
			justifyContent: 'center'
		},
		cardContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			'&.MuiCardContent-root': {
				p: 0
			}
		},
    avatar: {
      width: Dimens.avatarSize.xxl,
      height: Dimens.avatarSize.xxl,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
			mb: lg
    },
    badge: {
      'span': {
        height: Dimens.avatarBadgeSize,
        width: Dimens.avatarBadgeSize,
        border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.paper}`,
        borderRadius: Dimens.radius.md,
        bgcolor: theme.palette.warning.main,
        display: record.groupRoomKey ? 'none' : 'unset'
      }
    },
		badgeOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		title: {
			...commonStyles.textEllipsis(1),
			color: theme.palette.text.primary,
			fontWeight: Dimens.fontWeight.medium,
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				...commonStyles.textEllipsis(2),
			}
		},
		subTitle: {
			...commonStyles.textEllipsis(1),
			pt: xxs,
			color: theme.palette.text.secondary,
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		actionLayout: {
			pt: (record.groupRoomKey && !record.isMember) ? lg : xs,
			'.MuiIconButton-root': {
				'svg': {
					...commonStyles.smIcon,
					color: theme.palette.primary.main
				}
			}
		},
		joinGrpBtn: {
			display: 'none'
		}
	};
	
	return styles;
};

export default useStyles;