import { DRAWER_HIDDEN_PATH_LIST } from '../../../../utils/constants';
import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for DrawerNavigator component.
 * It uses the theme object from Material UI to access theme properties.
 * 
 * @param {boolean} isSubDrawerOpen - Optional boolean value indicating if the child drawer is open (defaults to false).
 * @returns {object} - An object containing styles for the container and floating button.
 */
const useStyles = (isSubDrawerOpen = false, path = '') => {

  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        zIndex: 1,
        boxShadow: `0 0 ${Dimens.shadowBlur} ${theme.palette.text.secondary}1A`,
        display:  DRAWER_HIDDEN_PATH_LIST.includes(path) ? 'none' : 'flex'
      }
    },
    floatingBtn: {
      position: 'absolute',
      zIndex: 1200,
      transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      bgcolor: theme.palette.background.paper,
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md,
      p: Dimens.spacing.xs,
      left: isSubDrawerOpen ? Dimens.drawer.fabMargin.child : Dimens.drawer.fabMargin.parent,
      top: Dimens.drawer.fabMargin.parent,
      '&:hover': {
        bgcolor: theme.palette.background.default
      },
      '& svg': {
        fontSize: `${Dimens.icon.sm} !important`,
        color: theme.palette.text.primary
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };

  return styles;
};

export default useStyles;
