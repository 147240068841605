import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the MessageDetailPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    contentWrapper: {
      ...commonStyles.noScrollBar,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',
      bgcolor: theme.palette.background.default
    },
    header: {
      p: `0 ${section} ${section} ${section}`,
      borderBottomLeftRadius: Dimens.radius.xl,
      borderBottomRightRadius: Dimens.radius.xl,
      bgcolor: theme.palette.background.paper
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      p: section
    }
  };

  return styles;
};

export default useStyles;
