import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the Main component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	
	const styles = {
		root: {
			height: 'inherit',
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse'
			}
		}
	};

	return styles;
};

export default useStyles;