import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Chip,
  Divider,
  FormHelperText,
  MenuItem,
  Popper,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import BellIcon from '../CustomIcons/BellIcon';
import ChevronDownIcon from '../CustomIcons/ChevronDownIcon';
import CrossIcon from '../CustomIcons/CrossIcon';
import CustomLabel from '../CustomLabel';
import { FieldProps } from 'formik';
import OptionData from '../../types/option-data';
import React from 'react';
import StarIcon from '../CustomIcons/StarIcon';
import Util from '../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

type Props<T extends OptionData> = {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  menu: Array<T>;
  showDropdownIcon?: boolean;
  defaultOptionId?: string;
} & SelectProps & FieldProps & AutocompleteProps<any, false, false, false>; /* eslint-disable-line */

/**
 * CustomAutocomplete component is a customized version of MUI's Autocomplete component 
 * that integrates with Formik for form management.
 * It provides various props to manage labels, placeholders, menu items, and styles.
 * It also handles errors and displays selected options as chips when in multiple selection mode.
 *
 * @template T - Type of the option data.
 * @param {Props<T>} props - Component props including label, subLabel, placeholder, menu, and other AutocompleteProps.
 * @returns {JSX.Element} - The rendered custom autocomplete component.
 */
const CustomAutocomplete = <T extends OptionData>(props: Props<T>) => {

  const {
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel,
    placeholder,
    showDropdownIcon,
    defaultOptionId,
    ...rest
  } = props;
  const styles = useStyles(rest.readOnly);
  const { t } = useTranslation();
  const hasError = Boolean(errors[name] && touched[name]);

  /**
   * Handles the blur event to update Formik's touched field and
   * trigger the onBlur function.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  }

  /**
   * Renders the input field for the autocomplete.
   *
   * @param {AutocompleteRenderInputParams} params - Parameters for rendering the input.
   * @returns {JSX.Element} - The rendered input field.
   */
  const renderInput = (params: AutocompleteRenderInputParams) => {

    return (
      <TextField
        {...params}
        error={hasError}
        sx={styles.textField}
        placeholder={props.placeholder}
        InputProps={{
          ...params.InputProps,
          sx: styles.outerInput
        }}
      />
    );
  }

  /**
   * Renders an option for the autocomplete.
   *
   * @param {React.HTMLAttributes<HTMLLIElement>} optionProps - Props for the menu item.
   * @param {T} option - The option data.
   * @param {AutocompleteRenderOptionState} state - State of the option.
   * @returns {JSX.Element[]} - Array of JSX elements for the option and divider.
   */
  const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: T,
    state: AutocompleteRenderOptionState) => {

    return (
      [<MenuItem {...optionProps} key={`option-${option.id}`} value={option.id} sx={styles.menuItem}>
        <Typography variant={'p1'}>{option.name}</Typography>
      </MenuItem>,
      (state.index !== props.menu.length - value?.length - 1) && (
        <Divider key={`divider-${option.id}`} sx={styles.divider} />
      )
      ]
    );
  }

  /**
   * Handles the deletion of a selected chip.
   *
   * @param {T} option - The option to be deleted.
   */
  const onChipDelete = (option: T) => {
    const indexToRemove = value.findIndex((item: T) => item.id === option.id);
    if (indexToRemove !== -1) {
      const newValue = [...value]; // Create a copy of the value array
      newValue.splice(indexToRemove, 1);
      setFieldValue(name, newValue);
    }
  }

  return (
    <Stack sx={styles.wrapper}>
      <CustomLabel label={label} subLabel={subLabel} isReadOnly={rest.readOnly} />
      <Autocomplete
        {...rest}
        sx={styles.autocomplete}
        popupIcon={showDropdownIcon ? <ChevronDownIcon sx={styles.endIcon} /> : <></>}
        noOptionsText={props.noOptionsText || t('noOptions')}
        value={value ?? (props.multiple ? [] : null)} //This is to make sure that the component remains controlled always.
        options={props.menu}
        filterSelectedOptions
        disableClearable
        renderInput={renderInput}
        PopperComponent={(props) => <Popper {...props} sx={styles.popper} />}
        renderOption={renderOption}
        getOptionLabel={(option) => option.name || ''}
        renderTags={props.multiple ? () => <></> : undefined}
        isOptionEqualToValue={(option, value) => (option.id === value.id)}
        onChange={(_event, newValue) => setFieldValue(name, newValue)}
        onBlur={handleBlur}
      />
      {hasError &&
        <FormHelperText sx={styles.fieldError}>
          {errors[name]?.toString()}
        </FormHelperText>
      }
      {props.multiple && !Util.isArrayEmpty(value) &&
        <Box sx={styles.chipWrapper}>
          {value.map((option: T, index: number) => {
            return (
              <Chip
                variant='filled'
                sx={styles.chip}
                key={`chip-index-${index}`}
                label={option.name}
                onDelete={() => onChipDelete(option)}
                deleteIcon={(option.id === defaultOptionId) ? <></> : <CrossIcon />}
                avatar={(option.id === defaultOptionId) ? <StarIcon /> : undefined}
              />
            );
          })}
        </Box>
      }
    </Stack>
  );
};

export default CustomAutocomplete;
