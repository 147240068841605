import { Box, Grid } from '@mui/material';
import { CARD_MAX_WIDTH, SortByAlphabet, siteInitialValue } from '../../utils/constants';
import React, { useEffect, useState } from 'react';

import AddIcon from '../../components/CustomIcons/AddIcon';
import CircularProgressBar from '../../components/CircularProgressBar';
import { FETCH_SITE_LIST } from '../../data/site/action-types';
import InlineAlert from '../../components/InlineAlert';
import { Masonry } from '@mui/lab';
import NoDataFound from '../../components/NoDataFound';
import SearchAndSortTopBar from '../../components/SearchAndSortTopBar';
import Site from '../../types/site';
import SiteCard from './SiteCard';
import SiteDetailDialog from './SiteDetailDialog';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useDepartmentApi } from '../../data/department/api';
import { useSiteApi } from '../../data/site/api';
import useStyles from './styles';
import { useWindowSize } from '../../hooks/use-window-size';

/**
 * SiteScreen component for managing site information.
 *
 * This component displays a list of sites with search, sort, and creation functionalities.
 * Users can view site details in a modal dialog and potentially edit or create new sites.
 *
 * @returns {JSX.Element} - The rendered SiteScreen component.
 */
const SiteScreen: React.FC = () => {

  const styles = useStyles();
  const [width] = useWindowSize();
  const numOfColumns = Math.floor(width / CARD_MAX_WIDTH);
  const [sortBy, setSortBy] = useState<string>(SortByAlphabet.A_Z);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openSiteDialog, setOpenSiteDialog] = useState<boolean>(false);
  const [site, setSite] = useState<Site>(siteInitialValue);
  const siteApi = useSiteApi();
  const deptApi = useDepartmentApi();
  const apiStatus = siteApi.state.site.apiStatus;

  /**
   * Fetches site and department data on component mount.
   */
  useEffect(() => {
    initSiteFetch();
  }, []);

  /**
   * Initializes site and department data fetching.
   */
  const initSiteFetch = () => {
    siteApi.fetchSiteList();
    deptApi.fetchDepartmentList();
  }

  /**
   * Handles site selection and opens the site detail dialog.
   *
   * Sets the selected `site` and opens the dialog.
   *
   * @param {Site} site - The selected site object.
   */
  const onItemSelected = (site: Site) => {
    setSite(site);
    setOpenSiteDialog(true);
  }

  /**
   * Sorts and filters the site list based on search and sort criteria.
   *
   * @returns {Site[]} - The sorted and filtered site list.
   */
  const sortAndFilterSites = () => {
    return (siteApi.state.site.siteList || []).filter((site) =>
      site.name.toLowerCase().includes(searchKey.toLowerCase())
    ).sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);

      return sortBy === SortByAlphabet.A_Z ? comparison : -comparison;
    });
  };

  const siteList = sortAndFilterSites();

  /**
   * Handles closing the site detail dialog.
   *
   * Closes the dialog and optionally resets the site error state.
   */
  const onSiteDetailDialogClose = () => {
    setOpenSiteDialog(false);
    siteApi.resetError();
  };

  return (
    <Box sx={styles.container}>
      <SearchAndSortTopBar
        title={t('sites')}
        subTitle={t('manageSites')}
        actionText={t('new')}
        actionIcon={<AddIcon />}
        onActionClick={() => onItemSelected(siteInitialValue)}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onSearchChange={setSearchKey}
      />
      <InlineAlert sx={styles.alertWrapper} message={Util.getApiError([FETCH_SITE_LIST], apiStatus)} />
      {Util.isArrayEmpty(siteList) ? (
        <NoDataFound message={t('noSitesFound')} />
      ) : (
        <Grid sx={styles.contentWrapper}>
          <Masonry columns={numOfColumns === 0 ? 1 : numOfColumns} spacing={2} sx={styles.content} sequential>
            {siteList.map((site) => (
              <SiteCard site={site} key={site.id} onItemSelected={onItemSelected} />
            ))}
          </Masonry>
        </Grid>
      )}
      {openSiteDialog &&
        <SiteDetailDialog
          open={openSiteDialog}
          site={site}
          deptList={deptApi.state.department.departmentList}
          onClose={onSiteDetailDialogClose}
        />
      }
      <CircularProgressBar show={Util.isApiLoading([FETCH_SITE_LIST], apiStatus)} />
    </Box>
  );
};

export default SiteScreen;
