import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import { Box } from '@mui/material';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomButton from '../../../../components/CustomButton';
import { DELETE_DEPT } from '../../../../data/department/action-types';
import Department from '../../../../types/department';
import { DepartmentSubMenuItem } from '../../../../utils/constants';
import InlineAlert from '../../../../components/InlineAlert';
import Util from '../../../../utils/util';
import { useDepartmentApi } from '../../../../data/department/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  department: Department;
  onClose: (action: string) => void;
}

/**
 * Renders a tab for department settings, including delete options.
 *
 * @param {Props} props - Component props containing the department data and close callback.
 * @returns JSX element representing the department settings tab.
 */
const SettingsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCantDeleteDialog, setOpenCantDeleteDialog] = useState<boolean>(false);
  const deptApi = useDepartmentApi();
  const apiStatus = deptApi.state.department.apiStatus;

  /**
   * Handles the click event on the delete button, opening the appropriate dialog based on member count.
   * If there are members then show warning saying, department cannot be deleted until all the members are removed.
   * Else show delete department dialog.
   */
  const onDeleteClick = () => {
    if ((props.department.memberCount ?? 0) > 0) {
      setOpenCantDeleteDialog(true);
    } else {
      setOpenDeleteDialog(true);
    }
  }

  /**
   * Handles the confirmation for deleting the department and calls the department delete API
   */
  const deleteDepartment = () => {
    deptApi.deleteDepartment(props.department).then(dept => {
      if (dept) {
        props.onClose(DepartmentSubMenuItem.Delete);
      }
    });
  }

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([DELETE_DEPT], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        <CustomButton
          title={t('delete')}
          color='secondary'
          startIcon={<BinIcon />}
          destructive
          fullWidth
          onClick={onDeleteClick}
        />
      </Box>
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('deleteDept')}
        titleIcon={<BinIcon />}
        message={t('deleteDeptMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={deleteDepartment}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <AlertDialogSmall
        open={openCantDeleteDialog}
        title={t('cantDeleteDept')}
        message={t('cantDeleteDeptMsg')}
        primaryLabel={t('ok')}
        onPrimaryAction={() => setOpenCantDeleteDialog(false)}
        onClose={() => setOpenCantDeleteDialog(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([DELETE_DEPT], apiStatus)} />
    </Box>
  );
};

export default SettingsTab;