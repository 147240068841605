import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Chip,
  FormHelperText,
  Popper,
  SelectProps,
  Stack,
  TextField,
} from '@mui/material';

import CrossIcon from '../CustomIcons/CrossIcon';
import CustomLabel from '../CustomLabel';
import { FieldProps } from 'formik';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  regExp: RegExp;
} & SelectProps & FieldProps & AutocompleteProps<any, true, false, true>; /* eslint-disable-line */

/**
 * Renders a custom Autocomplete component with chip input functionality.
 *
 * @param {Props} props - Component props containing label, sub-label, placeholder, regular expression,
 * and other formik and autocomplete properties.
 * 
 * @returns {JSX.Element} JSX element representing the AutocompleteChipInput component.
 */
const AutocompleteChipInput: React.FC<Props> = (props: Props) => {

  const {
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel,
    placeholder,
    regExp,
    ...rest
  } = props;
  const styles = useStyles();
  const { t } = useTranslation();
  const hasError = Boolean(errors[name] && touched[name]);

  /**
   * Handles the blur event to update Formik's touched field and
   * trigger the onBlur function.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  }

  /**
   * Handles the keydown event on the input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>} e - The keyboard event object.
   * @param {string} newValue - The new value entered in the input field.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
    const isValidEmail = newValue.match(regExp);
    if (e.key === 'Enter' && !isValidEmail) {
      e.stopPropagation();
    }
  }

  /**
   * Renders the input field for the Autocomplete component.
   *
   * @param {AutocompleteRenderInputParams} params - Parameters passed to the renderInput function.
   * 
   * @returns {JSX.Element} JSX element representing the input field.
   */
  const renderInput = (params: AutocompleteRenderInputParams) => {

    return (
      <TextField
        {...params}
        error={hasError}
        sx={styles.textField}
        placeholder={props.placeholder}
        InputProps={{
          ...params.InputProps,
          sx: styles.outerInput
        }}
        inputProps={{
          ...params.inputProps,
          onKeyDown: (e) => handleKeyDown(e, params.inputProps.value as string)
        }}
      />
    );
  }

  /**
   * Handles the deletion of a chip from the input field.
   *
   * @param {string} option - The option to be deleted.
   */
  const onChipDelete = (option: string) => {
    const indexToRemove = value.findIndex((item: string) => item === option);
    if (indexToRemove !== -1) {
      const newValue = [...value]; // Create a copy of the value array
      newValue.splice(indexToRemove, 1);
      setFieldValue(name, newValue);
    }
  }

  return (
    <Stack sx={styles.wrapper}>
      <CustomLabel label={label} subLabel={subLabel} />
      <Autocomplete
        {...rest}
        multiple
        freeSolo
        sx={styles.autocomplete}
        value={value ?? []}
        options={[]}
        renderInput={renderInput}
        PopperComponent={(props) => <Popper {...props} sx={styles.popper} />}
        renderTags={(tagValue, _getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              variant='filled'
              sx={styles.chip}
              key={`chip-index-${index}`}
              label={option}
              onDelete={() => onChipDelete(option)}
              deleteIcon={<CrossIcon />}
            />
          ));
        }}
        onChange={(_event, newValue) => setFieldValue(name, newValue)}
        onBlur={handleBlur}
      />
      {hasError &&
        <FormHelperText sx={styles.fieldError}>
          {errors[name]?.toString()}
        </FormHelperText>
      }
    </Stack>
  );
};

export default AutocompleteChipInput;
