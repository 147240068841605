import { ChatRole, InteractionType, SortOrder } from '../../utils/constants';
import { apiFailure, initAddGroupMember, initCreateGroup, initGroupMembersFetch, initGroupRoleUpdate, initRemoveGroupMember, initSearchDirectory, initUpdateGroup, resetDirectoryState, resetErrorState, searchDirectorySuccess } from './actions';
import { doGet, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import ConversationData from '../../types/conversation-data';
import { DirectoryRecord } from '../../types/directory-record';
import { DirectorySearchRequest } from '../../types/directory-search-request';
import { DirectorySearchResponse } from '../../types/directory-search-response';
import { Group } from '../../types/group';
import GroupCreateResponse from '../../types/group-create-response';
import GroupMemberBaseInfo from '../../types/group-member-base-info';
import GroupMemberListResponse from '../../types/group-member-list-response';
import GroupMemberResponse from '../../types/group-member-response';
import GroupUpdateResponse from '../../types/group-update-response';
import LoginUtil from '../../utils/login-util';
import React from 'react';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { interactionHistorySuccess } from '../inbox/actions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for interacting with the directory API.
 * 
 * This hook provides functions to:
 * - Search directories based on a request object.
 * - Reset the directory error state in the Redux store.
 * - Reset the entire directory state in the Redux store.
 * - Dispatch an API failure action with an error message.
 * 
 * @returns {object} An object containing functions for interacting with the directory API and the current directory state.
 */
export function useDirectoryApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Initiates a search for directories based on the provided request object.
	 * 
	 * Dispatches an initial search action, then performs a POST request to the API.
	 * Updates the directory state with the retrieved data or dispatches an error action upon failure.
	 * 
	 * @param {DirectorySearchRequest} request The search request object.
	 */
	const searchDirectory = useCallback(async (request: DirectorySearchRequest) => {
		dispatch(initSearchDirectory());
		try {
			const response: DirectorySearchResponse = await doPost(APIConfig.directorySearch, request);
			let recordList: Array<DirectoryRecord> = state.directory.recordList;
			if (response.data) {
				const newPageRecordList = [...(response.data.users || []), ...(response.data.groups || [])];
				if (request.page.pageNumber === 0) {
					recordList = newPageRecordList;
				} else {
					recordList = [...state.directory.recordList, ...newPageRecordList];
				}
				// Remove duplicate records if any as pagination might give duplicate results.
				const uniqueRecordsMap = new Map();
				recordList.forEach(record => {
					// Use record.email for users and record.groupKey for groups as the unique key
					const uniqueKey = record.email || record.groupRoomKey;
					if (uniqueKey && !uniqueRecordsMap.has(uniqueKey)) {
						uniqueRecordsMap.set(uniqueKey, record);
					}
				});
				// Convert the map back to an array
				recordList = Array.from(uniqueRecordsMap.values());
				recordList.sort((a, b) => {
					if (request.page.sort.order === SortOrder.Asc) {
						return a.name.localeCompare(b.name);
					} else {
						return b.name.localeCompare(a.name);
					}
				});
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.directory.recordList]);

	/**
	 * Creates a new group and updates the directory and interaction history.
	 *
	 * @param {Group} request - The group data to be created.
	 * @returns {Promise<Group | undefined>} - A promise that resolves to the created group data or undefined if an error occurs.
	 */
	const createGroup = useCallback(async (request: Group) => {
		dispatch(initCreateGroup());
		try {
			request.deviceId = LoginUtil.getClientId();
			const response: GroupCreateResponse = await doPost(APIConfig.createGroup, request);
			const recordList: Array<DirectoryRecord> = state.directory.recordList;
			if (response.data) {
				response.data.isMember = true;
				response.data.isAdmin = true;
				recordList.unshift(response.data as DirectoryRecord);
				updateInteractionHistory(response.data as DirectoryRecord);
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates group information in the directory.
	 *
	 * @param {Group} request - Object containing updated group data.
	 * 
	 * @returns {Promise<GroupUpdateResponse>} Promise resolving to the API response data.
	 */
	const updateGroup = useCallback(async (request: Group) => {
		dispatch(initUpdateGroup());
		try {
			request.deviceId = LoginUtil.getClientId();
			request.updatedByLoginId = LoginUtil.getLoginId();
			request.updatedByName = LoginUtil.getUserFullName();
			const response: GroupUpdateResponse = await doPost(APIConfig.updateGroup, request);
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			if (response.data) {
				const record = recordList.find(record => record.groupRoomKey === request.groupRoomKey);
				if (record) {
					record.name = request.name;
					record.description = request.description;
					updateInteractionHistory(record);
				}
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Adds or removes a group member based on the provided request and operation flag.
	 *
	 * @param {GroupMemberBaseInfo} request - Information about the group member to be added or removed.
	 * @param {boolean} isAddMember - Flag indicating whether to add or remove the member.
	 * 
	 * @returns {Promise<GroupMemberResponse>} Promise resolving to the API response data.
	 */
	const addOrRemoveGroupMember = useCallback(async (request: GroupMemberBaseInfo, isAddMember?: boolean) => {
		dispatch(isAddMember ? initAddGroupMember() : initRemoveGroupMember());
		try {
			request.deviceId = LoginUtil.getClientId();
			request.adminLoginId = LoginUtil.getLoginId();
			const url = isAddMember ? APIConfig.addGroupMember : APIConfig.removeGroupMember;
			const response: GroupMemberResponse = await doPost(url, request);
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			if (response.data) {
				const record = recordList.find(record => record.groupRoomKey === request.groupRoomKey);
				if (record) {
					if (isAddMember) {
						record.groupMembers?.push(request);
					} else {
						record.groupMembers = record.groupMembers?.filter(member => member.loginId !== request.loginId);
					}
					updateInteractionHistory(record);
				}
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates the chat role of a group member in the directory.
	 *
	 * @param {GroupMemberBaseInfo} request - Object containing member information and updated role.
	 * 
	 * @returns {Promise<GroupMemberResponse>} Promise resolving to the API response data.
	 */
	const updateGroupRole = useCallback(async (request: GroupMemberBaseInfo) => {
		dispatch(initGroupRoleUpdate());
		try {
			request.deviceId = LoginUtil.getClientId();
			request.adminLoginId = LoginUtil.getLoginId();
			const response: GroupMemberResponse = await doPost(APIConfig.updateGroupRole, request);
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			if (response.data) {
				const record = recordList.find(record => record.groupRoomKey === request.groupRoomKey);
				if (record) {
					for (const member of record.groupMembers || []) {
						if (member.loginId === request.loginId) {
							member.chatRole = request.chatRole;
							break; // Exit the loop when the condition is met
						}
					}
					updateInteractionHistory(record);
				}
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches a list of group members for a given room key and page number.
	 * This function is debounced to avoid excessive API calls.
	 *
	 * @param {Group} group - Group details.
	 */
	const fetchGroupMembers = useCallback(async (group: Group) => {
		dispatch(initGroupMembersFetch());
		try {
			const url = APIConfig.groupMembers.replace('{room_key}', group.groupRoomKey);
			const response: GroupMemberListResponse = await doGet(url);
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			if (!Util.isArrayEmpty(response.data)) {
				let record = recordList.find(record => record.groupRoomKey === group.groupRoomKey);
				if (!record) {
					recordList.push(group as DirectoryRecord);
					record = (group as DirectoryRecord);
				}
				record.groupMembers = response.data;
				record.isCompleted = true;
				const loggedInUser = response.data.find(item => item.loginId === LoginUtil.getLoginId());
				if (loggedInUser) {
					record.isMember = true;
					record.isAdmin = (loggedInUser.chatRole === ChatRole.Admin);
				}
				updateInteractionHistory(record);
			}
			dispatch(searchDirectorySuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates the interaction history with a new conversation based on the provided directory record.
	 *
	 * @param {DirectoryRecord} record - The directory record representing the new group.
	 */
	const updateInteractionHistory = (record: DirectoryRecord) => {

		const conversationList = state.inbox.messageList ?? [];
		const conversation = conversationList.find(conversation => conversation.groupRoomKey === record.groupRoomKey);
		if (conversation) {
			Object.assign(conversation, {...record});
		} else {
			const newConversation: ConversationData = {
				...record,
				count: 0,
				interactionType: InteractionType.Group,
				senderName: LoginUtil.getUserFullName(),
				senderLoginId: LoginUtil.getLoginId()
			}
			conversationList.push(newConversation);
		}
		dispatch(interactionHistorySuccess(conversationList));
	}

	/**
	 * Resets the directory error state in the Redux store.
	 */
	const resetError = () => {
		if (state.directory.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the directory state in the Redux store.
	 */
	const resetDirectory = () => {
		dispatch(resetDirectoryState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
	}

	return {
		searchDirectory,
		createGroup,
		updateGroup,
		addOrRemoveGroupMember,
		updateGroupRole,
		fetchGroupMembers,
		resetError,
		resetDirectory,
		state
	};
}