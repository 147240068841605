import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CameraIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.55556 4.61068H3.33333C3.74589 4.61068 4.14155 4.44679 4.43328 4.15507C4.725 3.86334 4.88889 3.46768 4.88889 3.05512C4.88889 2.84884 4.97083 2.65101 5.11669 2.50515C5.26256 2.35929 5.46039 2.27734 5.66667 2.27734H10.3333C10.5396 2.27734 10.7374 2.35929 10.8833 2.50515C11.0292 2.65101 11.1111 2.84884 11.1111 3.05512C11.1111 3.46768 11.275 3.86334 11.5667 4.15507C11.8584 4.44679 12.2541 4.61068 12.6667 4.61068H13.4444C13.857 4.61068 14.2527 4.77457 14.5444 5.06629C14.8361 5.35801 15 5.75367 15 6.16623V13.1662C15 13.5788 14.8361 13.9745 14.5444 14.2662C14.2527 14.5579 13.857 14.7218 13.4444 14.7218H2.55556C2.143 14.7218 1.74733 14.5579 1.45561 14.2662C1.16389 13.9745 1 13.5788 1 13.1662V6.16623C1 5.75367 1.16389 5.35801 1.45561 5.06629C1.74733 4.77457 2.143 4.61068 2.55556 4.61068Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.66602 9.27865C5.66602 9.89748 5.91185 10.491 6.34943 10.9286C6.78702 11.3661 7.38051 11.612 7.99935 11.612C8.61819 11.612 9.21168 11.3661 9.64926 10.9286C10.0868 10.491 10.3327 9.89748 10.3327 9.27865C10.3327 8.65981 10.0868 8.06631 9.64926 7.62873C9.21168 7.19114 8.61819 6.94531 7.99935 6.94531C7.38051 6.94531 6.78702 7.19114 6.34943 7.62873C5.91185 8.06631 5.66602 8.65981 5.66602 9.27865Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default CameraIcon;