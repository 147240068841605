import { Box, Drawer, DrawerProps } from '@mui/material';

import { ActionType } from '../../utils/constants';
import React from 'react';
import RightDrawerHeader from './RightDrawerHeader';
import useStyles from './styles';

type Props = {
  title?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
  hasBorder?: boolean;
  hasDefaultBg?: boolean;
  onActionClick?: (action: ActionType) => void;
  onClose: () => void;
} & DrawerProps;

/**
 * RightDrawerLayout component for displaying content in a right-aligned drawer.
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The rendered component.
 */
const RightDrawerLayout: React.FC<Props> = (props: Props) => {

  const {
    title,
    leftIcon,
    rightIcon,
    hasBorder,
    hasDefaultBg,
    children,
    onActionClick,
    onClose,
    ...rest
  } = props;
  const styles = useStyles();

  return (
    <Drawer anchor='right' {...rest} sx={styles.drawer} hideBackdrop onClose={onClose}>
      <Box sx={styles.contentWrapper}>
        <RightDrawerHeader {...props} />
        {children}
      </Box>
    </Drawer>
  );
};

export default RightDrawerLayout;
