import { Stack, Typography } from '@mui/material';

import Dimens from '../../../theme/dimens';
import React from 'react';
import useStyles from './styles';

interface Props {
  title: string;
  desc: string;
}

/**
 * SignInHeader Component
 *
 * This component renders a header section typically used for displaying a title
 * and a description text for sign-in or sign-up forms.
 *
 * @props { Props } - The component properties.
 * @property { string } title - The title text to be displayed.
 * @property { string } desc - The description text to be displayed.
 *
 * @returns {JSX.Element} - The rendered JSX element for the sign-in header.
 */
const SignInHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  
  return (
    <Stack sx={styles.header}>
      <Typography variant='h1'>{props.title}</Typography>
      <Typography variant='p1'>{props.desc}</Typography>
    </Stack>
  );
};

export default SignInHeader;
