import { Box } from '@mui/material';
import CircularProgressBar from '../../../components/CircularProgressBar';
import Department from '../../../types/department';
import InlineAlert from '../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../components/ListItemAddRemoveCard';
import React from 'react';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import { UPDATE_DEPT } from '../../../data/department/action-types';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { useDepartmentApi } from '../../../data/department/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	department: Department;
	onClose: () => void;
}

/**
 * DepartmentAddMemberPanel component for searching and adding members to a department.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DepartmentAddMemberPanel: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const userApi = useUserApi();
	const departmentApi = useDepartmentApi();
	const apiStatus = departmentApi.state.department.apiStatus;

	/**
	 * Filters the user list based on existing department members and sorts alphabetically.
	 *
	 * @returns {Array<User>} Filtered list of users not already in the department.
	 */
	const getFilteredUserList = (): Array<User> => {
		let userList: Array<User> = [];
		if (!Util.isArrayEmpty(userApi.state.user.searchedUserList)) {
			const existingMemberList = props.department.members?.map(member => member.loginId) ?? [];
			userList = userApi.state.user.searchedUserList.filter(user => !existingMemberList.includes(user.email))
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
		}

		return userList;
	}

	// Get the filtered user list based on search and sorting logic.
	const filteredUserList = getFilteredUserList();

	/**
	 * Handles adding a user as a member to the department.
	 *
	 * @param {User} user - User profile data for the new member.
	 */
	const addMember = (user: User): void => {
		// TODO: Change this when new API is implemented
		const request = { ...props.department };
		(request.members ?? []).push(user);
		departmentApi.updateDepartment(request).then(data => {
			if (data) {
				props.onClose();
			}
		});
	}

	/**
	 * Handles changes in the search bar input, updating the search query 
	 * and fetching users based on the new criteria.
	 *
	 * @param {string} searchKey Updated search keyword.
	 */
	const onSearchChange = (searchKey: string) => {
		if (searchKey) {
			userApi.searchUserList(searchKey, 0);
		}
	}

	return (
		<RightDrawerLayout open={props.open} title={t('addMembers')} onClose={props.onClose}>
			<Box sx={styles.container}>
				<InlineAlert message={Util.getApiError([UPDATE_DEPT], apiStatus)} />
				<SearchBar placeholder={t('search')} handleChange={onSearchChange} />
				<Box sx={styles.contentWrapper}>
					{filteredUserList.map((user, index) => (
						<ListItemAddRemoveCard
							key={`user-profile-${index}`}
							title={user.name}
							subTitle={user.position ?? ''}
							isExistingItem={false}
							onActionClick={() => addMember(user)}
						/>
					))}
				</Box>
				<CircularProgressBar show={Util.isApiLoading([UPDATE_DEPT], apiStatus)} />
			</Box>
		</RightDrawerLayout>
	);
};

export default DepartmentAddMemberPanel;