import { PaletteMode } from '@mui/material';
import { ThemeMode } from '../utils/constants';

/**
 * Color mode manager utility.
 * 
 * This object provides functions to manage the application's color mode 
 * using localStorage.
 */
export const colorModeManager = {
  /**
   * Gets the current color mode preference from localStorage.
   * 
   * Defaults to system theme mode if no preference is found.
   * 
   * @returns {PaletteMode} The current color mode ('dark' or 'light').
   */
  get: (): PaletteMode => {
    let systemThemeMode = ThemeMode.Light;
    if (window) {
      systemThemeMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? ThemeMode.Dark : ThemeMode.Light;
    }
    const val = localStorage.getItem('@aurora-app-color-mode');

    return (val ?? systemThemeMode) === 'dark' ? 'dark' : 'light';
    // return val === 'light' ? 'light' : 'dark';
  },
  /**
   * Sets the color mode preference in localStorage.
   * 
   * @param {string} value The desired color mode ('dark' or 'light').
   */
  set: (value: string) => {
    localStorage.setItem('@aurora-app-color-mode', value);
  }
};