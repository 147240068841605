import Dimens from '../../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for the ChildDrawerSubItem component based on theme and dimensions.
 * It also styles last child elements to remove unnecessary bottom margins.
 * 
 * @param {boolean} isActive - Optional prop to indicate if the item is active. Defaults to false.
 * @returns {object} - An object containing styles for the child drawer sub-item elements.
 */
const useStyles = (isActive = false) => {

  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      '&:last-child': {
        '& hr':{
          mb: Dimens.verticalDividerMargin
        }
      },
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      '&:last-child': {
        '& hr':{
          mb: '0 !important'
        }
      },
    },
    vDivider: {
      borderLeft: `${Dimens.smallBorderWidth} dashed ${theme.palette.text.secondary}`
    },
    hDivider: {
      height: Dimens.smallBorderWidth,
      width: Dimens.spacing.sm,
      borderBottom: `${Dimens.smallBorderWidth} dashed ${theme.palette.text.secondary}`
    },
    listBtn: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      borderRadius: Dimens.radius.md
    },
    listText: {
      color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
      margin: 0,
      marginLeft: Dimens.spacing.base
    }
  };

  return styles;
};

export default useStyles;
