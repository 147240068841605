import { ActualSessionStatus, AppFeature, AssignedSessionStatus, InteractionType } from '../../../utils/constants';
import { Avatar, Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../../routes/app-nav';
import ChatUtil from '../../../utils/chat-util';
import CircularProgressBar from '../../../components/CircularProgressBar';
import ConversationData from '../../../types/conversation-data';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import { FETCH_USER_DETAIL } from '../../../data/user/action-types';
import LoginUtil from '../../../utils/login-util';
import MediaIcon from '../../../components/CustomIcons/MediaIcon';
import MessageIcon from '../../../components/CustomIcons/MessageIcon';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import PhoneIcon from '../../../components/CustomIcons/PhoneIcon';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchIcon from '../../../components/CustomIcons/SearchIcon';
import { User } from '../../../types/user';
import UserEditPanel from '../UserEditPanel';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	user?: User;
	onClose: () => void;
}

/**
 * Renders a drawer displaying user information, actions, and potentially an edit panel.
 *
 * @param {Props} props - Component props containing open state, navigation mode, user data, and close callback.
 * 
 * @returns JSX element representing the user view panel.
 */
const UserViewPanel: React.FC<Props> = (props: Props) => {

	const { open, user, onClose } = props;
	const styles = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [showUserEdit, setShowUserEdit] = useState<boolean>(false);
	const hasEditPermission = LoginUtil.hasPermission(AppFeature.ManageUser);
	const userApi = useUserApi();
	const apiStatus = userApi.state.user.apiStatus;
	const isChatPage = location.pathname === `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`;

	/**
	 * Fetches user details if the provided user object is incomplete.
	 */
	useEffect(() => {
		const userDetail = getUserDetail();
		if (open && user && (!userDetail || !userDetail?.isCompleted)) {
			userApi.fetchUserDetail(user);
		}
	}, [open]);

	/**
	 * Retrieves the department name based on the provided department ID.
	 *
	 * @param {string} deptId - The department ID to look up.
	 * 
	 * @returns {string} The department name, or an empty string if not found.
	 */
	const getDeptNameById = (deptId: string): string => {

		return user?.departments?.find(dept => dept.id === deptId)?.name ?? '';
	}

	/**
	 * Fetches user details if not already available or incomplete.
	 *
	 * @returns {User | null} The fetched user details or null if not found or loading.
	 */
	const getUserDetail = () => {
		const detail = userApi.state.user.userList.find(item => item.email === user?.email);

		return detail;
	}

	const userDetail = getUserDetail();

	/**
	 * Handles the click event for the message button.
	 *
	 * This function prepares the conversation data and navigates to the message detail page.
	 */
	const onMessageBtnClick = () => {
		if (userDetail) {
			const conversation: ConversationData = {
				...userDetail,
				interactionType: InteractionType.Private,
				senderLoginId: LoginUtil.getLoginId(),
				senderName: LoginUtil.getUserFullName(),
				recipientLoginId: userDetail.email,
				recipientName: userDetail.name,
				recipientActualSessionStatus: ActualSessionStatus.Online,
				recipientAssignedSessionStatus: AssignedSessionStatus.NotDefined,
				count: 0,
				groupRoomKey: '',
				createdAt: '',
				createdByLoginId: '',
				createdByName: ''
			};
			const navPath = isDesktop ? `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`
				: APP_NAV.INBOX_DETAIL_REL_PATH.replace('/', '');
			navigate(navPath, {
				state: conversation
			});
		}
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={(hasEditPermission && userDetail && userDetail.isCompleted) ? <PencilIcon /> : undefined}
			onActionClick={() => setShowUserEdit(true)}
			onClose={onClose}
		>
			{(open && userDetail && userDetail.isCompleted && !showUserEdit) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.groupHeader}>
							<Box sx={styles.avatarWrapper}>
								<Avatar sx={styles.avatar}>
									<Typography variant='h1'>{Util.getInitialsFromName(userDetail.name)}</Typography>
								</Avatar>
							</Box>
							<Box sx={styles.groupContent}>
								<Typography variant='h2' sx={styles.title}>{userDetail.name}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>{userDetail.email}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>{getDeptNameById(userDetail.defaultDepartmentId)}</Typography>
								<Typography variant='footer' sx={styles.userType}>{userDetail.type.toUpperCase()}</Typography>
								{(isChatPage || location.pathname === APP_NAV.DIRECTORY) &&
									<Box sx={styles.actionLayout}>
										{!ChatUtil.isLoggedInUser(userDetail.email) &&
											<>
												<IconButton><PhoneIcon /></IconButton>
												{!isChatPage &&
													<IconButton onClick={onMessageBtnClick}><MessageIcon /></IconButton>
												}
											</>
										}
										{isChatPage &&
											<>
												<IconButton><MediaIcon /></IconButton>
												<IconButton><SearchIcon /></IconButton>
											</>
										}
									</Box>
								}
							</Box>
						</Box>
						<Box sx={styles.groupInfo}>
							<Formik enableReinitialize initialValues={userDetail} onSubmit={() => {/*Do Nothing*/ }}>
								<Form>
									<Field
										name='about'
										label={t('aboutMe')}
										placeholder={t('aboutYouPlaceholder')}
										component={CustomInput}
										maxRows={Number.MAX_SAFE_INTEGER}
										readOnly
										multiline
									/>
									{!Util.isArrayEmpty(userDetail.sites) &&
										<Field
											name='sites'
											label={t('sites')}
											placeholder={t('findSites')}
											component={CustomAutocomplete}
											multiple
											menu={[]}
											readOnly
										/>
									}
									<Field
										name='departments'
										label={t('departments')}
										placeholder={t('findDepartments')}
										component={CustomAutocomplete}
										multiple
										defaultOptionId={userDetail.defaultDepartmentId}
										menu={[]}
										readOnly
									/>
								</Form>
							</Formik>
						</Box>
					</Box>
					<Box sx={styles.footer}>
						<CustomButton title={userDetail.locked ? t('unlock') : t('lock')} fullWidth />
					</Box>
				</Box>
			}
			{hasEditPermission && userDetail &&
				<UserEditPanel
					open={showUserEdit}
					user={userDetail}
					onClose={() => setShowUserEdit(false)}
				/>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_USER_DETAIL], apiStatus)} />
		</RightDrawerLayout>
	);
};

export default UserViewPanel;