import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6395_10672)'>
          <path d='M4.13854 4.79297H3.31502C2.87819 4.79297 2.45926 4.9665 2.15038 5.27538C1.8415 5.58426 1.66797 6.00319 1.66797 6.44002V13.8517C1.66797 14.2886 1.8415 14.7075 2.15038 15.0164C2.45926 15.3253 2.87819 15.4988 3.31502 15.4988H10.7267C11.1636 15.4988 11.5825 15.3253 11.8914 15.0164C12.2003 14.7075 12.3738 14.2886 12.3738 13.8517V13.0282' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15.161 4.45242C15.4853 4.12808 15.6675 3.68818 15.6675 3.22949C15.6675 2.7708 15.4853 2.3309 15.161 2.00656C14.8366 1.68221 14.3967 1.5 13.938 1.5C13.4794 1.5 13.0395 1.68221 12.7151 2.00656L5.78516 8.91181V11.3824H8.25573L15.161 4.45242Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M11.5508 3.14844L14.0214 5.61901' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6395_10672'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default EditIcon;