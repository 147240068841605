import { Box, ListItemButton, Typography } from '@mui/material';
import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import Department from '../../../../types/department';
import DepartmentAddMemberPanel from '../../DepartmentAddMemberPanel';
import InlineAlert from '../../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../../components/ListItemAddRemoveCard';
import SearchBar from '../../../../components/SearchBar';
import { UPDATE_DEPT } from '../../../../data/department/action-types';
import { User } from '../../../../types/user';
import UserAddIcon from '../../../../components/CustomIcons/UserAddIcon';
import Util from '../../../../utils/util';
import { useDepartmentApi } from '../../../../data/department/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  department: Department;
}

/**
 * MembersTab component for managing department members (listing, add and removal).
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MembersTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showAddMemberPanel, setShowAddMemberPanel] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<User>();
  const [searchKey, setSearchKey] = useState<string>('');
  const departmentApi = useDepartmentApi();
  const apiStatus = departmentApi.state.department.apiStatus;

  /**
   * Handles the click event on the delete button of remove user dialog.
   */
  const onDeleteClick = () => {
    // TODO: Change this when new API is implemented
    setOpenDeleteDialog(false);
    if (selectedMember) {
      const request = { ...props.department };
      request.members = request.members?.filter(member => member.loginId !== selectedMember.loginId);
      departmentApi.updateDepartment(request);
      setSelectedMember(undefined);
    }
  }

  /**
   * Handles the click event on the remove member button.
   *
   * @param {User} member - The member to be removed from the department.
   */
  const onRemoveMemberClick = (member: User) => {
    setSelectedMember(member);
    setOpenDeleteDialog(true);
  }

  /**
   * Sort & Filters the department members based on search criteria.
   *
   * @returns {Department[]} - The sorted and filtered department list.
   */
  const getFilteredMemberList = () => {

    return (props.department.members || [])
      .filter((member) =>
        member.name.toLowerCase().includes(searchKey.toLowerCase())
        || member.loginId?.toLowerCase().includes(searchKey.toLowerCase())
      ).sort((a, b) => a.name.localeCompare(b.name));
  };

  const memberList = getFilteredMemberList();

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([UPDATE_DEPT], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        <SearchBar sx={styles.searchBar} placeholder={t('search')} handleChange={setSearchKey} />
        <ListItemButton sx={styles.addMemberLayout} onClick={() => setShowAddMemberPanel(true)}>
          <Box sx={styles.addMemberBtn}><UserAddIcon /></Box>
          <Typography variant='p1' sx={styles.addMemberText}>{t('addMembers')}</Typography>
        </ListItemButton>
        {memberList.map((member, index) => (
          <ListItemAddRemoveCard
            key={`department-member-${index}`}
            title={member.name}
            subTitle={member.position}
            isExistingItem={true}
            onActionClick={() => onRemoveMemberClick(member)}
          />
        ))}
      </Box>
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('removeDeptUser')}
        titleIcon={<BinIcon />}
        message={t('removeDeptUserMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('ok')}
        onPrimaryAction={onDeleteClick}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <DepartmentAddMemberPanel
        open={showAddMemberPanel}
        department={props.department}
        onClose={() => setShowAddMemberPanel(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([UPDATE_DEPT], apiStatus)} />
    </Box>
  );
};

export default MembersTab;