import Dimens from '../../../../../theme/dimens';
import useCommonStyles from '../../../../../theme/use-common-styles';

/**
 * Custom hook to generate styles for the MessageInfoHeader component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const commonStyles = useCommonStyles();

  const styles = {
    titleLayout: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      pb: Dimens.spacing.base,
      '& svg': {
        ...commonStyles.smIcon
      }
    },
    title: {
      fontWeight: Dimens.fontWeight.semiBold,
      px: Dimens.spacing.base,
      flex: 1
    }
  };

  return styles;
};

export default useStyles;
