import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the MembersTab component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xs, base, lg, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      p: `${section} ${section} 0 ${section}`,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        p: `${lg} ${lg} 0 ${lg}`
      }
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto'
    },
    searchBar: {
      mb: lg
    },
    addMemberLayout: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      p: `${lg} 0`
    },
    addMemberBtn: {
      minWidth: Dimens.avatarSize.lg,
      minHeight: Dimens.avatarSize.lg,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      bgcolor: theme.palette.primary.main,
      borderRadius: Dimens.radius.xxl,
      mr: xs,
      '& svg': {
        ...commonStyles.smIcon,
        color: theme.palette.secondary.main
      }
    },
    addMemberText: {
      px: lg
    }
  };

  return styles;
};

export default useStyles;