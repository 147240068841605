import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PhoneIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3615_198)'>
          <path d='M11.0354 19.0276L14.1221 16.1063C15.1032 15.1693 22.5 17.4843 22.5 17.4843V22.5C19.7418 22.5029 17.0103 21.961 14.4621 20.9054C11.9139 19.8498 9.59932 18.3013 7.65119 16.3488C5.69869 14.4007 4.1502 12.0861 3.09461 9.53792C2.03902 6.98974 1.49712 4.25816 1.50001 1.5H6.5378C6.5378 1.5 8.85276 8.89685 7.91576 9.87795L4.97245 12.9646' stroke='currentColor' strokeWidth='2.5' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3615_198'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default PhoneIcon;