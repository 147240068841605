import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

const useStyles = () => {

  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      bgcolor: theme.palette.background.paper,
    },
    sortFilterWrapper: {
      display: 'flex',
      flexDirection: 'row',
      px: Dimens.spacing.section,
      justifyContent: 'space-between'
    }
  };

  return styles;
};

export default useStyles;
