import Dimens from '../../../../../theme/dimens';
import useCommonStyles from '../../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the ForwardRecipientCard component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xs, lg } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    content: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      p: lg,
      '&:hover': {
        '& .MuiIconButton-root': {
          display: 'flex'
        }
      }
    },
    avatar: {
      width: Dimens.avatarSize.lg,
      height: Dimens.avatarSize.lg,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
      mr: xs
    },
    contentRight: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& svg': {
        fontSize: Dimens.icon.sm
      }
    },
    contentText: {
      flexDirection: 'column',
      display: 'flex',
      px: lg
    },
    primaryText: {
      ...commonStyles.textEllipsis(1),
      color: theme.palette.text.primary
    }
  };

  return styles;
};

export default useStyles;
