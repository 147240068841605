import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MediaIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5470_62029)'>
          <path d='M5.66667 10.3333H3.33333C2.71449 10.3333 2.121 10.0875 1.68342 9.64992C1.24583 9.21233 1 8.61884 1 8V3.33333C1 2.71449 1.24583 2.121 1.68342 1.68342C2.121 1.24583 2.71449 1 3.33333 1H8C8.61884 1 9.21233 1.24583 9.64992 1.68342C10.0875 2.121 10.3333 2.71449 10.3333 3.33333V5.66667' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M5.6665 8.00008C5.6665 7.38124 5.91234 6.78775 6.34992 6.35017C6.78751 5.91258 7.381 5.66675 7.99984 5.66675H12.6665C13.2853 5.66675 13.8788 5.91258 14.3164 6.35017C14.754 6.78775 14.9998 7.38124 14.9998 8.00008V12.6667C14.9998 13.2856 14.754 13.8791 14.3164 14.3167C13.8788 14.7542 13.2853 15.0001 12.6665 15.0001H7.99984C7.381 15.0001 6.78751 14.7542 6.34992 14.3167C5.91234 13.8791 5.6665 13.2856 5.6665 12.6667V8.00008Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1 7.99997L2.78578 6.2142C3.1373 5.86273 3.61403 5.66528 4.11111 5.66528C4.6082 5.66528 5.08493 5.86273 5.43644 6.2142L5.66667 6.44442' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M9.55566 9.16675V11.5001L11.5001 10.3334L9.55566 9.16675Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4.11133 3.33325V3.34103' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_5470_62029'>
            <rect width='16' height='16' fill='transparent' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default MediaIcon;