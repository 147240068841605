import { Box, Typography } from '@mui/material';

import React, { } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  message?: string;
}

const NoDataFound: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Typography variant='p1' sx={styles.message}>
          {props.message ?? t('noDataFound')}
        </Typography>
    </Box>
  );
};

export default NoDataFound;
