import { DATE_PATTERN_DD_MM_YYYY, PWD_MIN_LENGTH, REGEX_PATTERN_LOWER_CASE, REGEX_PATTERN_NUMBER, REGEX_PATTERN_PWD_SPECIAL_CHAR, REGEX_PATTERN_UPPER_CASE } from './constants';
import { format, isThisYear, isToday, isYesterday } from 'date-fns';

import APIStatus from '../types/api-status';
import ValidationItem from '../types/ui/validation-item';
import { t } from 'i18next';

/**
 * Utility class for various helper functions.
 */
export default class Util {

  /**
   * Converts a UTC time string to a local time Date object.
   * 
   * @param {string} utcTimeString The UTC time string to convert.
   * @returns {Date} The converted local time Date object.
   */
  static UTCtoLocalTime(utcTimeString: string): Date {
    const utcDate = new Date(utcTimeString);
    const localTime = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

    return localTime;
  }

  /**
   * Formats a UTC date object into a human-readable string using a specified pattern.
   * 
   * This function likely utilizes an external library for date formatting (date-fns).
   *
   * @param {Date} utcDate - The UTC date object to be formatted.
   * @param {string} pattern - The desired format pattern for the output string (e.g., "DD-MM-YYYY", "YYYY-MM-DD hh:mm").
   * @returns {string} - The formatted date string based on the provided pattern, representing the local time equivalent of the UTC date.
   */
  static formatUTCtoLocal(utcDate?: Date | string, pattern?: string): string {
    let formattedDate = '';
    if (utcDate) {
      formattedDate = format(utcDate, pattern ?? DATE_PATTERN_DD_MM_YYYY);
    }

    return formattedDate;
  }

  /**
   * Checks password validation status against different criteria.
   *
   * This static function takes a password string and returns an array of `ValidationItem` objects.
   * Each `ValidationItem` object has a `label` (translated message) and an `isValid` flag
   * indicating if the password meets the corresponding criteria. The criteria include:
   *  - Minimum length (defined by `PWD_MIN_LENGTH`)
   *  - Lowercase character presence (using `REGEX_PATTERN_LOWER_CASE`)
   *  - Uppercase character presence (using `REGEX_PATTERN_UPPER_CASE`)
   *  - Number presence (using `REGEX_PATTERN_NUMBER`)
   *  - Special character presence (using `REGEX_PATTERN_PWD_SPECIAL_CHAR`)
   *
   * This function can be used to display password validation feedback to the user
   * based on the returned `ValidationItem` objects.
   *
   * @param {string} password - The password string to validate.
   * @returns {Array<ValidationItem>} - An array of objects with validation status details.
   */
  static getPasswordValidationStatus(password: string): Array<ValidationItem> {

    return [
      {
        label: t('pwdMinLength'),
        isValid: password.length >= PWD_MIN_LENGTH
      },
      {
        label: t('pwdLowerCase'),
        isValid: REGEX_PATTERN_LOWER_CASE.test(password)
      },
      {
        label: t('pwdUpperCase'),
        isValid: REGEX_PATTERN_UPPER_CASE.test(password)
      },
      {
        label: t('pwdNumber'),
        isValid: REGEX_PATTERN_NUMBER.test(password)
      },
      {
        label: t('pwdSpecialChar'),
        isValid: REGEX_PATTERN_PWD_SPECIAL_CHAR.test(password)
      }
    ];
  }

  /**
   * Generates a random client ID string.
   *
   * This function uses the window.crypto API to generate a cryptographically
   * secure random byte array. It then converts the byte array to a hex string
   * and a base64 encoded string. The base64 encoded string is returned as the
   * client ID.
   *
   * @returns {string} - The generated random client ID string.
   */
  public static generateClientId(): string {

    const randomBytes = new Uint8Array(128);
    window.crypto.getRandomValues(randomBytes);
    const hexString: Array<string> = [];
    randomBytes.forEach((b) => hexString.push(b.toString(16).padStart(2, '0')));
    const clientId = btoa(String.fromCharCode(...randomBytes));

    return clientId;
  }

  /**
   * Checks if an array is empty.
   *
   * This function takes an array as input and returns true if the array is either null or undefined,
   * or if the array has a length of zero (meaning it contains no elements).
   *
   * @param {Array<any>} array - The array to check for emptiness.
   * @returns {boolean} - True if the array is empty, false otherwise.
   */
  public static isArrayEmpty(array?: Array<any>): boolean { /* eslint-disable-line */

    return (!array || array.length === 0);
  }

  /**
   * getInitialsFromName function
   * 
   * This function extracts initials from a given name string.
   * 
   * @param {string} name - The full name string.
   * @param {number} charCount (optional) - The desired number of initials (defaults to 2).
   * @returns {string} - The extracted initials in uppercase format.
   */
  public static getInitialsFromName(name: string, charCount = 2): string {
    // Handle empty or single-word names
    if (!name || name.trim() === '') {
      return '';
    }

    const words = name.trim().split(' ');
    let initials = '';

    // Extract initials based on charCount
    for (let i = 0; i < Math.min(words.length, charCount); i++) {
      initials += words[i].charAt(0).toUpperCase();
    }

    return initials.toUpperCase();
  }

  /**
   * Converts a string to PascalCase (UpperCamelCase).
   * This function converts a string with spaces into PascalCase format by capitalizing the first letter of each word.
   * @param {string} str - The input string.
   * @returns {string} The converted string in PascalCase format.
   */
  public static toPascalCase(str: string): string {

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
   * Extracts the error message for a specific API task from the API status.
   *
   * @param {Array<string>} taskList - The list of task Ids.
   * @param {APIStatus | undefined} apiStatus - The API status object.
   * 
   * @returns {string | undefined} - The error message if the task is in error state, otherwise undefined.
   */
  public static getApiError(taskList: Array<string>, apiStatus: APIStatus | undefined): string | undefined {

    return taskList.includes(apiStatus?.task ?? '') ? apiStatus?.error : undefined;
  }

  /**
   * Checks if a specific API task is currently loading.
   *
   * @param {Array<string>} taskList - The list of task Ids.
   * @param {APIStatus | undefined} apiStatus - The API status object.
   * 
   * @returns {boolean} - True if the task is loading, false otherwise.
   */
  public static isApiLoading(taskList: Array<string>, apiStatus: APIStatus | undefined): boolean {

    return Boolean(taskList.includes(apiStatus?.task ?? '') && apiStatus?.isLoading);
  }

  /**
   * Formats a number with abbreviations for large values (K, M, B).
   *
   * @param {number} num - The number to be formatted.
   * 
   * @returns {string} The formatted number string.
   */
  public static formatNumber(num: number): string {
    let formattedNum = num.toString();
    if (num >= 1_000_000_000) {
      formattedNum = Math.floor(num / 1_000_000_000) + 'B';
    } else if (num >= 1_000_000) {
      formattedNum = Math.floor(num / 1_000_000) + 'M';
    } else if (num >= 1_000) {
      formattedNum = Math.floor(num / 1_000) + 'K';
    }

    return formattedNum;
  }

}