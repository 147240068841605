import {
  API_FAILURE,
  ApiFailureAction,
  CHALLENGE_SUCCESS,
  ChallengeSuccessAction,
  INIT_API,
  InitApiAction,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SignInSuccessAction,
  SignOutSuccessAction
} from './action-types';

import AppError from '../../exception/app-error';
import Challenge from '../../types/challenge';
import SignInResponse from '../../types/sign-in-response';

/**
 * Creates an action object to indicate the initiation of an API call.
 *
 * This function returns an action object with the type `INIT_API`.
 *
 * @returns {InitApiAction} - The action object for API call initiation.
 */
export const initApi = (): InitApiAction => ({
  type: INIT_API
});

/**
 * Creates an action object to indicate successful challenge completion.
 *
 * This function takes an array of `Challenge` objects representing the completed challenges
 * and returns an action object with the type `CHALLENGE_SUCCESS` and the challenge list
 * as the payload.
 *
 * @param {Array<Challenge>} challengeList - The list of completed email challenges.
 * @returns {EmailChallengeSuccessAction} - The action object for successful challenge completion.
 */
export const challengeSuccess = (challengeList: Array<Challenge>): ChallengeSuccessAction => ({
  type: CHALLENGE_SUCCESS,
  payload: challengeList
});

/**
 * Creates an action object to indicate successful sign-in.
 *
 * This function takes a `SignInResponse` object containing the user's login information
 * and returns an action object with the type `SIGN_IN_SUCCESS` and the login information
 * as the payload.
 *
 * @param {SignInResponse} loginInfo - The user's login information.
 * @returns {SignInSuccessAction} - The action object for successful sign-in.
 */
export const signInSuccess = (loginInfo: SignInResponse): SignInSuccessAction => ({
  type: SIGN_IN_SUCCESS,
  payload: loginInfo
});

/**
 * Creates an action object to indicate successful sign-out.
 *
 * This function returns an action object with the type `SIGN_OUT_SUCCESS` and
 * a payload of `true`.
 *
 * @returns {SignOutSuccessAction} - The action object for successful sign-out.
 */
export const signOutSuccess = (): SignOutSuccessAction => ({
  type: SIGN_OUT_SUCCESS,
  payload: true
});

/**
 * Creates an action object to indicate an API call failure with an error message.
 *
 * This function takes an error message as input and returns an action object
 * with the type `API_FAILURE` and the error message in the payload.
 *
 * @param {AppError} error - The error message encountered during the API call.
 * @returns {ApiFailureAction} - The action object for API call failure.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
  type: API_FAILURE,
  payload: error
});