import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SIGN_IN_INVITE, SIGN_IN_PASSWORD } from '../../../utils/constants';

import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../../components/CustomButton';
import React from 'react';
import SignInOptionsImageDark from '../../../assets/sign_in_options_dark.svg';
import SignInOptionsImageLight from '../../../assets/sign_in_options_light.svg';
import ThemeUtil from '../../../theme/theme-util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining the properties accepted by the SignInOptionDialog component.
 * These properties allow for customization of the dialog's appearance and behavior.
 */
interface Props extends DialogProps {
	onSignInOptionSelected: (option: string) => void;
	onClose: () => void;
}

/**
 * SignInOptionDialog component
 * 
 * It renders a dialog prompting the user to choose a sign-in option.
 * It displays an image, title, message, and two buttons for password or email link sign-in.
 */
const SignInOptionDialog: React.FC<Props> = (props: Props) => {

	const {
		onSignInOptionSelected,
		onClose,
		...rest
	} = props;
	const styles = useStyles();
	const theme = useTheme();
	const isLightTheme = ThemeUtil.isLightTheme(theme);
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const { t } = useTranslation();

	return (
		<Dialog {...rest} sx={styles.dialog}>
			<Stack sx={styles.header}>
				<IconButton onClick={onClose} >
					<CloseIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<DialogContent>
				<Box component='img' alt='logo' src={isLightTheme ? SignInOptionsImageLight : SignInOptionsImageDark} sx={styles.headerImage} />
				<Typography variant={'h2'} sx={styles.title}>{isMobile ? t('signIn') : t('signInOptionTitle')}</Typography>
				<Typography variant='p1' sx={styles.message}>{t('signInOptionMsg')}</Typography>
			</DialogContent>
			<DialogActions>
				<CustomButton
					title={t('password')}
					color='secondary'
					onClick={() => onSignInOptionSelected(SIGN_IN_PASSWORD)}
				/>
				<CustomButton
					title={t('emailLink')}
					color='secondary'
					onClick={() => onSignInOptionSelected(SIGN_IN_INVITE)}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default SignInOptionDialog;