import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the CreateGroupCard component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	const { lg, xl } = Dimens.spacing;

	const styles = {
		...commonStyles,
		card: {
			borderRadius: Dimens.radius.lg,
			boxShadow: 'none',
			height: Dimens.directoryCardMaxHeight,
			display: 'flex',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			backgroundImage: 'none'
		},
		cardContent: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			'&.MuiCardContent-root': {
				p: xl
			}
		},
		actionBtn: {
			height: Dimens.avatarSize.xxl,
			width: Dimens.avatarSize.xxl,
			bgcolor: theme.palette.background.default,
			p: 0,
			'& svg': {
				fontSize: Dimens.icon.lg
			}
		},
		title: {
			...commonStyles.textEllipsis(1),
			color: theme.palette.text.primary,
			fontWeight: Dimens.fontWeight.medium,
			mt: lg
		}
	};
	
	return styles;
};

export default useStyles;