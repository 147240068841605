import { AttachmentType, ConversationSubMenu, DepartmentSubMenuItem, DirectoryRecordType, MessageOptions, MessagePriority, TAB_DEPT_EDIT_DETAIL, TAB_DEPT_EDIT_MEMBER, TAB_DEPT_EDIT_SETTINGS, TAB_GROUP_DETAIL, TAB_GROUP_MEMBER, TAB_GROUP_MEMBER_ADMIN, TAB_GROUP_MEMBER_ALL, TAB_GROUP_SETTING, TAB_USER_ACTIVE, TAB_USER_ALL, TAB_USER_DEACTIVATED, TAB_USER_EDIT_PROFILE, TAB_USER_EDIT_SETTINGS, TAB_USER_PENDING, UserListSubMenu, UserProfileStatus, UserTableHeader } from './constants';

import BinIcon from '../components/CustomIcons/BinIcon';
import CameraIcon from '../components/CustomIcons/CameraIcon';
import DeactivateIcon from '../components/CustomIcons/DeactivateIcon';
import FileIcon from '../components/CustomIcons/FileIcon';
import ForwardIcon from '../components/CustomIcons/ForwardIcon';
import HighPriorityIcon from '../components/CustomIcons/HighPriorityIcon';
import InfoCircleIcon from '../components/CustomIcons/InfoCircleIcon';
import MenuData from '../types/ui/menu-data';
import NormalPriorityIcon from '../components/CustomIcons/NormalPriorityIcon';
import PencilIcon from '../components/CustomIcons/PencilIcon';
import PhotoIcon from '../components/CustomIcons/PhotoIcon';
import React from 'react';
import ReloadIcon from '../components/CustomIcons/ReloadIcon';
import TabData from '../types/ui/tab-data';
import UnlockIcon from '../components/CustomIcons/UnlockIcon';
import { User } from '../types/user';
import VideoIcon from '../components/CustomIcons/VideoIcon';
import { t } from 'i18next';

/**
 * ui-constants.tsx
 * 
 * This file is used to define UI-related constants which may also include JSX components.
 * If the constants doesn't have any JSX dependency, define that in constants.ts.
 * It helps in centralizing and organizing constants used throughout the UI, such as message
 * priorities with their associated properties and icons.
 */

export const MESSAGE_PRIORITY_LIST: Array<MenuData> = [
  {
    id: MessagePriority.Urgent,
    value: t('urgent'),
    isDestructive: true,
    icon: <HighPriorityIcon />
  },
  {
    id: MessagePriority.Normal,
    value: t('normal'),
    icon: <NormalPriorityIcon />
  }
];

export const ATTACHMENT_TYPE_LIST: Array<MenuData> = [
  {
    id: AttachmentType.Camera,
    value: t('camera'),
    icon: <CameraIcon />
  },
  {
    id: AttachmentType.Photo,
    value: t('photo'),
    icon: <PhotoIcon />
  },
  {
    id: AttachmentType.Video,
    value: t('video'),
    icon: <VideoIcon />
  },
  {
    id: AttachmentType.Document,
    value: t('document'),
    icon: <FileIcon />
  }
];

export const CONVERSATION_SUB_MENU: Array<MenuData> = [
  {
    id: ConversationSubMenu.Mute,
    value: t('mute')
  }
];

export const DIRECTORY_TYPE_LIST: Array<MenuData> = [
  {
    id: DirectoryRecordType.Group,
    value: t('groups'),
    isChecked: true
  },
  {
    id: DirectoryRecordType.User,
    value: t('users'),
    isChecked: true
  }
];

export const GROUP_TAB_LIST = [
  {
    id: TAB_GROUP_DETAIL,
    label: t('details')
  },
  {
    id: TAB_GROUP_MEMBER,
    label: t('members')
  },
  // {
  //   id: TAB_GROUP_SETTING,
  //   label: t('settings')
  // }
];

export const GROUP_MEMBER_TAB_LIST = [
  {
    id: TAB_GROUP_MEMBER_ALL,
    label: t('all')
  },
  {
    id: TAB_GROUP_MEMBER_ADMIN,
    label: t('admins')
  }
];

/**
 * Generates the sub-menu options for a group member based on the user's admin status.
 *
 * @param {boolean} isAdmin - Indicates whether the current user is an admin of the group.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available sub-menu options.
 */
export const getGroupMemberSubMenu = (isAdmin: boolean): Array<MenuData> => ([
  {
    id: 'CHAT_ROLE',
    value: isAdmin ? t('dismissAdmin') : t('makeAdmin')
  }
]);

export const USER_EDIT_TAB_LIST = [
  {
    id: TAB_USER_EDIT_PROFILE,
    label: t('profile')
  },
  {
    id: TAB_USER_EDIT_SETTINGS,
    label: t('settings')
  }
];

/**
 * Generates a list of available actions for a message based on whether it's from the current user.
 *
 * @param {boolean} isCurrentUser - Indicates whether the message is from the current user.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available message actions.
 */
export const getMessageActionList = (isCurrentUser: boolean): Array<MenuData> => ([
  ...(isCurrentUser ? [{
    id: MessageOptions.Info,
    value: t('information'),
    icon: <InfoCircleIcon />
  }] : []),
  {
    id: MessageOptions.Forward,
    value: t('forward'),
    icon: <ForwardIcon />
  },
  // {
  //   id: MessageOptions.Reply,
  //   value: t('reply'),
  //   icon: <ReplyIcon />
  // }
]);

export const DEPT_SUB_MENU: Array<MenuData> = [
  {
    id: DepartmentSubMenuItem.Edit,
    value: t('edit'),
    icon: <PencilIcon />
  },
  {
    id: DepartmentSubMenuItem.Delete,
    value: t('delete'),
    isDestructive: true,
    icon: <BinIcon />
  }
];

export const DEPT_EDIT_TAB_LIST = [
  {
    id: TAB_DEPT_EDIT_DETAIL,
    label: t('details')
  },
  {
    id: TAB_DEPT_EDIT_MEMBER,
    label: t('members')
  },
  {
    id: TAB_DEPT_EDIT_SETTINGS,
    label: t('settings')
  }
];

export const USER_TAB_LIST: Array<TabData> = [
  {
    id: TAB_USER_ALL,
    label: t('all')
  },
  {
    id: TAB_USER_ACTIVE,
    label: t('active')
  },
  {
    id: TAB_USER_PENDING,
    label: t('pending')
  },
  {
    id: TAB_USER_DEACTIVATED,
    label: t('deactivated')
  }
];

/**
 * Generates the table headers for the user list, including visibility based on screen size and selected tab.
 *
 * @param {boolean} isDesktop - Indicates whether the screen size is desktop or smaller.
 * @param {string} tab - The currently selected tab.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the table headers.
 */
export const getUserTableHeaders = (isDesktop: boolean, tab: string): Array<MenuData> => ([
  {
    id: UserTableHeader.UserType,
    value: t('userType'),
    isChecked: true
  },
  {
    id: UserTableHeader.Department,
    value: t('department'),
    isChecked: isDesktop
  },
  tab === TAB_USER_PENDING ? {
    id: UserTableHeader.Invited,
    value: t('invited'),
    isChecked: isDesktop
  } : {
    id: UserTableHeader.Position,
    value: t('position'),
    isChecked: isDesktop
  }
]);

/**
 * Generates the sub-menu options for a user based on their profile status.
 *
 * @param {User} user - The user object for whom to generate sub-menu options.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available sub-menu options.
 */
export const getUserListSubMenu = (user: User): Array<MenuData> => {
  const menuList: Array<MenuData> = [];
  
  const addItem = (id: UserListSubMenu, valueKey: string, icon: JSX.Element, isDestructive = false) => {
    menuList.push({ id, value: t(valueKey), icon, isDestructive });
  };
  if (user.status === UserProfileStatus.Pending) {
    addItem(UserListSubMenu.ResendInvite, 'resendInvite', <ReloadIcon />);
    addItem(UserListSubMenu.Delete, 'delete', <BinIcon />, true);
  } else {
    addItem(UserListSubMenu.Edit, 'edit', <PencilIcon />);
    if (user.locked) {
      addItem(UserListSubMenu.Unlock, 'unlock', <UnlockIcon />);
    }
    if (user.status === UserProfileStatus.Active) {
      addItem(UserListSubMenu.Deactivate, 'deactivate', <DeactivateIcon />, true);
    } else if (user.status === UserProfileStatus.Deactivated) {
      addItem(UserListSubMenu.Reactivate, 'reactivate', <ReloadIcon />);
      addItem(UserListSubMenu.Delete, 'delete', <BinIcon />, true);
    }
  }

  return menuList;
};