export const INIT_REGISTRATION = 'INIT_REGISTRATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export interface InitRegistrationAction {
  type: typeof INIT_REGISTRATION;
}

export interface RegistrationSuccessAction {
  type: typeof REGISTRATION_SUCCESS;
}

export interface RegistrationFailureAction {
  type: typeof REGISTRATION_FAILURE;
  payload: string;
}

export type SignUpActionTypes = InitRegistrationAction | RegistrationSuccessAction | RegistrationFailureAction;