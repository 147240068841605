import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CAROUSEL_DEFAULT_INTERVAL } from '../../utils/constants';
import useStyles from './styles';

interface Props {
  children: React.ReactNode[];
  interval?: number;
}

/**
 * Carousel Component
 *
 * This component renders a basic carousel that automatically transitions between child elements.
 * It also includes manual navigation buttons for each child element.
 *
 * @props { Props } - The component properties.
 * @property { React.ReactNode[] } children - An array of React elements to display as slides in the carousel.
 * @property { number } interval - The time interval (in milliseconds) between automatic slide transitions (defaults to a constant value).
 *
 * @returns {JSX.Element} - The rendered JSX element for the carousel component.
 */
const Carousel: React.FC<Props> = ({ children, interval = CAROUSEL_DEFAULT_INTERVAL }) => {

  const styles = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatic slide transition using useEffect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevIndex) => (prevIndex + 1) % children.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [interval, children.length]);

  return (
    <Box>
      <Box sx={styles.slideAnimate}>
        {children[currentSlide]}
      </Box>
      <Stack sx={styles.indicatorWrapper}>
        {children.map((_child, index) => (
          <Button
            key={index}
            variant={'contained'}
            color={currentSlide === index ? 'primary' : 'secondary'}
            sx={styles.indicator}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Carousel;
