import {
	CREATE_SITE,
	CreateSiteAction,
	DELETE_SITE,
	DeleteSiteAction,
	FETCH_SITE_DETAIL,
	FETCH_SITE_LIST,
	FetchSiteDetailAction,
	FetchSiteListAction,
	RESET_ERROR_STATE,
	RESET_SITE_STATE,
	ResetErrorStateAction,
	SITE_FAILURE,
	SITE_LIST_SUCCESS,
	SiteFailureAction,
	SiteListSuccessAction,
	SiteStateResetAction,
	UPDATE_SITE,
	UpdateSiteAction
} from './action-types';

import AppError from '../../exception/app-error';
import Site from '../../types/site';

// Action Creators

/**
 * Reset the site error state.
 *
 * @returns {ResetErrorStateAction} An action to reset the site error state.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Initiates fetching the site list.
 *
 * @returns {FetchSiteListAction} An action to indicate fetching the site list.
 */
export const initSiteListFetch = (): FetchSiteListAction => ({
	type: FETCH_SITE_LIST
});

/**
 * Dispatched on successful retrieval of site list.
 *
 * @param {Array<Site>} siteList - The list of fetched sites.
 * @returns {SiteListSuccessAction} An action to store the retrieved site list.
 */
export const siteListSuccess = (siteList: Array<Site>): SiteListSuccessAction => ({
	type: SITE_LIST_SUCCESS,
	payload: siteList
});

/**
 * Initiates fetching site details.
 *
 * @returns {FetchSiteDetailAction} An action to indicate fetching site details.
 */
export const initSiteDetailFetch = (): FetchSiteDetailAction => ({
	type: FETCH_SITE_DETAIL
});

/**
 * Initiates creating a new site.
 *
 * @returns {CreateSiteAction} An action to indicate creating a new site.
 */
export const initSiteCreate = (): CreateSiteAction => ({
	type: CREATE_SITE
});

/**
 * Initiates updating an existing site.
 *
 * @returns {UpdateSiteAction} An action to indicate updating a site.
 */
export const initSiteUpdate = (): UpdateSiteAction => ({
	type: UPDATE_SITE
});

/**
 * Initiates deleting a site.
 *
 * @returns {DeleteSiteAction} An action to indicate deleting a site.
 */
export const initSiteDelete = (): DeleteSiteAction => ({
	type: DELETE_SITE
});

/**
 * Dispatched on site data fetching/creation/update/deletion failure.
 *
 * @param {AppError} error - The error object.
 * @returns {SiteFailureAction} An action to indicate site data operation failure.
 */
export const siteFailure = (error: AppError): SiteFailureAction => ({
	type: SITE_FAILURE,
	payload: error
});

/**
 * Resets the site state to its initial state.
 *
 * @returns {SiteStateResetAction} An action to reset the site state.
 */
export const resetSiteState = (): SiteStateResetAction => ({
	type: RESET_SITE_STATE
});