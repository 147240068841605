import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UnlockIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6909_11821)'>
          <path d='M5.9894 5C5.9894 3.61875 7.18806 2.5 8.66797 2.5C9.73605 2.5 10.6568 3.08125 11.0887 3.92812C11.3432 4.42812 11.9827 4.64062 12.5151 4.40313C13.0474 4.16563 13.2785 3.56875 13.024 3.07188C12.2506 1.55313 10.5932 0.5 8.66797 0.5C6.00614 0.5 3.84654 2.51562 3.84654 5V6.5H3.31083C2.12891 6.5 1.16797 7.39687 1.16797 8.5V14.5C1.16797 15.6031 2.12891 16.5 3.31083 16.5H14.0251C15.207 16.5 16.168 15.6031 16.168 14.5V8.5C16.168 7.39687 15.207 6.5 14.0251 6.5H5.9894V5Z' fill='currentColor' />
        </g>
        <defs>
          <clipPath id='clip0_6909_11821'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default UnlockIcon;