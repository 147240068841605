import { Box, InputAdornment, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import ChallengeData from '../../types/challenge-data';
import CheckFilledIcon from '../../components/CustomIcons/CheckFilledIcon';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import PwdImageDark from '../../assets/sign_in_password_dark.svg';
import PwdImageLight from '../../assets/sign_in_password_light.svg';
import SignInLayout from '../../layouts/SignInLayout';
import SignInRequest from '../../types/sign-in-request';
import ThemeUtil from '../../theme/theme-util';
import { signInEmailSchema } from '../../utils/validation-schema';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * ForgotPasswordScreen component for handling password reset.
 * 
 * This component displays a form for entering the user's email address and submits
 * a password reset request upon form submission.
 * 
 * @returns {JSX.Element} - The rendered ForgotPasswordScreen component.
 */
const ForgotPasswordScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const challengeData: ChallengeData = location.state;
  const signInApi = useSignInApi();
  const initialValues: SignInRequest = {
    loginId: challengeData.loginId,
    clientId: ''
  };

  useEffect(() => {
    if (!challengeData) {
      // If the user tries to access this page directly, redirect him to sign-in page.
      navigate(APP_NAV.SIGN_IN);
    }
  }, []);

  /**
   * Handles form submission for password reset.
   * 
   * Triggers the email validation API call with the provided login ID.
   * 
   * @param {SignInRequest} value - The form values containing the login ID.
   */
  const onSubmit = (value: SignInRequest) => {
    signInApi.performEmailValidation(value);
  }

  return (
    <SignInLayout
      error={signInApi.state.apiStatus?.error}
      showProgressBar={signInApi.state.apiStatus?.isLoading}
      headerTitle={t('resetPwd')}
      headerDesc={t('resetPwdMsg')}
      hideFooter={true}
      image={isLightTheme ? PwdImageLight : PwdImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={signInEmailSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => (
          <Form>
            <Field
              name='loginId'
              placeholder={t('emailAddress')}
              component={CustomInput}
              endAdornment={(dirty && isValid) &&
                <InputAdornment position='end'>
                  <CheckFilledIcon sx={styles.icon} />
                </InputAdornment>
              } />
            <Box sx={styles.spacer} />
            <CustomButton
              type='submit'
              title={t('resetPwd')}
              color='primary'
              disabled={!isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
    </SignInLayout>
  );
};

export default ForgotPasswordScreen;
