import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuProps,
  Typography,
} from '@mui/material';

import MenuData from '../../types/ui/menu-data';
import React from 'react';
import useStyles from './styles';

/**
 * Props interface extending from MenuProps
 * @interface
 */
interface Props extends MenuProps {
  onMenuItemSelected: (item: MenuData) => void;
  menuList: Array<MenuData>;
}

const MenuCheckboxDialog: React.FC<Props> = (props: Props) => {

  const { menuList, onMenuItemSelected, ...rest } = props;
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, option: MenuData, checked: boolean) => {
    // TODO:
    option.isChecked = checked;
    onMenuItemSelected(option);
  }

  return (
    <Menu
      {...rest}
      id='menu-appbar'
      slotProps={{ paper: { sx: styles.menuPaperProps } }}
      MenuListProps={{ sx: styles.menu }}
    >
      <FormGroup sx={styles.formGroup}>
        {menuList.flatMap((option, index) => [
          <FormControlLabel
            key={`checkbox-${index}`}
            label={<Typography variant='p1'>{option.value}</Typography>}
            control={
              <Checkbox checked={option.isChecked} onChange={(e, checked) => handleChange(e, option, checked)} />
            }
          />,
          index !== menuList.length - 1 && (
            <Divider key={`divider-${index}`} sx={styles.divider} />
          ),
        ])}
      </FormGroup>
    </Menu>
  );
};

export default MenuCheckboxDialog;
