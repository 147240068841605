import { SIGN_UP_PAGE_LIST, SignUpChallengeType } from '../utils/constants';
import { doPost, getUrl } from '../service';
import { useLocation, useNavigate } from 'react-router-dom';

import APIConfig from '../service/api-config';
import APP_NAV from '../routes/app-nav';
import Challenge from '../types/challenge';
import ChallengePayload from '../types/challenge-payload';
import { EventSourcePolyfill } from 'event-source-polyfill';
import LoginUtil from '../utils/login-util';
import SignUpChallengeResponse from '../types/sign-up-challenge-response';
import SignUpEvent from '../types/sign-up-event';
import SignUpUtil from '../utils/sign-up-util';
import Util from '../utils/util';
import { useEffect } from 'react';

/**
 * useAppSignUpControl hook
 *
 * Manages the sign-up process, including subscribing to the registration event stream,
 * handling incoming challenges, and navigating to appropriate sign-up pages based on challenges.
 * 
 * @param {boolean} isInitSignUpDirectly - Flag indicating if the sign-up process should be initiated directly.
 * @returns {object} - An object containing the `initSignUpProcess` function.
 */
export function useAppSignUpControl(isInitSignUpDirectly: boolean) {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const signUpInfo = SignUpUtil.getBearerToken();

  /**
   * Initializes the sign-up process when the component mounts and when `isInitSignUpDirectly` is true.
   */
  useEffect(() => {
    if (signUpInfo && isInitSignUpDirectly) {
      initSignUpProcess();
    }
  }, []);

  /**
   * Cleans up the sign-up state when navigating away from sign-up pages.
   */
  useEffect(() => {
    if (signUpInfo && !SIGN_UP_PAGE_LIST.includes(pathname)) {
      SignUpUtil.clearAll();
    }
  }, [pathname]);

  /**
   * initSignUpProcess function
   *
   * Initializes the sign-up process by subscribing to the account registration event stream.
   */
  const initSignUpProcess = () => {
    subscribeToAccountRegistration();
  }

  /**
   * subscribeToAccountRegistration function
   *
   * Subscribes to the account registration topic and then subscribes to events endpoint.
   */
  const subscribeToAccountRegistration = async () => {
    try {
      await doPost(APIConfig.subscribeRegistration, undefined);
      addEventListener();
    } catch (error: any) { /* eslint-disable-line */
      // TODO:
    }
  }

  /**
   * addEventListener function
   *
   * Handles the event listener for the EventSource instance.
   * Manages incoming events, parses responses, and handles navigation based on challenges.
   */
  const addEventListener = () => {
    const accessToken = SignUpUtil.getAccessTokenData()?.token;
    if (accessToken) {
      const eventSource = new EventSourcePolyfill(getUrl(APIConfig.events), {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      eventSource.onmessage = (e) => {
        if (SignUpUtil.getBearerToken()) {
          const response: SignUpChallengeResponse = JSON.parse(e.data);
          ackEvent(response.event);
          if (response.bearer_tokens && response.bearer_tokens.length > 0) {
            LoginUtil.saveLoginInfo(response);
            navigate(APP_NAV.INBOX);
          } else {
            manageControl(response.control, response.payload);
          }
        } else {
          eventSource.close();
        }
      };
      eventSource.onerror = (e) => {
        eventSource.close();
        setTimeout(addEventListener, 40000);
      }
    } else {
      // Do nothing as the sign up token is cleared.
    }
  }

  /**
   * ackEvent function
   *
   * Acknowledges a received event by sending an ACK request.
   *
   * @param {SignUpEvent} event - The event to acknowledge.
   */
  const ackEvent = async (event?: SignUpEvent) => {
    if (event && event._links?.ack.href) {
      try {
        await doPost(event._links?.ack.href, undefined);
      } catch (error: any) { /* eslint-disable-line */
        // Do nothing as event listener will disconnect and try to reconnect or close the connection.
      }
    }
  }

  /**
   * manageControl function
   *
   * Handles the received challenge and navigates to the corresponding sign-up page.
   *
   * @param {Array<Challenge>} control - Array of challenges.
   * @param {ChallengePayload} payload - Optional payload data.
   */
  const manageControl = (control: Array<Challenge>, payload?: ChallengePayload) => {
    if (!Util.isArrayEmpty(control)) {
      const challenge = control[0];
      switch (challenge.type) {
        case SignUpChallengeType.SetPassword:
          if (pathname !== APP_NAV.SIGN_UP_PASSWORD) {
            navigate(APP_NAV.SIGN_UP_PASSWORD, {
              replace: true,
              state: {
                isValidSignUpRequest: true
              }
            });
          }
          break;
        case SignUpChallengeType.SetDepartment:
          if (pathname !== APP_NAV.SIGN_UP_DEPARTMENT && pathname !== APP_NAV.SIGN_UP_NEW_DEPARTMENT) {
            navigate(APP_NAV.SIGN_UP_DEPARTMENT, {
              replace: true,
              state: {
                isValidSignUpRequest: true,
                payload: payload
              }
            });
          }
          break;
        case SignUpChallengeType.SetProfile:
          if (pathname !== APP_NAV.SIGN_UP_PROFILE) {
            navigate(APP_NAV.SIGN_UP_PROFILE, {
              replace: true,
              state: {
                isValidSignUpRequest: true
              }
            });
          }
          break;
        default:
          break;
      }
    } else {
      // TODO:
    }
  };

  return {
    initSignUpProcess
  };

}