import {
	AppFeature,
	KEY_APP_CLIENT_ID,
	KEY_APP_LOGIN_STATUS,
	KEY_APP_USER_INFO,
	KEY_LAST_ACTIVE_TIME,
	KEY_SESSION_INFO,
	LOGIN_STORAGE_IV,
	LOGIN_STORAGE_SECRET,
	PASSWORD_IV,
	PASSWORD_SECRET
} from './constants';

import AppUserData from '../types/app-user-data';
import BearerToken from '../types/bearer-token';
import CryptoUtil from './crypto-util';
import SignInResponse from '../types/sign-in-response';
import Token from '../types/token';
import Util from './util';

/**
 * Utility class for handling login-related operations, including secure storage of login data.
 * This class uses AES encryption with a secret key and initialization vector (IV) to protect sensitive login information.
 */
export default class LoginUtil {
	/**
	 * Internal storage instance used for storing login data.
	 * (Private to prevent direct modification)
	 */
	private static storage: Storage;

	/**
	 * Retrieves the storage instance (either `localStorage` or a custom implementation).
	 * This method uses lazy initialization to improve performance.
	 * @private
	 */
	private static getStorage(): Storage {
		if (!this.storage) {
			this.storage = localStorage;
		}

		return this.storage;
	}

	/**
	 * Save an item in storage after encrypting its value.
	 * @param key The key to store the data under.
	 * @param value The value to store.
	 */
	private static setItem(key: string, value: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		const encryptedValue = CryptoUtil.encryptAES(value, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		this.getStorage().setItem(encryptedKey, encryptedValue);
	}

	/**
	 * Retrieves an item from storage after decrypting its value.
	 * @param key The key to retrieve the data under.
	 * @returns The decrypted value from storage, or null if not found.
	 */
	private static getItem(key: string): string | null {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		const encryptedValue = this.getStorage().getItem(encryptedKey);
		const decryptedValue = encryptedValue ? CryptoUtil.decryptAES(encryptedValue, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV) : null;

		return decryptedValue;
	}

	/**
	 * Removes an item from storage.
	 * @param key The key of the item to remove.
	 */
	private static removeItem(key: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, LOGIN_STORAGE_SECRET, LOGIN_STORAGE_IV);
		this.getStorage().removeItem(encryptedKey);
	}

	/**
	 * Clears all login details from storage.
	 */
	public static clearAll(): void {
		this.removeItem(KEY_SESSION_INFO);
		this.removeItem(KEY_APP_USER_INFO);
		this.removeItem(KEY_LAST_ACTIVE_TIME);
		this.removeItem(KEY_APP_CLIENT_ID);
		this.removeItem(KEY_APP_LOGIN_STATUS);
	}

	/**
	 * Encrypts the provided value using AES encryption with the configured secret and IV.
	 * @param value The value to encrypt.
	 * @returns The encrypted string.
	 */
	public static encryptPassword(value: string): string {
		return CryptoUtil.encryptAES(value, PASSWORD_SECRET, PASSWORD_IV);
	}

	/**
	 * Saves user login information to local storage.
	 *
	 * This function takes a `SignInResponse` object containing login data and bearer tokens.
	 * It extracts the user data (`loginInfo.data`) and the first bearer token (`loginInfo.bearer_tokens[0]`)
	 * and calls separate functions to save them in local storage.
	 *
	 * @param {SignInResponse} loginInfo - The login information object containing user data and tokens.
	 * @public
	 */
	public static saveLoginInfo(loginInfo: SignInResponse) {
		this.saveLoginStatus(true);
		this.saveAppUserInfo(loginInfo.data);
		loginInfo.bearer_tokens && this.saveBearerToken(loginInfo.bearer_tokens[0]);
	}

	/**
	 * Saves the login status to local storage.
	 *
	 * @param {boolean} isLoggedIn - Whether the user is logged in.
	 */
	private static saveLoginStatus(isLoggedIn: boolean) {
		this.setItem(KEY_APP_LOGIN_STATUS, JSON.stringify(isLoggedIn));
	}

	/**
	 * Retrieves the login status from local storage.
	 *
	 * @returns {boolean} - True if the user is logged in, false otherwise.
	 */
	public static isLoggedIn(): boolean {
		const status: string | null = this.getItem(KEY_APP_LOGIN_STATUS);
		const isLoggedIn: boolean = status ? JSON.parse(status) : false;

		return isLoggedIn;
	}

	/**
	 * Saves user information and permissions to local storage.
	 *
	 * This function takes an `AppUserData` object representing the user information and
	 * saves it to local storage under the key `KEY_APP_USER_INFO` after converting it to a JSON string.
	 *
	 * @param {AppUserData} appUserInfo - The user information data to be saved.
	 * @private
	 */
	private static saveAppUserInfo(appUserInfo: AppUserData) {
		this.setItem(KEY_APP_USER_INFO, JSON.stringify(appUserInfo));
	}

	/**
	 * Retrieves application user information from local storage.
	 *
	 * This function attempts to retrieve a JSON string from local storage under the key `KEY_APP_USER_INFO`.
	 * If the string exists, it parses it back into an `AppUserData` object using JSON.parse and returns it.
	 * Otherwise, it returns `undefined`.
	 *
	 * @returns {AppUserData | undefined} - The retrieved user information object or undefined if not found.
	 * @private
	 */
	private static getAppUserInfo(): AppUserData | undefined {
		const appUserInfoStr: string | null = this.getItem(KEY_APP_USER_INFO);
		const appUserInfo: AppUserData | undefined = appUserInfoStr ? JSON.parse(appUserInfoStr) : undefined;

		return appUserInfo;
	}

	/**
	 * Retrieves the user's full name from the application user data.
	 * 
	 * This method first calls `getAppUserInfo` (assumed to be implemented elsewhere)
	 * to retrieve the user data object. If the user data is available and contains
	 * a profile object with a name property, the name is extracted and returned.
	 * Otherwise, an empty string is returned.
	 *
	 * @returns {string} The user's full name if available, otherwise an empty string.
	 */
	public static getUserFullName(): string {
		let displayName = '';
		const appUserInfo: AppUserData | undefined = this.getAppUserInfo();
		if (appUserInfo && appUserInfo.profile && appUserInfo.profile.name) {
			displayName = appUserInfo.profile.name;
		}

		return displayName;
	}

	/**
	 * Gets the default department ID from the app user info.
	 * 
	 * This function retrieves the app user info from storage and extracts the department ID if available.
	 * If the user info or department ID is not found, an empty string is returned.
	 * 
	 * @returns {string} - The default department ID, or an empty string if not found.
	 */
	public static getDefaultDeptId(): string {
		let deptId = '';
		const appUserInfo: AppUserData | undefined = this.getAppUserInfo();
		if (appUserInfo && appUserInfo.profile && appUserInfo.profile.departmentId) {
			deptId = appUserInfo.profile.departmentId;
		}

		return deptId;
	}

	/**
	 * Retrieves the login ID of the current user from the application user data.
	 * 
	 * This method retrieves user information from login data. It checks if the retrieved
	 * user data has a property named `loginId` and returns it if found, otherwise returns
	 * an empty string.
	 *
	 * @returns {string} The login ID of the current user, or an empty string if not found.
	 */
	public static getLoginId(): string {
		let loginId = '';
		const appUserInfo: AppUserData | undefined = this.getAppUserInfo();
		if (appUserInfo && appUserInfo.loginId) {
			loginId = appUserInfo.loginId;
		}

		return loginId;
	}

	/**
	 * Retrieves the user's organization domain from local storage.
	 *
	 * This function first calls `getAppUserInfo` to retrieve the user information object.
	 * If the user information exists and has a `domain` property, it returns that domain.
	 * Otherwise, it returns an empty string.
	 *
	 * @returns {string} - The user's organization domain or an empty string if not found.
	 * @public
	 */
	public static getOrgDomain(): string {
		const appUserInfo: AppUserData | undefined = this.getAppUserInfo();
		const domain = (appUserInfo && appUserInfo.domain) ? appUserInfo.domain : '';

		return domain;
	}

	/**
	 * Saves the provided bearer token object in secure storage under the key KEY_SESSION_INFO.
	 * The token is first converted to a JSON string before storing.
	 * 
	 * @param {BearerToken} bearerToken - The bearer token object to store.
	 */
	private static saveBearerToken(bearerToken: BearerToken) {
		this.setItem(KEY_SESSION_INFO, JSON.stringify(bearerToken));
	}

	/**
	 * Retrieves the bearer token object from secure storage under the key KEY_SESSION_INFO.
	 * If a token is found, it's parsed from the JSON string back to a BearerToken object.
	 * Otherwise, it returns undefined.
	 *
	 * @returns {BearerToken | undefined} The retrieved bearer token object or undefined if not found.
	 */
	public static getBearerToken(): BearerToken | undefined {
		const bearerTokenStr: string | null = this.getItem(KEY_SESSION_INFO);
		const bearerToken: BearerToken | undefined = bearerTokenStr ? JSON.parse(bearerTokenStr) : undefined;

		return bearerToken;
	}

	/**
	 * Retrieves the access token data from the stored bearer token, if available.
	 * This method first retrieves the bearer token and then extracts the 'access_token' property
	 * from the bearer token object. If any step fails, it returns undefined.
	 *
	 * @returns {Token | undefined} The access token data from the bearer token or undefined if not found.
	 */
	public static getAccessTokenData(): Token | undefined {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const accessToken: Token | undefined = bearerToken ? bearerToken.access_token : undefined;

		return accessToken;
	}

	/**
	 * Retrieves the refresh token data from the stored bearer token, if available.
	 * This method first retrieves the bearer token and then extracts the 'refresh_token' property
	 * from the bearer token object. If any step fails, it returns undefined.
	 *
	 * @returns {Token | undefined} The refresh token data from the bearer token or undefined if not found.
	 */
	public static getRefreshTokenData(): Token | undefined {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const refreshToken: Token | undefined = bearerToken ? bearerToken.refresh_token : undefined;

		return refreshToken;
	}

	/**
	 * Utility function to save the last active time of the user.
	 *
	 * This function stores the provided `dateTime` as the user's last active time in local storage.
	 *
	 * @param {Date} dateTime - The timestamp representing the user's last active time.
	 */
	public static saveLastActiveTime(dateTime: Date) {
		this.setItem(KEY_LAST_ACTIVE_TIME, JSON.stringify(dateTime));
	}


	/**
	 * Utility function to retrieve the user's last active time.
	 *
	 * This function attempts to retrieve the last active time stored in local storage.
	 *
	 * @returns {Date | undefined} - The user's last active time as a Date object, or undefined if not found.
	 */
	public static getLastActiveTime(): Date | undefined {
		const lastActiveTimeStr: string | null = this.getItem(KEY_LAST_ACTIVE_TIME);
		const lastActiveTime: Date | undefined = lastActiveTimeStr ? JSON.parse(lastActiveTimeStr) : undefined;

		return lastActiveTime;
	}

	/**
	 * Saves the provided client ID to local storage.
	 *
	 * This function uses localStorage to store the client ID under the key `KEY_APP_CLIENT_ID`.
	 *
	 * @param {string} clientId - The client ID to be saved.
	 */
	private static saveClientId(clientId: string) {
		this.setItem(KEY_APP_CLIENT_ID, clientId);
	}

	/**
	 * Retrieves the client ID from local storage, generating a new one if not found.
	 *
	 * This function first tries to retrieve the client ID from localStorage under the key `KEY_APP_CLIENT_ID`.
	 * If no client ID is found, it generates a new one using `Util.generateClientId` and saves it to local storage.
	 * Finally, it returns the retrieved or generated client ID.
	 *
	 * @returns {string} - The client ID retrieved from local storage or a newly generated one.
	 */
	public static getClientId(): string {

		let clientId: string | null = this.getItem(KEY_APP_CLIENT_ID)
		if (clientId === null) {
			clientId = Util.generateClientId();
			this.saveClientId(clientId);
		}

		return clientId || '';
	}

	/**
   * Retrieves a list of features available to the current app user.
   *
   * @returns {Array<string>} An array of feature strings.
   */
	public static getAppFeatures(): Array<string> {
		let featureList: Array<string> = [];
		const appUserInfo: AppUserData | undefined = this.getAppUserInfo();
		if (appUserInfo && appUserInfo.features) {
			featureList = appUserInfo.features;
		}

		return featureList;
	}

	/**
   * Checks if the current app user has permission for the specified feature.
   *
   * @param {AppFeature} feature - The feature to check permission for.
	 * 
   * @returns {boolean} True if the user has permission, false otherwise.
   */
	public static hasPermission(feature: AppFeature): boolean {
		
		return this.getAppFeatures().includes(feature);
	}

}
