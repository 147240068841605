import { Box, Divider, TextField } from '@mui/material';
import React, { CSSProperties, ChangeEvent, useRef, useState } from 'react';

import useStyles from './styles';

interface OTPInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
}

/**
 * OTP (One-Time Password) input component for entering verification codes.
 *
 * This component renders a series of text fields with the specified length,
 * allowing users to input one-time password digits. It manages the input state,
 * handles user interactions like focusing the next field on input completion,
 * and validates input to only accept alphanumeric characters. It also handles
 * backspace, paste events, and focuses the first field automatically.
 *
 * @props {OTPInputProps} - Component properties defining the OTP input length,
 * current value, and onChange callback function.
 *
 * @returns {JSX.Element} - The rendered OTP input component.
 */
const OTPInput: React.FC<OTPInputProps> = ({ length, value, onChange }) => {

  const styles = useStyles();
  const [otpValues, setOtpValues] = useState<string[]>(Array(length).fill(''));
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const inputRefs = Array(length)
    .fill(null)
    .map(() => useRef<HTMLInputElement>(null));

  /**
 * Handles changes within each text field.
 * - Validates input to accept only alphanumeric characters (`^[0-9a-zA-Z]*$`).
 * - Updates the internal OTP value state (`otpValues`).
 * - Triggers the `onChange` callback with the combined OTP value.
 * - Focuses the next field if the current input reaches its limit (length 1).
 *
 * @param {number} index - Index of the input field that changed.
 * @param {ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - Change event object.
 */
  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    if (/^[0-9a-zA-Z]*$/.test(inputValue)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = inputValue[inputValue.length - 1] || '';
      setOtpValues(newOtpValues);
      onChange(newOtpValues.join(''));
      if (inputValue.length === 1 && index < length - 1 && inputRefs[index + 1]?.current) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  /**
   * Handles backspace key presses.
   * - If backspace is pressed on an empty field and it's not the first field,
   *   focuses the previous field.
   *
   * @param {number} index - Index of the input field where the key was pressed.
   * @param {React.KeyboardEvent<HTMLDivElement>} e - Keyboard event object.
   */
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace' && index > 0 && !otpValues[index]) {
      inputRefs[index - 1].current?.focus();
    }
  };

  /**
   * Handles paste events.
   * - Extracts pasted data and limits it to the OTP length.
   * - Updates the internal OTP value state (`otpValues`) based on the pasted data.
   * - Triggers the `onChange` callback with the combined OTP value.
   *
   * @param {React.ClipboardEvent<HTMLInputElement>} e - Clipboard event object.
   */
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('Text');
    const pastedValues = pastedData.slice(0, length).split('');
    const newOtpValues = [...otpValues];
    for (let i = 0; i < length; i++) {
      if (pastedValues[i]) {
        newOtpValues[i] = pastedValues[i];
      }
    }
    setOtpValues(newOtpValues);
    onChange(newOtpValues.join(''));
    const firstEmptyIndex = newOtpValues.findIndex(val => !val);
    if (firstEmptyIndex !== -1) {
      inputRefs[firstEmptyIndex].current?.focus();
    } else {
      inputRefs[length - 1].current?.focus();
    }
  };

  return (
    <Box>
      {otpValues.map((value, index) => (
        <Box key={index} sx={styles.inputWrapper}>
          <TextField
            inputRef={inputRefs[index]}
            value={value}
            type='password'
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            variant='standard'
            style={{ ...styles.inputBase, ...(index === length - 1 && styles.noMargin) }}
            autoFocus={index === 0}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 1, sx: styles.inputProps }}
            onFocus={() => setFocusedIndex(index)}
            onBlur={() => setFocusedIndex(null)}
          />
          {focusedIndex === index && (
            <Divider sx={styles.inputFocusLine} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default OTPInput;
