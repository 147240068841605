import {
  API_FAILURE,
  ApiFailureAction,
  CHAT_HISTORY_SUCCESS,
  CHAT_SEND_API,
  CHAT_SEND_FAILED,
  CHAT_SEND_SUCCESS,
  ChatHistorySuccessAction,
  ChatSendAPIAction,
  ChatSendFailureAction,
  ChatSendSuccessAction,
  FETCH_CHAT_HISTORY,
  FETCH_INTERACTION_HISTORY,
  FETCH_MESSAGE_INFO,
  FetchChatHistoryAPIAction,
  FetchInteractionHistoryAPIAction,
  FetchMessageInfoAction,
  INTERACTION_HISTORY_SUCCESS,
  InteractionHistorySuccessAction,
  MARK_CHAT_AS_READ,
  MARK_CHAT_AS_READ_SUCCESS,
  MESSAGE_INFO_SUCCESS,
  MarkChatAsReadAction,
  MarkChatAsReadSuccessAction,
  MessageInfoSuccessAction,
  RESET_INBOX_STATE,
  ResetInboxStateAction,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_STATUS_SUCCESS,
  UpdateSessionStatusAction,
  UpdateSessionStatusSuccessAction
} from './action-types';

import ChatHistoryDispatchPayload from '../../types/chat-history-dispatch-payload';
import ChatMarkAsReadRequest from '../../types/chat-mark-as-read-request';
import ConversationData from '../../types/conversation-data';
import MessageData from '../../types/message-data';
import SessionStatusRequest from '../../types/session-status-request';

/**
 * Action creator to indicate that the chat session status is being updated.
 *
 * @returns {UpdateSessionStatusAction} - The action to update session status.
 */
export const updateSessionStatus = (): UpdateSessionStatusAction => ({
  type: UPDATE_SESSION_STATUS
});

/**
 * fetchInteractionHistoryAPI action creator
 * 
 * This function creates a Redux action object to signal the initiation of an API call 
 * to fetch interaction history data.
 * 
 * @returns {FetchInteractionHistoryAPIAction} Redux action object with type 
 *                                            `FETCH_INTERACTION_HISTORY`.
 */
export const fetchInteractionHistoryAPI = (): FetchInteractionHistoryAPIAction => ({
  type: FETCH_INTERACTION_HISTORY
});

/**
 * fetchChatHistoryAPI action creator
 * 
 * This function creates a Redux action object to signal the initiation of an API call 
 * to fetch chat history data.
 * 
 * @returns {FetchChatHistoryAPIAction} Redux action object with type 
 *                                            `FETCH_CHAT_HISTORY`.
 */
export const fetchChatHistoryAPI = (): FetchChatHistoryAPIAction => ({
  type: FETCH_CHAT_HISTORY
});

/**
 * chatSendAPI action creator
 * 
 * This function creates a Redux action object to signal the initiation of an API call 
 * to send a chat message.
 * 
 * @returns {ChatSendAPIAction} Redux action object with type `CHAT_SEND_API`.
 */
export const chatSendAPI = (message: MessageData): ChatSendAPIAction => ({
  type: CHAT_SEND_API,
  payload: message
});

/**
 * markChatAsReadAPI action creator
 * 
 * This function creates a Redux action object to signal the initiation of an API call 
 * to mark unread messages as read.
 * 
 * @returns {MarkChatAsReadAction} Redux action object with type `MARK_CHAT_AS_READ`.
 */
export const markChatAsReadAPI = (): MarkChatAsReadAction => ({
  type: MARK_CHAT_AS_READ
});

/**
 * Action creator to initiate fetching message info.
 *
 * @returns {FetchMessageInfoAction} An action object indicating the start of fetching message info.
 */
export const initMessageInfoFetch = (): FetchMessageInfoAction => ({
  type: FETCH_MESSAGE_INFO
});

/**
 * Action creator to indicate successful update of chat session status.
 *
 * @param {SessionStatusRequest} request - The session status request object.
 * @returns {UpdateSessionStatusSuccessAction} - The action to indicate successful update with the request as payload.
 */
export const updateSessionStatusSuccess = (request: SessionStatusRequest): UpdateSessionStatusSuccessAction => ({
  type: UPDATE_SESSION_STATUS_SUCCESS,
  payload: request
});

/**
 * interactionHistorySuccess action creator
 * 
 * This function creates a Redux action object to signal the successful retrieval of 
 * interaction history data and update the store with the received conversation list.
 * 
 * @param conversationList {Array<ConversationData>} Array containing conversation data objects.
 * 
 * @returns {InteractionHistorySuccessAction} Redux action object with type 
 * `INTERACTION_HISTORY_SUCCESS` and payload containing the conversation list.
 */
export const interactionHistorySuccess = (
  conversationList: Array<ConversationData>
): InteractionHistorySuccessAction => ({
  type: INTERACTION_HISTORY_SUCCESS,
  payload: conversationList
});

/**
 * chatHistorySuccess action creator
 * 
 * This function creates a Redux action object of type `CHAT_HISTORY_SUCCESS` 
 * signifying the successful retrieval of chat history data. It takes a 
 * `chatHistory` object as payload, containing the fetched conversation history.
 * 
 * @param {ChatHistoryDispatchPayload} chatHistory - Fetched conversation history data.
 * @returns {ChatHistorySuccessAction} - Redux action object.
 */
export const chatHistorySuccess = (chatHistory: ChatHistoryDispatchPayload): ChatHistorySuccessAction => ({
  type: CHAT_HISTORY_SUCCESS,
  payload: chatHistory
});

/**
 * chatSendSuccess action creator
 * 
 * This function creates a Redux action object to signal a successful chat message send.
 * 
 * @param messageData {MessageData} Object containing the sent message data. 
 * 
 * @returns {ChatSendSuccessAction} Redux action object with type `CHAT_SEND_SUCCESS` 
 *                                  and payload containing the message data.
 */
export const chatSendSuccess = (messageData: MessageData): ChatSendSuccessAction => ({
  type: CHAT_SEND_SUCCESS,
  payload: messageData
});

/**
 * Action creator to indicate a failed chat message send attempt.
 *
 * @param {MessageData} messageData - The message data that failed to send.
 * 
 * @returns {ChatSendFailureAction} An action object containing the failed message data.
 */
export const chatSendFailed = (messageData: MessageData): ChatSendFailureAction => ({
  type: CHAT_SEND_FAILED,
  payload: messageData
});

/**
 * markChatAsReadSuccess action creator
 * 
 * This function creates a Redux action object to signal the successful marking of 
 * unread messages as read and provide details about the marked messages.
 * 
 * @param request {ChatMarkAsReadRequest} Object containing details about the marked messages.
 * 
 * @returns {MarkChatAsReadSuccessAction} Redux action object with type 
 * `MARK_CHAT_AS_READ_SUCCESS` and payload containing the `request` object.
 */
export const markChatAsReadSuccess = (request: ChatMarkAsReadRequest): MarkChatAsReadSuccessAction => ({
  type: MARK_CHAT_AS_READ_SUCCESS,
  payload: request
});

/**
 * Action creator to indicate successful fetching of message info.
 *
 * @param {MessageData} messageData - The fetched message data.
 * 
 * @returns {MessageInfoSuccessAction} An action object containing the message data.
 */
export const messageInfoSuccess = (messageData: MessageData): MessageInfoSuccessAction => ({
  type: MESSAGE_INFO_SUCCESS,
  payload: messageData
});

/**
 * Creates an action object to indicate an API call failure with an error message.
 *
 * This function takes an error message as input and returns an action object
 * with the type `API_FAILURE` and the error message in the payload.
 *
 * @param {string} error - The error message encountered during the API call.
 * @returns {ApiFailureAction} - The action object for API call failure.
 */
export const apiFailure = (error: string): ApiFailureAction => ({
  type: API_FAILURE,
  payload: error
});

/**
 * resetInboxState action creator
 * 
 * This function creates a Redux action object to reset the state of the Inbox section.
 * 
 * @returns {ResetInboxStateAction} Redux action object with type `RESET_INBOX_STATE`.
 */
export const resetInboxState = (): ResetInboxStateAction => ({
  type: RESET_INBOX_STATE
});