import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M6.69531 5.46855C6.69531 6.52211 7.11384 7.53252 7.85882 8.27751C8.6038 9.02249 9.61421 9.44101 10.6678 9.44101C11.7213 9.44101 12.7317 9.02249 13.4767 8.27751C14.2217 7.53252 14.6402 6.52211 14.6402 5.46855C14.6402 4.41499 14.2217 3.40458 13.4767 2.6596C12.7317 1.91462 11.7213 1.49609 10.6678 1.49609C9.61421 1.49609 8.6038 1.91462 7.85882 2.6596C7.11384 3.40458 6.69531 4.41499 6.69531 5.46855Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.70898 19.3728V17.3865C4.70898 16.333 5.12751 15.3226 5.87249 14.5776C6.61747 13.8326 7.62788 13.4141 8.68144 13.4141H12.6539C13.7075 13.4141 14.7179 13.8326 15.4629 14.5776C16.2078 15.3226 16.6264 16.333 16.6264 17.3865V19.3728' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default UserIcon;