import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for DirectorySortFilterDialog styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    ...commonStyles,
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        width: Dimens.alertDialogLargeWidth.lg,
        [theme.breakpoints.down('md')]: {
          width: Dimens.filterDialog.width,
          maxHeight: Dimens.filterDialog.height
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          justifyContent: 'center'
        },
        '> .MuiBox-root': {
          overflowY: 'auto'
        }
      }
    },
    closeIcon: {
      position: 'absolute',
      zIndex: 2,
      right: 0,
      top: 0,
      [theme.breakpoints.down('md')]: {
        p: Dimens.radius.xl,
      },
      [theme.breakpoints.down('sm')]: {
        p: Dimens.radius.lg,
      },
      'svg': {
        p: 0,
        color: theme.palette.text.primary,
        fontSize: Dimens.icon.sm
      }
    }
  };

  return styles;
};

export default useStyles;
