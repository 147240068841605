import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HighPriorityIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3690_7238)'>
          <path d='M5.61166 8.33511H2.75686C2.59944 8.33508 2.44555 8.28837 2.31467 8.20089C2.18378 8.11341 2.08177 7.98909 2.02153 7.84364C1.96129 7.6982 1.94552 7.53816 1.97623 7.38375C2.00693 7.22935 2.08272 7.08751 2.19402 6.97618L7.43711 1.73309C7.5864 1.58384 7.78886 1.5 7.99995 1.5C8.21105 1.5 8.4135 1.58384 8.56279 1.73309L13.8059 6.97618C13.9172 7.08751 13.993 7.22935 14.0237 7.38375C14.0544 7.53816 14.0386 7.6982 13.9784 7.84364C13.9181 7.98909 13.8161 8.11341 13.6852 8.20089C13.5544 8.28837 13.4005 8.33508 13.243 8.33511H10.3882V10.7234H5.61166V8.33511Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M5.61133 15.5H10.3879' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M5.61133 13.1113H10.3879' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3690_7238'>
            <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default HighPriorityIcon;