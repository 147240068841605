import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Generates MUI theme-dependent styles for the AutocompleteChipInput component.
 *
 * @returns An object containing styled classes for various component elements.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	const { xxs, xs, sm, base, md, lg } = Dimens.spacing;

	const styles = {
		wrapper: {
			flexDirection: 'column',
			display: 'flex',
			flex: 1,
			pb: base
		},
		autocomplete: {
			display: 'flex'
		},
		textField: {
			'& .MuiOutlinedInput-root': {
				p: `${md} ${lg} !important`,
				'.MuiAutocomplete-endAdornment': {
					mr: xxs
				},
				'.MuiAutocomplete-input': {
					height: 'inherit',
					...theme.typography.p1,
					padding: `0 !important`
				},
				'& fieldset': {
					borderColor: theme.palette.background.default
				},
				'&:hover fieldset': {
					borderColor: theme.palette.background.default
				},
				'&.Mui-focused fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: theme.palette.primary.main
				},
				'&.Mui-error fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: theme.palette.error.main
				}
			},
			'& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
				...theme.typography.p1,
				color: theme.palette.text.secondary,
				opacity: 1
			},
			'.MuiInputBase-multiline': {
				p: 0
			}
		},
		outerInput: {
			borderRadius: Dimens.radius.md,
			backgroundColor: theme.palette.background.default
		},
		endIcon: {
			...commonStyles.smIcon,
			color: theme.palette.text.secondary
		},
		divider: {
			my: '0 !important',
			borderBottomWidth: Dimens.menuBorderWidth,
			borderColor: theme.palette.background.default
		},
		fieldError: {
			...theme.typography.footer,
			m: 0,
			pt: xs,
			color: theme.palette.error.main
		},
		chipWrapper: {
			py: xxs
		},
		chip: {
			m: xxs,
			borderRadius: Dimens.radius.md,
			p: `${sm} ${lg}`,
			height: 'auto',
			'.MuiChip-label': {
				...theme.typography.p1,
				...commonStyles.textEllipsis(1),
				whiteSpace: 'normal',
				p: 0
			},
			'.MuiChip-deleteIcon': {
				display: 'flex',
				m: 0,
				ml: base,
				fontSize: Dimens.icon.sm,
				color: theme.palette.text.primary,
				'&:hover': {
					color: theme.palette.text.primary
				}
			}
		},
		popper: {
			'.MuiAutocomplete-paper': {
				border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
				borderRadius: Dimens.radius.md,
				boxShadow: `0 ${Dimens.spacing.xxs} ${Dimens.spacing.xs} 0 ${Colors.shadow}`,
				'ul': {
					p: 0,
					'li': {
						p: `${md} ${lg}`,
					}
				}
			}
		}
	};

	return styles;
};

export default useStyles;