import { doDelete, doGet, doPost, doPut } from '../../service';
import { initSiteCreate, initSiteDelete, initSiteDetailFetch, initSiteListFetch, initSiteUpdate, resetErrorState, resetSiteState, siteFailure, siteListSuccess } from './actions';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import React from 'react';
import Site from '../../types/site';
import SiteDetailResponse from '../../types/site-detail-response';
import SiteListResponse from '../../types/site-list-response';
import { Store } from '../../store/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * React hook for managing site data through API calls.
 *
 * @returns {{fetchSiteList, fetchSiteDetail, createOrUpdateSite, deleteSite, state}} An object containing functions for
 * interacting with site data and the site state.
 */
export function useSiteApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Fetches the list of sites.
	 * Dispatches actions to indicate fetching and handle success/failure.
	 */
	const fetchSiteList = useCallback(async () => {
		dispatch(initSiteListFetch());
		try {
			const response: SiteListResponse = await doGet(APIConfig.siteSearch);
			dispatch(siteListSuccess(response.data || []));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches details of a specific site by ID.
	 * Dispatches actions to indicate fetching and handle success/failure.
	 *
	 * @param {string} siteId - The ID of the site to fetch details for.
	 */
	const fetchSiteDetail = useCallback(async (siteId: string) => {
		dispatch(initSiteDetailFetch());
		try {
			const response: SiteDetailResponse = await doGet(`${APIConfig.site}/${siteId}`);
			response.data.isFetched = true;
			const siteList = [...state.site.siteList.filter(item => item.id !== response.data.id), response.data];
			dispatch(siteListSuccess(siteList));
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Creates a new site or updates an existing one.
	 * Dispatches actions to indicate creation/update and handle success/failure.
	 *
	 * @param {Site} site - The site data to create or update.
	 */
	const createOrUpdateSite = useCallback(async (site: Site) => {
		dispatch(site.id ? initSiteUpdate() : initSiteCreate());
		try {
			const response: SiteDetailResponse = site.id ? await doPut(`${APIConfig.site}/${site.id}`, site)
				: await doPost(APIConfig.site, site);
			response.data.isFetched = true;
			const siteList = [...state.site.siteList.filter(item => item.id !== response.data.id), response.data];
			dispatch(siteListSuccess(siteList));
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Deletes a site by ID.
	 * Dispatches actions to indicate deletion and handle success/failure.
	 *
	 * @param {Site} site - The site object containing the ID for deletion.
	 */
	const deleteSite = useCallback(async (site: Site) => {
		dispatch(initSiteDelete());
		try {
			await doDelete(`${APIConfig.site}/${site.id}`);
			const siteList = [...state.site.siteList.filter(item => item.id !== site.id)];
			dispatch(siteListSuccess(siteList));
			return site;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Resets the site error state in the Redux store.
	 */
	const resetError = () => {
		if (state.site.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the site state in the Redux store.
	 */
	const resetSite = () => {
		dispatch(resetSiteState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(siteFailure(new AppError(error?.code, message)));
	}

	return {
		fetchSiteList,
		fetchSiteDetail,
		createOrUpdateSite,
		deleteSite,
		resetError,
		resetSite,
		state
	};
}