import { initRegistration, registrationFailure, registrationSuccess } from './actions';
import signUpReducer, { signUpInitialState } from './reducer';
import { useCallback, useReducer } from 'react';

import APIConfig from '../../service/api-config';
import Department from '../../types/department';
import LoginUtil from '../../utils/login-util';
import PasswordRequest from '../../types/password-request';
import UserProfile from '../../types/user-profile';
import { doPost } from '../../service';
import { useTranslation } from 'react-i18next';

/**
 * useSignUpApi hook
 * 
 * This hook provides functions to handle user registration functionalities: 
 *  - Updating password
 *  - Assigning department
 *  - Registering user profile
 * 
 * It utilizes the `useReducer` hook with a dedicated reducer (`signUpReducer`) 
 * to manage the registration state and dispatches actions based on user interactions.
 * 
 * @returns {object} An object containing functions for registration and the current state.
 *  - registerPassword: Function to update user password.
 *  - registerDepartment: Function to assign department to a user.
 *  - registerProfile: Function to register user profile details.
 *  - state: Current registration state managed by the reducer.
 */
export function useSignUpApi() {

	const [state, dispatch] = useReducer(signUpReducer, signUpInitialState);
	const { t } = useTranslation();

	/**
   * dispatchFailureAction function
   * 
   * This function dispatches a registration failure action to update the state 
   * with error details. It extracts the error message from the provided error object 
   * or uses a default error message from translation if no specific message is available.
   * 
   * @param {any} error - Error object containing details about the registration failure.
   * 
   * @returns {void} - This function does not return a value, it dispatches a Redux action.
   */
	const dispatchFailureAction = (error: any) => { /* eslint-disable-line */
		dispatch(registrationFailure(error?.message || t('defaultErrorMsg')));
	}

	/**
   * registerPassword function
   * 
   * This function handles updating the user's password.
   * 
   * @param {PasswordRequest} request - Object containing new password details.
   * 
   * @returns {Promise<void>} - Resolves upon successful password update, rejects on error.
   */
	const registerPassword = useCallback(async (request: PasswordRequest) => {
		dispatch(initRegistration());
		try {
			request.newPassword = LoginUtil.encryptPassword(request.newPassword || '');
			await doPost(APIConfig.updatePassword, request);
			dispatch(registrationSuccess());
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * registerDepartment function
   * 
   * This function handles assigning a department to the user.
   * 
   * @param {Department} department - Object containing department details.
   * 
   * @returns {Promise<void>} - Resolves upon successful department assignment, rejects on error.
   */
	const registerDepartment = useCallback(async (department: Department) => {
		dispatch(initRegistration());
		try {
			await doPost(APIConfig.assignDept, department);
			dispatch(registrationSuccess());
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * registerProfile function
   * 
   * This function handles registering the user's profile details.
   * 
   * @param {UserProfile} profile - Object containing user profile details.
   * 
   * @returns {Promise<void>} - Resolves upon successful profile registration, rejects on error.
   */
	const registerProfile = useCallback(async (profile: UserProfile) => {
		dispatch(initRegistration());
		try {
			await doPost(APIConfig.profile, profile);
			dispatch(registrationSuccess());
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	return {
		registerPassword,
		registerDepartment,
		registerProfile,
		state
	};
}