import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoCircleIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6733_1000)'>
          <path d='M1.75 12.6045C1.75 14.0162 2.02806 15.4141 2.56829 16.7183C3.10853 18.0226 3.90037 19.2077 4.8986 20.2059C5.89683 21.2041 7.0819 21.996 8.38615 22.5362C9.6904 23.0764 11.0883 23.3545 12.5 23.3545C13.9117 23.3545 15.3096 23.0764 16.6138 22.5362C17.9181 21.996 19.1032 21.2041 20.1014 20.2059C21.0996 19.2077 21.8915 18.0226 22.4317 16.7183C22.9719 15.4141 23.25 14.0162 23.25 12.6045C23.25 9.75341 22.1174 7.01911 20.1014 5.00309C18.0854 2.98708 15.3511 1.85449 12.5 1.85449C9.64892 1.85449 6.91462 2.98708 4.8986 5.00309C2.88259 7.01911 1.75 9.75341 1.75 12.6045Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M12.4923 7.91217H12.5074' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M12.5 12.6045L12.5 17.6943' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6733_1000'>
            <rect width='24' height='24' fill='currentColor' transform='translate(0.5 0.604492)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default InfoCircleIcon;