import { Box, Grid } from '@mui/material';

import React from 'react';
import useStyles from './styles';

interface Props {
  children: React.ReactNode;
  image: string;
}

const AuthLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Grid container sx={styles.wrapper}>
      <Box sx={styles.leftPane}>
        {props.children}
      </Box>
      <Box sx={styles.rightPane}>
        <Box sx={{...styles.image, backgroundImage: `url(${props.image})`}} />
      </Box>
    </Grid>
  );
};

export default AuthLayout;
