import { Box, Fab, Typography } from '@mui/material';
import { CHAT_SEND_API, FETCH_CHAT_HISTORY } from '../../../data/inbox/action-types';
import { ContentType, EntityType, MessageOptions, MessagePriority } from '../../../utils/constants';
import React, { useEffect, useRef, useState } from 'react';

import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import ChatItem from './ChatItem';
import ChatMarkAsReadRequest from '../../../types/chat-mark-as-read-request';
import ChatUtil from '../../../utils/chat-util';
import CircleDownIcon from '../../../components/CustomIcons/CircleDownIcon';
import ConversationData from '../../../types/conversation-data';
import { Group } from '../../../types/group';
import GroupViewPanel from '../../DirectoryScreen/GroupViewPanel';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import MessageData from '../../../types/message-data';
import MessageDetailPanel from './MessageDetailPanel';
import MessageForwardDialog from './MessageForwardDialog';
import { User } from '../../../types/user';
import UserViewPanel from '../../UserScreen/UserViewPanel';
import Util from '../../../utils/util';
import { useChatScrollHandler } from '../../../hooks/use-chat-scroll-handler';
import { useInboxApi } from '../../../data/inbox/api';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * InboxDetailScreen component
 *
 * This component renders the detail screen for an inbox conversation, providing a user interface for:
 *   - Displaying conversation details: participant name(s) or group name and status (if available).
 *   - Viewing chat history: Individual messages are rendered using the `ChatItem` component, 
 *     taking into account message alignment (left-aligned for received messages, right-aligned for sent messages), 
 *     date headers for separating messages from different days, and potential overflow handling.
 *   - Sending new messages: The `ChatFooter` component provides a message input field and a send button. 
 *     This component can optionally clear the message input field after sending a message.
 *   - Scrolling to bottom: A "scroll to bottom" button appears when the chat history overflows the available container space.
 *     The `useScrollToBottom` hook is used to manage scrolling behavior and display the button conditionally.
 *
 */
const InboxDetailScreen: React.FC = () => {

  const location = useLocation();
  const { t } = useTranslation();
  const conversation: ConversationData = location.state;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { scrollToBottom, showBackToStartBtn, isLoadMore } = useChatScrollHandler(containerRef);
  const styles = useStyles(showBackToStartBtn);
  const [openGroupPanel, setOpenGroupPanel] = useState<boolean>(false);
  const [openUserPanel, setOpenUserPanel] = useState<boolean>(false);
  const [openMessageInfoPanel, setOpenMessageInfoPanel] = useState<boolean>(false);
  const [showForwardDialog, setShowForwardDialog] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<MessageData>();
  const inboxApi = useInboxApi();
  const apiStatus = inboxApi.state.inbox.apiStatus;

  /**
   * Fetches chat history from the server when the component mounts or conversation or isLoadMore(pagination) state changes.
   * Triggers a re-fetch whenever relevant data might have been updated.
   */
  useEffect(() => {
    fetchChatHistoryFromServer();
  }, [conversation, isLoadMore]);

  /**
   * Retrieves conversation data from the Inbox API state based on conversation type (private or group)
   * and participant details. Used to access specific conversation information within the Inbox API state.
   * 
   * @returns ConversationData object if conversation is found, otherwise undefined.
   */
  const getConversationFromState = (): ConversationData | undefined => {
    const isPrivateChat = ChatUtil.isPrivateChat(conversation);
    const conversationItem = inboxApi.state.inbox.messageList
      .find(item => isPrivateChat ? (item.recipientLoginId === conversation.recipientLoginId)
        : (item.groupRoomKey === conversation.groupRoomKey));

    return conversationItem;
  }

  const getUserInfo = () => {
    const user: User = {
      name: conversation.recipientName ?? '',
      email: conversation.recipientLoginId ?? '',
      defaultDepartmentId: '',
      type: '',
      position: ''
    };

    return user;
  }

  /**
   * Gets the subheader text for a conversation. It will session status for private chat
   * and number of members for group chat.
   *
   * @returns {string} The subheader text.
   */
  const getSubHeader = (): string => {
    let subHeader = '';
    const conversationItem = getConversationFromState() || conversation;
    if (conversationItem) {
      subHeader = ChatUtil.isGroupChat(conversationItem) ? `${conversationItem.groupMembers?.length || 0} ${t('members')}`
        : conversationItem.recipientActualSessionStatus ?? '';
    }

    return subHeader;
  }

  /**
   * Handles the click event on the chat header.
   *
   * If the conversation is a group chat, opens the group panel.
   */
  const onHeaderClick = () => {
    if (ChatUtil.isGroupChat(conversation)) {
      setOpenGroupPanel(true);
    } else if (ChatUtil.isPrivateChat(conversation)) {
      setOpenUserPanel(true);
    }
  }

  const handleMsgActionClick = (action: MessageOptions, messageData: MessageData) => {
    setSelectedMessage(messageData);
    if (action === MessageOptions.Forward) {
      setShowForwardDialog(true);
    } else if (action === MessageOptions.Info) {
      setOpenMessageInfoPanel(true);
    }
  }

  /**
   * Extracts the chat history data (content array) from the conversation object.
   * 
   * @returns Array of MessageData objects representing the chat history.
   */
  const getChatHistoryFromState = (): Array<MessageData> => {
    const chatHistory = conversation ? (getConversationFromState()?.chatHistory?.content || []) : [];

    return chatHistory;
  };

  // Derived state: chatHistory array containing message data
  const chatHistory = getChatHistoryFromState();

  /**
   * useEffect hook to automatically mark unread messages as read
   * 
   * This hook checks for specific conditions before marking unread messages as read:
   *  - A conversation item from the inbox list page is defined (`conversation`).
   *  - Chat history has been successfully fetched (`chatHistory`).
   *  - There are unread messages in the chat history (`messageIdList`).
   * 
   * If the conditions are met, it builds a `ChatMarkAsReadRequest` object containing:
   *  - Login ID of the user.
   *  - Interaction type (private or group chat).
   *  - Array of message IDs to mark as read.
   *  - Room key (private or group chat room key).
   * 
   * Finally, it calls the `inboxApi.markAsRead` function to send the request 
   * and mark the unread messages as read on the server and also in local.
   * 
   * @returns {void} - This hook does not return a value, it triggers the 
   *                   marking of unread messages as read based on conditions.
   */
  useEffect(() => {
    // Condition 1: Check coversation item from inbox list page is defined.
    // Condition 2: Check whether chat history is called or else wait until its done (This
    // will avoid duplicate calls too server).
    // Condition 3: If there is no chat then there is no point in checking unread messages.
    if (conversation && getConversationFromState()?.chatHistory?.pageable
      && !Util.isArrayEmpty(chatHistory)) {
      const loginId = LoginUtil.getLoginId();
      const messageIdList = chatHistory.filter(message => (
        !message.isRead && message.senderLoginId !== loginId
      )).map(message => message.messageId ?? '');
      if (!Util.isArrayEmpty(messageIdList)) {
        const request: ChatMarkAsReadRequest = {
          loginId: loginId,
          interactionType: conversation.interactionType,
          messageIds: messageIdList,
          roomKey: ChatUtil.isPrivateChat(conversation) ? conversation.privateRoomKey ?? '' : conversation.groupRoomKey ?? ''
        }
        inboxApi.markAsRead(request);
      }
    }
  }, [chatHistory.length]);


  /**
   * Fetches additional chat history from the server when the isLoadMore state flag is true, indicating 
   * there are potentially more messages to load. This is typically triggered by reaching the end of the 
   * currently displayed chat history.
   */
  const fetchChatHistoryFromServer = () => {
    if (conversation) {
      const conversationItem = getConversationFromState();
      if (conversationItem) {
        // Condition 1: If chat history is empty, then there are no messages.
        // Condition 2: If the chat history is not empty but there are no pagination details,
        // then the messages are all received messages and the history API is not called.
        // Condition 3: Call the history API to fetch next page, if the pagination is not completed.
        if ((!ChatUtil.hasChatHistory(conversationItem) || !conversationItem.chatHistory?.pageable)
          || (isLoadMore && conversationItem.chatHistory?.content.length < conversationItem.chatHistory?.total)) {
          if (apiStatus?.task !== FETCH_CHAT_HISTORY || (apiStatus?.task === FETCH_CHAT_HISTORY && !apiStatus?.isLoading)) {
            const page = conversationItem.chatHistory?.pageable ? conversationItem.chatHistory?.pageable.page + 1 : 0;
            if (ChatUtil.isPrivateChat(conversation)) {
              inboxApi.fetchPrivateChatHistory(conversationItem.recipientLoginId ?? '', page);
            } else {
              inboxApi.fetchGroupChatHistory(conversationItem.groupRoomKey ?? '', page);
            }
          }
        }
      }
    }
  }

  /**
   * Handles sending a new message to the conversation. Handles both private and group chat.
   * 
   * @param {string} message - The message content to send.
   * @param {MessagePriority} priority - The priority of the message (optional).
   */
  const sendMessage = (message: string, priority: MessagePriority) => {
    if (message) {
      const messageData: MessageData = {
        senderName: LoginUtil.getUserFullName(),
        senderLoginId: LoginUtil.getLoginId(),
        senderEntityType: EntityType.User,
        groupRoomKey: conversation.groupRoomKey,
        recipientLoginId: conversation.recipientLoginId,
        recipientName: conversation.recipientName,
        recipientEntityType: EntityType.User,
        contentText: message,
        contentType: ContentType.ContentText,
        priority: priority
      };
      inboxApi.sendChat(messageData);
      scrollToBottom();
    }
  }

  return (
    <Box sx={styles.wrapper}>
      {conversation ?
        <Box sx={styles.innerWrapper}>
          <ChatHeader
            name={(conversation.name || conversation.recipientName) ?? ''}
            status={getSubHeader()}
            isGroup={ChatUtil.isGroupChat(conversation)}
            onHeaderClick={onHeaderClick}
          />
          <InlineAlert message={Util.getApiError([FETCH_CHAT_HISTORY], apiStatus)} />
          <Box sx={styles.content} ref={containerRef}>
            {chatHistory.map((item: MessageData, index: number) => (
              <ChatItem
                key={index}
                nextMessageData={index < chatHistory.length - 1 ? chatHistory[index + 1] : undefined}
                messageData={item}
                handleMsgActionClick={action => handleMsgActionClick(action, item)}
                isCurrentUser={ChatUtil.isLoggedInUser(item.senderLoginId)}
                isGroup={ChatUtil.isGroupChat(conversation)}
              />
            ))}
            {Util.isApiLoading([FETCH_CHAT_HISTORY], apiStatus) &&
              <Typography variant='caption' sx={styles.loadingTxt}>{t('loadingHistory')}</Typography>
            }
            <Fab color='secondary' size='small' sx={styles.fab} onClick={scrollToBottom}>
              <CircleDownIcon sx={styles.icon} />
            </Fab>
          </Box>
          <ChatFooter onSendMessage={sendMessage} />
          {ChatUtil.isGroupChat(conversation) &&
            <GroupViewPanel
              open={Boolean(openGroupPanel && conversation)}
              group={getConversationFromState() as Group}
              onClose={() => setOpenGroupPanel(false)}
            />
          }
          {ChatUtil.isPrivateChat(conversation) &&
            <UserViewPanel
              open={Boolean(openUserPanel && conversation)}
              user={getUserInfo()}
              onClose={() => setOpenUserPanel(false)}
            />
          }
          <MessageDetailPanel
          open={Boolean(openMessageInfoPanel && selectedMessage)}
          messageData={selectedMessage}
          onClose={() => setOpenMessageInfoPanel(false)}
        />
          {(showForwardDialog && selectedMessage) &&
            <MessageForwardDialog
              open={showForwardDialog}
              messageData={selectedMessage}
              onClose={() => setShowForwardDialog(false)}
            />
          }
        </Box>
        :
        <Box sx={styles.image} />
      }
    </Box>
  );
};

export default InboxDetailScreen;
