import { Box, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import { MAX_MULTI_LINE_CHAR_COUNT } from '../../utils/constants';
import ProfileImageDark from '../../assets/sign_up_profile_dark.svg';
import ProfileImageLight from '../../assets/sign_up_profile_light.svg';
import SignUpLayout from '../../layouts/SignUpLayout';
import SignUpPayload from '../../types/ui/sign-up-payload';
import ThemeUtil from '../../theme/theme-util';
import UserProfile from '../../types/user-profile';
import { registerProfileSchema } from '../../utils/validation-schema';
import { useDisableBackNav } from '../../hooks/use-disable-back-nav';
import { useSignUpApi } from '../../data/sign-up/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * RegisterProfileScreen component for user profile registration.
 *
 * This component handles the final step of user registration where the user provides 
 * their profile information, including first name, last name, position, and a short bio.
 * 
 * @returns {JSX.Element} - The rendered RegisterProfileScreen component.
 */
const RegisterProfileScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signUpApi = useSignUpApi();
  useDisableBackNav();
  const challengePayload: SignUpPayload = location.state;
  const initialValues: UserProfile = {
    firstName: '',
    lastName: '',
    name: '',
    position: '',
    about: '',
    email: ''
  };

  /**
   * Ensures user has a valid signup request before proceeding.
   * 
   * This useEffect hook checks the `isValidSignUpRequest` property in the 
   * `challengePayload` from the location state. If the request is not valid, 
   * it redirects the user back to the welcome screen using `navigate`.
   * This ensures the user completes the initial valid signup before accessing 
   * the profile registration step.
   */
  useEffect(() => {
    if (!challengePayload?.isValidSignUpRequest) {
      navigate(APP_NAV.WELCOME);
    }
  }, []);

  /**
   * Handles form submission for user profile registration.
   * 
   * This function is passed as a prop to the `Formik` component. It receives 
   * the form values as a `profile` object of type `UserProfile`. It then calls 
   * the `registerProfile` function from the `signUpApi` hook, triggering 
   * an API call to register the user's profile information on the server.
   * 
   * @param {UserProfile} profile - The user profile data from the form.
   */
  const onSubmit = (profile: UserProfile): void => {
    profile.name = `${profile.firstName} ${profile.lastName}`;
    signUpApi.registerProfile(profile);
  }

  return (
    <SignUpLayout
      error={signUpApi.state.error}
      showProgressBar={signUpApi.state.isLoading}
      headerTitle={t('createProfile')}
      image={isLightTheme ? ProfileImageLight : ProfileImageDark}
    >
      <Formik
        validateOnMount
        validationSchema={registerProfileSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box>
              <Field
                name='firstName'
                label={t('firstName')}
                placeholder={t('firstName')}
                component={CustomInput}
              />
              <Field
                name='lastName'
                label={t('lastName')}
                placeholder={t('lastName')}
                component={CustomInput}
              />
              <Field
                name='position'
                label={t('position')}
                placeholder={t('position')}
                component={CustomInput}
              />
              <Field
                name='about'
                label={t('aboutYou')}
                subLabel={t('optional')}
                placeholder={t('aboutYouPlaceholder')}
                multiline
                maxCharCount={MAX_MULTI_LINE_CHAR_COUNT}
                component={CustomInput}
              />
            </Box>
            <CustomButton type='submit' title={t('next')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignUpLayout>
  );
};

export default RegisterProfileScreen;

