import AppError from '../../exception/app-error';
import UserType from '../../types/user-type';

export const FETCH_USER_TYPE_LIST = 'FETCH_USER_TYPE_LIST';
export const USER_TYPE_LIST_SUCCESS = 'USER_TYPE_LIST_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

export interface FetchUserTypeListAction {
	type: typeof FETCH_USER_TYPE_LIST;
}

export interface UserTypeListSuccessAction {
	type: typeof USER_TYPE_LIST_SUCCESS;
	payload: Array<UserType>;
}

export interface ApiFailureAction {
	type: typeof API_FAILURE;
	payload: AppError;
}

export type UserTypeActionTypes = FetchUserTypeListAction | UserTypeListSuccessAction | ApiFailureAction;
