import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates a set of styles using Material UI's theme for the SignInPasswordScreen component.
 * It defines styles for icons, a spacer element, and a footer text element.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    icon: {
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    spacer: {
      height: Dimens.spacing.container
    },
    footer: {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  };

  return styles;
};

export default useStyles;