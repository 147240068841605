import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Creates styles for the MessageForwardDialog component.
 *
 * @returns {object} An object containing styles for the dialog, content, header, close icon, and title.
 */
const useStyles = () => {

  const { base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogLargeWidth.md,
        height: Dimens.alertDialogLargeWidth.md,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          p: lg
        }
      }
    },
    content: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto'
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    }
  };

  return styles;
};

export default useStyles;
