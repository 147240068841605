import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';

/**
 * Custom hook to generate styles for the DepartmentMemberPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xs, section } = Dimens.spacing;
  const commonStyles = useCommonStyles();

  const styles = {
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'hidden',
      padding: section
    },
    content: {
      ...commonStyles.noScrollBar,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',
      pt: xs
    }
  };

  return styles;
};

export default useStyles;
