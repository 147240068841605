import { Box, Typography } from '@mui/material';

import AuthLayout from '../../layouts/AuthLayout';
import CircularProgressBar from '../../components/CircularProgressBar';
import InlineAlert from '../../components/InlineAlert';
import React from 'react';
import useStyles from './styles';

interface Props {
  headerTitle: string;
  children: React.ReactNode;
  image: string;
  error?: string;
  showProgressBar?: boolean;
}

/**
 * SignUpLayout component
 * 
 * This component provides a layout for sign-up pages, including a header, content area, 
 * optional error message, and progress indicator.
 * 
 * @param {Props} props - Component props:
 *  - headerTitle: The title of the sign-up page.
 *  - children: Content to be rendered within the layout.
 *  - image: Background image for the AuthLayout.
 *  - error: Optional error message to display.
 *  - showProgressBar: Flag to indicate whether to show the progress bar.
 * 
 * @returns {JSX.Element} - The rendered layout component.
 */
const SignUpLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <AuthLayout image={props.image}>
      <Box sx={styles.contentWrapper}>
        <Typography variant='h1' sx={styles.header}>{props.headerTitle}</Typography>
        <InlineAlert message={props.error} />
        {props.children}
      </Box>
      <CircularProgressBar show={props.showProgressBar} />
    </AuthLayout>
  );
};

export default SignUpLayout;
